import { ChangeEvent, useContext, useState } from "react";
import { GeneralContext } from "~/contexts";
import { ModalContext } from "~/contexts/modal";
import api from "~/services/api";
import './styles.css';

const FormCreateTypeAndMeasure = () => {
    const [infoData, setInfoData] = useState({ name: "", describe: "" });
    const [loadData, setLoadData] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);

    // Contexts
    const { user } = useContext(GeneralContext);
    const Modal = useContext(ModalContext);

    function onChange(event: ChangeEvent<HTMLInputElement | HTMLSelectElement>) {
        const { name, value } = event.target;
        setInfoData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    }

    const salvarDadosDoForm = async () => {
        // Trim spaces and check if the fields are not empty
        if (infoData.name.trim() === "" || infoData.describe.trim() === "") {
            setError("Preencha todos os campos.");
            return;
        }

        setLoadData(true);
        setError(null);

        try {
            const dataFormat = {
                type: infoData.name.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, ""),
                name: infoData.name,
                description: infoData.describe
            };

            await api.post('warehouse_resource_type', dataFormat, {
                headers: { Authorization: user.token }
            });

            Modal.setModalOpen(false);
        } catch (err) {
            setError("Erro ao salvar os dados. Tente novamente.");
        } finally {
            setLoadData(false);
        }
    };

    return !loadData ? (
        <form method="post" onSubmit={(event: React.FormEvent<HTMLFormElement>) => event.preventDefault()} className="editCatAndUnit">
            <div className="form-group no-margin">
                <div className="input-group">
                    <label>Nome</label>
                    <input
                        type="text"
                        placeholder="Ex.: Limpeza"
                        name="name"
                        value={infoData.name}
                        onChange={onChange}
                        required
                    />
                </div>

                <div className="input-group">
                    <label>Descrição</label>
                    <input
                        type="text"
                        placeholder="Ex.: Produtos de limpeza e outros"
                        name="describe"
                        value={infoData.describe}
                        onChange={onChange}
                        required
                    />
                </div>

                {error && <div className="error">{error}</div>}

                <div className="input-group btns">
                    <button id="btnAdd" type="button" onClick={salvarDadosDoForm}>Adicionar</button>
                    <button id="btnCancel" type="button" onClick={() => Modal.setModalOpen(false)}>Cancelar</button>
                </div>
            </div>
        </form>
    ) : (
        <>Salvando dados...</>
    );
};

export default FormCreateTypeAndMeasure;
