import React, { useContext, useEffect, useState } from 'react';
import api from '~/services/api';
import './styles.css';

// Contexts
import { GeneralContext } from '~/contexts';
import { ModalContext } from '~/contexts/modal';

// Components
import Template from '~/components/Default/Template';
import Loading from '~/components/Default/Loading';
import HeaderSearchAndForm from '~/components/Default/HeaderSearchAndForm';
import Presences from './Modal/presences';
import View from './Modal/view';
import TransportAttendanceBox from './Box';

const TransportAttendance: React.FC = () => {
    const [ready, setReady]                   = useState(false);
    const [attendance, setAttendance]         = useState<any>(null);
    const [attendanceList, setAttendanceList] = useState<any>([]);
    const [error, setError]                   = useState<string | null>(null);

    // Contexts
    const {user, breadcrumbs, setBreadcrumbs} = useContext(GeneralContext);
    const {setModalOpen,setModalClass, setModalTitle, setModalBody} = useContext(ModalContext);

    function openModal(type: string, data?: any, student?: any) {
        if (type === "presence"){
            setModalClass('presenceView')
            setModalTitle('Lista de Presença do Aluno')
            setModalBody(<Presences data={data} student={student} openModal={openModal} setModalOpen={setModalOpen} setReady={setReady} />)
        } else if (type === "view"){
            setModalClass('studentView')
            setModalTitle('Alunos na Linha')
            setModalBody(<View data={data} openModal={openModal} setModalOpen={setModalOpen} setReady={setReady} />)
        }

        setModalOpen(true)
    }

    function getContent() {
        if (error) return <div className='noData'>{error}</div>

        return <>
            <HeaderSearchAndForm
                search={{
                    dataList: attendanceList,        // Substitua 'yourDataList' pela sua lista de dados
                    filterKey: ["name", "ra_number"],             // Substitua 'nome' pela chave que você deseja filtrar
                    setFilteredList: setAttendance   // Substitua 'setYourList' pela função que define a lista filtrada
                }}
                isViewButton={false}
                isViewSearch={true}
            />

            {
                attendance && attendance.length > 0 ? (
                    <div className='attendance'>
                        {
                            attendance.map((studentData: any) => (
                                <TransportAttendanceBox
                                    key={'student_' + studentData.id}
                                    data={studentData}
                                    openModal={openModal}
                                />
                            ))
                        }
                    </div>
                ) : <div className='noData'>Não foi possível localizar nenhum estudante</div>
            }
        </>
    }

    useEffect(() => {
        !ready && api.get('transport/routes/students', {
            headers: { Authorization: user.token }
        }).then(resp => {
            setAttendance(resp.data);
            setAttendanceList(resp.data);
            setReady(true);
        }).catch(err => {
            setError('Erro ao carregar a presença dos alunos')
            setReady(true)
        });
    }, [ready])

    // Breadcrumbs
    useEffect(() => {
        (!breadcrumbs || breadcrumbs.curr!=="Presenças") && setBreadcrumbs({
            curr: 'Presenças',
            links: [
                { name: 'Home', url: '/'},
                { name: 'Transporte', url: '/transporte'},
                { name: 'Presenças' }
            ]
        });
    }, [breadcrumbs]);

    return <Template page="Presenças" pageTitle="Transporte - Presenças" className="secEducAttendance">
       { ready ? getContent() : <Loading /> }
    </Template>;
}

export default TransportAttendance;