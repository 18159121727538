import React from "react";
import './styles.css';

const ModalView: React.FC<any> = ({ data }: any) => {
    return <>
        <h3>{data.name}</h3>

        <p><strong>Telefone:</strong> {data.phone}</p>
        <p><strong>Email:</strong> {data.mail}</p>

        <hr />

        <div className="routes">
            {
                data.routesMonitor ? data.routesMonitor.map((el: any, idx: number) => (
                    <div className={`route_line${idx>0 ? ' line' : ''}`} key={'route_' + el.id}>
                        <h4>{el.name}</h4>
                        <div className="description">{el.description}</div>

                        <hr />

                        <div className="vehicle"><b>Veículo:</b> {el.vehicle.name} ({el.vehicle.brand} {el.vehicle.model})</div>
                        <div className="vehicle_plate"><b>Placa:</b> {el.vehicle.license_plate}</div>
                        <div className="vehicle_color"><b>Cor:</b> {el.vehicle.color}</div>
                        <div className="vehicle_seats"><b>Assentos:</b> {el.vehicle.seats}</div>
                    </div>
                )) : <div className="alert">Nenhuma rota cadastrada</div>
            }
        </div>
    </>;
};

export default ModalView;
