import React, { useContext, useEffect, useState } from 'react';
import { FaPlus } from 'react-icons/fa';
import sort_by from '~/utils/sort';
import api from '~/services/api';
import "./styles.css"

// Contexts
import { GeneralContext } from '~/contexts';
import { ModalContext } from '~/contexts/modal';

// Components
import Template from '~/components/Default/Template';
import HeaderSearchAndForm from '~/components/Default/HeaderSearchAndForm';
import Loading from '~/components/Default/Loading';
import RequestBox from './Box';
import NewRequest from './Form';
import Products from './Modal/products';
import View from './Modal/view';
import Cancel from './Modal/cancel';

export interface Request {
    id           : number;
    id_user      : number;
    resource_data: string;
    state        : string;
    justification: string;
    inactive     : boolean;
    delivery_date: Date;
}

const SchoolSecretaryMaterials: React.FC = () => {
    const [requests, setRequests] = useState<Request[]>([]);
    const [requestsList, setRequestsList] = useState<Request[]>([]);
    const [products, setProducts] = useState<any[]>([]);
    const [error, setError] = useState<string | null>(null);
    const [ready, setReady] = useState(false);

    // Filters States
    const [filters, setFilters]           = useState<any>({type: 0});
    const [usersRequest, setUsersRequest] = useState<any>([]);
    const [typesRequest, setTypesRequest] = useState<any>([]);

    // Contexts
    const { user, breadcrumbs, setBreadcrumbs } = useContext(GeneralContext);
    const { setModalOpen, setModalClass, setModalTitle, setModalBody } = useContext(ModalContext);

    function openModal(type: string, data: any) {
        if (type==="View") {
            setModalClass('viewRequest')
            setModalTitle('Visualizar Requisição')
            setModalBody(<>Visualizar</>)
            setModalBody(<View {...data} setModalOpen={setModalOpen} setReady={setReady} />)
        } else if (type === "NewRequest") {
            setModalClass('newRequest')
            setModalTitle('Nova Requisição')
            setModalBody(<NewRequest products={products} setModalOpen={setModalOpen} setReady={setReady} />)
        } else if (type === "Cancel") {
            setModalClass('cancelRequest')
            setModalTitle('Cancelando Requisição')
            setModalBody(<Cancel {...data} setModalOpen={setModalOpen} setReady={setReady} />)
        } else if (type === "AllProducts") {
            setModalClass('prodsRequest')
            setModalTitle('Visualizando Produtos')
            setModalBody(<Products products={products} />)
        }

        setModalOpen(true)
    }

    function onChangeFilters(e: React.ChangeEvent<HTMLSelectElement>) {
        let { name, value } = e.currentTarget;
        let data: any = filters;

        if (name === 'users') {
            data = { ...filters, user: value ? Number(value) : 0 }
            setFilters(data)
        } else if (name === 'types') {
            data = { ...filters, type: value ? Number(value) : 0 }
            setFilters(data)
        }

        // Filter Requests
        if (data.user !== 0 || data.type !== 0) {
            let listFiltered: any = requestsList;
            if (data.user !== 0) listFiltered = listFiltered.filter((item: any) => item.user.id === data.user);
            if (data.type !== 0) listFiltered = listFiltered.filter((item: any) => item.type.id === data.type);

            setRequests(listFiltered)
        } else setRequests(requestsList)
    }

    function getFilters(requests: any) {
        let users: any = []

        requests.map((el: any) => {
            let dataUser = { id: el.user.id, name: el.user.name }
            if (users.findIndex((el2: any) => el2.id === dataUser.id) < 0) users.push(dataUser)
        })

        setUsersRequest(users)
    }

    function getSelects() {
        return <>
            {
                usersRequest.length>1 ? <div className="users">
                    <select id="users" name="users" onChange={onChangeFilters} defaultValue={filters.user}>
                        <option value="">-- Usuários --</option>
                        {
                            usersRequest.map((el: any) => <option
                                key={`user_${el.id}`}
                                value={el.id}
                            >
                                {el.name}
                            </option>)
                        }
                    </select>
                </div> : <></>
            }

            {
                typesRequest.length>1 ? <div className="types">
                    <select id="types" name="types" onChange={onChangeFilters} defaultValue={filters.type}>
                        <option value="">-- Categorias --</option>
                        {
                            typesRequest.map((el: any) => <option
                                key={`user_${el.id}`}
                                value={el.id}
                            >
                                {el.name}
                            </option>)
                        }
                    </select>
                </div> : <></>
            }
        </>
    }

    function getContent() {
        if (error) return <div className='noData'>{error}</div>

        return <>
            <HeaderSearchAndForm
                buttons= {
                    [
                        {
                            nameButton: user.role==='admin' ? "Solicitação" : "Nova Solicitação",
                            className: "add",
                            onClick: () => openModal("NewRequest", products),
                            icons: <FaPlus size={14} />
                        }, {
                            nameButton: user.role==='admin' ? "Estoque" : "Lista de Produtos",
                            className: "info",
                            onClick: () => openModal("AllProducts", products)
                        }
                    ]
                }
                module={getSelects()}
                isViewButton={true}
                isViewSearch={false}
            />

            {
                requests && requests.length > 0 ? (
                    <div className='materials'>
                        {
                            requests.map((requestData: any) => (
                                <RequestBox
                                    key={`request_box_${requestData.id}`}
                                    {...requestData}
                                    openModal={openModal}
                                />
                            ))
                        }
                    </div>
                ) : <div className='noData'>Não foi possível localizar nenhuma requisição</div>
            }
        </>
    }

    async function loadInfos() {
        try {
            const [requestsResponse, resourcesResponse, requestTypes] = await Promise.all([
                api.get(`warehouse_requests${user.role!=='admin' ? '/user/' + user.id : ''}`, { headers: { Authorization: user.token } }),
                api.get('warehouse_resources', { headers: { Authorization: user.token } }),
                api.get('warehouse_resource_type', { headers: { Authorization: user.token } }),
            ]);

            // Treat Resources
            let resources: any = [];
            resourcesResponse.data.map((el: any) => resources.push({...el, type: el.resourceType.name}))

            // Set Datas
            setRequests(requestsResponse.data);
            setRequestsList(requestsResponse.data);
            if (user.role==='admin') getFilters(requestsResponse.data);
            setTypesRequest(requestTypes.data);
            setProducts(resources.sort(sort_by({ name: 'type' }, { name: 'name' })));

            // Quando ambos os estados forem setados, setReady(true) é chamado.
            setReady(true);
        } catch (error) {
            alert('Erro ao carregar informações do formulário')
            console.error('Erro ao carregar informações:', error);
            // Trate o erro apropriadamente, talvez com um setReady(false) ou exibição de mensagem de erro.
        }
    }

    useEffect(() => {
        if (!ready) loadInfos()
    }, [ready])

    // Breadcrumbs
    useEffect(() => {
        (!breadcrumbs || breadcrumbs.curr!=="Requisições") && setBreadcrumbs({
            curr: 'Requisições',
            links: [
                { name: 'Home', url: '/'},
                { name: 'Requisições' }
            ]
        });
    }, [breadcrumbs]);

    return <Template page="Solicitações de Materiais e Serviços" pageTitle="Requisições de Materiais e Serviços" className="requests">
        { ready ? getContent() : <Loading /> }
    </Template>;
}

export default SchoolSecretaryMaterials;