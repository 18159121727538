import React from "react";
import Treats from '~/utils/treats'
import './styles.css';

const ModalView: React.FC<any> = ({ data }: any) => {
    return <>
        <h3>{Treats.formatNames(data.student.name)}</h3>
        <p><b>Gênero:</b> {Treats.formatNames(data.student.sex)}</p>
        <p><b>Mãe:</b> {data.student.mother_name ? Treats.formatNames(data.student.mother_name) : 'Não Informado'}</p>
        <p><b>Pai:</b> {data.student.father_name ? Treats.formatNames(data.student.father_name) : 'Não Informado'}</p>
        <p><b>Endereço:</b> {Treats.formatNames(data.student.address.address)}, {data.student.address.number}</p>
        <p><b>Bairro / Cidade:</b> {Treats.formatNames(data.student.address.neighborhood)}, {Treats.formatNames(data.student.address.city)}</p>

        <hr />

        <h4>{Treats.formatNames(data.student.school.name)}</h4>
        <p><b>Endereço:</b> {Treats.formatNames(data.student.school.address)}, {data.student.school.address_number}</p>
        <p><b>Bairro / Cidade:</b> {Treats.formatNames(data.student.school.address_neighborhood)} / {Treats.formatNames(data.student.school.address_city)}</p>

        <hr />

        <h4>{Treats.formatNames(data.route.name)}</h4>
        <p><b>Veículo:</b> {Treats.formatNames(data.route.vehicle.name)} ({data.route.vehicle.brand} {data.route.vehicle.model} - {data.route.vehicle.license_plate})</p>
        <p><b>Motorista:</b> {Treats.formatNames(data.route.driver.name)}</p>
        <p><b>Monitor:</b> {Treats.formatNames(data.route.monitor.name)}</p>
    </>;
};

export default ModalView;
