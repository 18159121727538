import React, { useContext, useEffect, useState } from 'react';
import api from '~/services/api';
// import "./styles.css";

// Contexts
import { GeneralContext } from '~/contexts';

// Components
import TemplateTeacher from '../Template';
import Loading from '~/components/Default/Loading';
import ClassBox from './Box';

const TeacherGrades: React.FC = () => {
    const [ready, setReady]             = useState(false);
    const [classes, setClasses]         = useState<any>(null);
    const [classesList, setClassesList] = useState<any>([]);

    // Contexts
    const { user, breadcrumbs, setBreadcrumbs, checkAdminDirector, teacher } = useContext(GeneralContext);

    // Get page data
    useEffect(() => {
        let url = checkAdminDirector() && teacher ? '?teacher=' + teacher.id : '';

        !ready && api.get(`teacher/classes${url}`, {
            headers: { Authorization: user.token }
        }).then(resp => {
            setClasses(resp.data);
            setClassesList(resp.data);
            setReady(true);
        }).catch(() => {
            setReady(true);
        });
    }, [ready]);

    // Breadcrumbs
    useEffect(() => {
        (!breadcrumbs || breadcrumbs.curr!=="Notas e Outros") && setBreadcrumbs({
            curr: 'Notas e Outros',
            links: [
                { name: 'Home', url: '/'},
                { name: 'Professor', url: '/professor'},
                { name: 'Notas' }
            ]
        });
    }, [breadcrumbs]);

    return <TemplateTeacher
        page="Provas e Atividades"
        pageTitle="Professor - Provas e Atividades"
        className="teacherActivities"
        search={{
            dataList: classesList, // Substitua 'yourDataList' pela sua lista de dados
            filterKey: ["id", "name", "school.name", "teaching.name"], // Substitua 'nome' pela chave que você deseja filtrar
            setFilteredList: setClasses // Substitua 'setYourList' pela função que define a lista filtrada
        }}
    >
        {
            ready ? classes && classes.length > 0 ? (
                <div className="classes">
                    {
                        classes.map((data: any) =>
                            <ClassBox key={`class_${data.id}`} {...data} />)
                    }
                </div>
            ) : <div className='noData'>Não foi possível localizar nenhuma turma</div>
            : <Loading />
        }
    </TemplateTeacher>;
}

export default TeacherGrades;