import React from 'react';
import { FaClipboard, FaPenToSquare } from 'react-icons/fa6';
import './styles.css';

interface Props {
    id           : number;
    user         : any;
    type         : any;
    delivery_date: string;
    inactive     : boolean;
    justification: string;
    state        : string;
    resource_data: any[];
    openModal    : Function;
}

const RequestBox: React.FC<any> = (props: Props) => {
    function getButtons() {
        return <>
            <button type="button" className="response" onClick={() => props.openModal('response', props)} title="Responder solicitação">
                 <FaPenToSquare size={16} /> <span>Responder</span>
            </button>

            <button type="button" className="view" onClick={() => props.openModal('view', props)} title="Visualizar itens solicitados">
                <FaClipboard size={16} /> <span>Visualizar</span>
            </button>
        </>
    }

    return <div className="requestBox">
        <div className="infos">
            <h3>{ props.type.name  }</h3>
            <h4>{ props.user.name  }</h4>

            <div className="dataRequest">
                <b>Data da Solicitação:</b> { new Date(props.delivery_date).toLocaleDateString('pt-BR', {day: '2-digit',month: '2-digit',year: 'numeric',}) }
            </div>

            <div className={`stateRequest ${props.state.toLowerCase()}`}>
                <b>Status da Solicitação:</b> { props.state }
            </div>
        </div>

        <div className="buttons">
            { getButtons() }
        </div>
    </div>;
}

export default RequestBox;
