import React, { useContext, useEffect, useState } from 'react';
import api from '~/services/api';
import './styles.css';

// Contexts
import { GeneralContext } from '~/contexts';
import { ModalContext } from '~/contexts/modal';

// Components
import Template from '~/components/Default/Template';
import HeaderSearchAndForm from '~/components/Default/HeaderSearchAndForm';
import Loading from '~/components/Default/Loading';
import View from './Modal/view';
import DriverBox from './Box';

const TransportDrivers: React.FC = () => {
    const [ready, setReady]             = useState(false);
    const [error, setError]             = useState<string | null>(null);
    const [drivers, setDrivers]         = useState<any[]>([]);
    const [driversList, setDriversList] = useState<any[]>([]);

    // Contexts
    const { user, breadcrumbs, setBreadcrumbs } = useContext(GeneralContext);
    const { setModalOpen, setModalClass, setModalTitle, setModalBody } = useContext(ModalContext);

    function openModal(data: any) {
        setModalClass('driverView')
        setModalTitle('Dados do Motorista')
        setModalBody(<View data={data} setModalOpen={setModalOpen} setReady={setReady} />)
        setModalOpen(true)
    }

    function getContent() {
        if (error) return <div className='noData'>{error}</div>

        return <>
            <HeaderSearchAndForm
                buttons={[
                    { nameButton: "Cadastrar Novo Motorista", url: "/secretaria-educacao/funcionarios/novo?type=3", className: "add" }
                ]}
                search={{
                    dataList: driversList,
                    filterKey: [],
                    setFilteredList:setDriversList
                }}
                placeholder='Filtrar Motorista...'
                isViewButton={true}
                isViewSearch={true}
            />

            {
                drivers && drivers.length > 0 ? (
                    <div className='drivers'>
                        {
                            drivers.map((driversData: any) => (
                                <DriverBox
                                    key={`drivers_${driversData.id}`}
                                    data={driversData}
                                    openModal={openModal}
                                />
                            ))
                        }
                    </div>
                ) : <div className='noData'>Não foi possível localizar nenhum motorista</div>
            }
        </>
    }

    useEffect(() => {
        !ready && api.get('transport/drivers', {
            headers: { Authorization: user.token }
        }).then((resp: any) => {
            setDrivers(resp.data);
            setDriversList(resp.data);
            setReady(true);
        }).catch((err: any) => {
            setError('Erro ao carregar os motoristas')
            setReady(true)
        });
    }, [ready])

    // Breadcrumbs
    useEffect(() => {
        (!breadcrumbs || breadcrumbs.curr!=="Motoristas") && setBreadcrumbs({
            curr: 'Motoristas',
            links: [
                { name: 'Home', url: '/'},
                { name: 'Transporte', url: '/transporte'},
                { name: 'Motoristas' }
            ]
        });
    }, [breadcrumbs]);

    return <Template page="Motoristas" pageTitle="Transporte - Motoristas" className="transportDrivers">
         { ready ? getContent() : <Loading /> }
    </Template>;
}

export default TransportDrivers;