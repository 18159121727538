import React from 'react';
import './styles.css';

const MeasureBox: React.FC<any> = (props) => {
    return <div className="measureBox">
        <div className="name">{props.description}</div>
        <div className="measure">{props.measure}</div>
        <div className="btns"></div>
    </div>;
}

export default MeasureBox;
