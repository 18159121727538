import { ChangeEvent, useCallback, useContext, useEffect, useState } from "react";
import api from "~/services/api";
import './styles.css';

// Contexts
import { GeneralContext } from "~/contexts";

interface Product {
    id         ?: number;
    name       : string;
    description: string;
    quantity   : number;
    type_id    : number;
    measure_id : number;
    inactive   : boolean;
}

interface TypeProduct {
    id: string;
    type: string;
}

interface MeasureProduct {
    id: string;
    type: string;
}

// Types Array
const filterArr = ['transporte', 'nutricao', 'manutencao'];

// Default Product Value
const prodDefault = {
    name       : '',
    description: '',
    quantity   : 0,
    type_id    : 0,
    measure_id : 0,
    inactive   : false
}

const ModalCreate: React.FC<any> = (props: any) => {
    const [ready, setReady]             = useState<boolean>(false);
    const [error, setError]             = useState<string | null>(null);
    const [loadData, setLoadData]       = useState<boolean>(false);
    const [types, setTypes]             = useState<TypeProduct[]>([]);
    const [measures, setMeasures]       = useState<MeasureProduct[]>([]);
    const [productData, setProductData] = useState<Product>(prodDefault);

    // Contexts
    const { user } = useContext(GeneralContext);

    function onChange(event: ChangeEvent<HTMLInputElement | HTMLSelectElement>) {
        const { name, value } = event.target;
        let newProduct = JSON.parse(JSON.stringify(productData));
        setProductData({...newProduct, [name]: (['name', 'description'].includes(name) ? value : Number(value))});
    }

    async function saveDataForm() {
        if (productData.name.trim() === "") {
            setError("Preencha todos os campos.");
            return;
        }

        // Clear states
        setLoadData(true);
        setError(null);

        // Set Body POST
        let dataFormat: any = productData;

        // Send data to server
        await api.post('warehouse_resources', dataFormat, {
            headers: { Authorization: user.token }
        }).then(resp => {
            setLoadData(false);
            props.setReady(false);
            props.setModalOpen(false);
        }).catch(errr => {
            console.log("Errors")
            console.log(errr)
            setError("Erro ao salvar os dados. Tente novamente.")
        });
    }

    function getContent() {
        if (loadData) return <div className="saving">Salvando dados...</div>

        return <form method="post" onSubmit={(event: React.FormEvent<HTMLFormElement>) => event.preventDefault()} className="editUser">
            {error && <p style={{ color: 'red' }}>{error}</p>}

            <div className="form-group">
                <div className="input-group">
                    <label>Nome</label>
                    <input
                        type="text"
                        placeholder="ex.: Lapis"
                        name="name"
                        value={productData.name}
                        onChange={onChange}
                        required
                    />
                </div>

                <div className="input-group">
                    <label>Descrição</label>
                    <input
                        type="text"
                        placeholder="ex.: Lapis de Escrever"
                        name="description"
                        value={productData.description}
                        onChange={onChange}
                        required
                    />
                </div>

                <div className="input-group">
                    <label>Quantidade</label>
                    <input
                        type="text"
                        placeholder="ex.: 100"
                        name="quantity"
                        value={productData.quantity ? productData.quantity : ''}
                        onChange={onChange}
                        required
                    />
                </div>

                <div className="selects">
                    <div className="input-group">
                        <label htmlFor="type">Categoria</label>
                        <select onChange={onChange} id="type" name="type_id" defaultValue={productData.type_id ? productData.type_id : undefined} required>
                            <option value="0">Selecione uma categoria</option>

                            {
                                types && types.filter((el: any) => !filterArr.includes(el.type)).map((type: any) => (
                                    <option key={`type_${type.id}`} value={type.id}>{type.name}</option>
                                ))
                            }
                        </select>
                    </div>

                    <div className="input-group">
                        <label htmlFor="measure">Unidade</label>
                        <select onChange={onChange} id="measure" name="measure_id" defaultValue={productData.measure_id ? productData.measure_id : undefined} required>
                            <option value="0">Selecione uma medida</option>

                            {
                                measures && measures.map((m: any) => (
                                    <option key={m.id} value={m.id}>{m.description}</option>
                                ))
                            }
                        </select>
                    </div>
                </div>

                <div className="input-group btns">
                    <button id="btnAdd" type="button" onClick={saveDataForm}>Salvar</button>
                    <button id="btnCancel" type="button" onClick={() => props.setModalOpen(false)}>Cancelar</button>
                </div>
            </div>
        </form>
    }

    // Get Form Data
    const getData = useCallback(async () => {
        // Get Select Data
        try {
            const [type, measure] = await Promise.all([
                api.get('warehouse_resource_type', { headers: { Authorization: user.token } }),
                api.get('warehouse_measure', { headers: { Authorization: user.token } }),
            ]);

            setTypes(type.data);
            setMeasures(measure.data);
            setReady(true);
        } catch (error) {
            console.error('Error fetching data', error);
        }

        // Set Edit Data If Exists
        if (props.id) setProductData(props);
    }, []);

    useEffect(() => {
        !ready && getData()
    }, [ready])

    return ready ? getContent() : <></>;
};

export default ModalCreate;
