import React from 'react';
import './styles.css';

const RequestLine: React.FC<any> = (props: any) => {
    let items = JSON.parse(props.resource_data);

    return <div className="requestLine">
        <div className="category">
            <small>Categoria</small>
            <span>{ props.type.name  }</span>
        </div>

        <div className="qtty">
            <small>Produtos</small>
            <span>{items.length} Solicitado{ items.length>1 ? 's' : '' }</span>
        </div>

        <div className="user">
            <small>Requerinte</small>
            <span>{ props.user.name }</span>
        </div>

        <div className="delivery">
            <small>Data da Requisição</small>
            <span>{ new Date(props.delivery_date).toLocaleDateString('pt-BR', {day: '2-digit',month: '2-digit',year: 'numeric',})  }</span>
        </div>
    </div>;
}

export default RequestLine;
