import React, { useContext, useEffect } from 'react';
import { FaFilePdf } from 'react-icons/fa6';
import './styles.css';

// Contexts
import { GeneralContext } from '~/contexts';
import { ModalContext } from '~/contexts/modal';

// Components
import Template from '~/components/Default/Template';
import Modal from './Modal';

const SchoolSecretaryReports: React.FC = () => {
    // Contexts
    const { breadcrumbs, setBreadcrumbs } = useContext(GeneralContext);
    const { setModalClass, setModalOpen, setModalTitle, setModalBody } = useContext(ModalContext);

    function openModal(doc: string) {
        let reportName = getReportName(doc);

        setModalClass('createReports')
        setModalTitle(reportName)
        setModalBody(<Modal reportName={reportName} report={doc} />)
        setModalOpen(true)
    }

    function getReportName (doc: string) {
        switch (doc) {
            case "students":
                return "Relatório de Alunos";
            case "teachers":
                return "Relatório de Professores";
            default:
                return false;
        }
    }

    // Breadcrumbs
    useEffect(() => {
        (!breadcrumbs || breadcrumbs.curr!=="Relatórios") && setBreadcrumbs({
            curr: 'Relatórios',
            links: [
                { name: 'Home', url: '/'},
                { name: 'Secretaria Escolar', url: '/secretaria-escolar'},
                { name: 'Relatórios' }
            ]
        });
    }, [breadcrumbs]);

    return <Template page="Relatórios" pageTitle="Secretaria Escolar - Relatórios" className="secEscReports">
        <div className="reports">
            <button className='btns decStudents' onClick={() => openModal('students')}>
                <FaFilePdf size={18} />
                <span>Relatório de Alunos</span>
            </button>

            <button className='btns decTeachers' onClick={() => openModal('teachers')}>
                <FaFilePdf size={18} />
                <span>Relatório de Professores</span>
            </button>
        </div>
    </Template>;
}

export default SchoolSecretaryReports;