import { useContext, useEffect, useState } from "react";
import api from "~/services/api";
import './styles.css';

// Contexts
import { GeneralContext } from "~/contexts";
import { ModalContext } from "~/contexts/modal";

// Components
import MeasureBox from "../Box/measures";
import { FormMeasure } from ".";

const Measures = () => {
    const [ready, setReady]           = useState(false);
    const [measures, setMeasures] = useState<any>(null);

    // Contexts
    const { user } = useContext(GeneralContext);
    const { setModalOpen, setModalClass, setModalTitle, setModalBody } = useContext(ModalContext);

    function addMeasure() {
        setModalClass('category')
        setModalTitle('Adicionar Nova Categoria')
        setModalBody(<FormMeasure />)
        setModalOpen(true)
    }

    useEffect(() => {
        !ready && api.get('warehouse_measure', {
            headers: { Authorization: user.token }
        }).then(resp => {
            setMeasures(resp.data);
            setReady(true);
        });
    }, [ready]);

    return ready ? <>
        <div className="header">
            <div className="name">Nome</div>
            <div className="measure">Unidade</div>
            <div className="btns"></div>
        </div>

        {
            measures.map((el: any) => <MeasureBox key={`mea_${el.id}`} {...el} />)
        }

        <div className="btns">
            <button
                type="button"
                name="add"
                className="addMea"
                onClick={() => addMeasure()}
            >Adicionar</button>

            <button
                type="button"
                name="close"
                className="closeMea"
                onClick={() => setModalOpen(false)}
            >Fechar</button>
        </div>
    </> : <></>
};

export default Measures;
