import React from 'react';
import { FaChevronLeft } from 'react-icons/fa6';
import { PlanningData } from '../types';

// Components
import HeaderSearchAndForm from '~/components/Default/HeaderSearchAndForm';
import { InputLabel, Button } from '~/components/Forms';

// Images
import hat from '../icons/hat.png';
import books from '../icons/books.png';
import teacher from '../icons/teacher.png';

interface PlanningInitProps {
	data: PlanningData;
	subjects: any;
	setData: React.Dispatch<React.SetStateAction<PlanningData>>;
	setStep: React.Dispatch<React.SetStateAction<number>>;
}

const PlanningInit: React.FC<PlanningInitProps> = ({ data, setData, setStep, subjects }) => {
	const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
		setData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
	};

	const validateAndProceed = () => {
		if (!data.bimester || !data.series || !data.subject || !data.level) {
			alert('Preencha todos os campos antes de continuar.');
			return;
		}

		if (data.level === 'Ensino Infantil')
			setStep(1);
		else if (data.level === 'Ensino Fundamental')
			setStep(2);
	};

	return <>
		<HeaderSearchAndForm
			buttons={[{ nameButton: "Voltar", url: "/professor/planejamento", icons: <FaChevronLeft size={14} /> }]}
			isViewButton={true}
			isViewSearch={false}
		/>

		<div className="init">
			<header>
				<h1>Planejamento de Aulas</h1>
				<p>Preencha as informações iniciais para começar o planejamento.</p>
			</header>

			<div className="content ">
				<div className='formGroup'>
					<img src={hat} alt="Chapéu de Formatura" />

					<div className="formItem inputLabel">
						<label htmlFor="bimester">Bimestre</label>
						<select onChange={handleInputChange} id="bimester" name="bimester" value={data?.bimester} defaultValue={data?.bimester} required>
							<option value="" disabled>  Selecione um bimestre </option>
							<option value="1">1</option>
							<option value="2">2</option>
							<option value="3">3</option>
							<option value="4">4</option>
						</select>
					</div>

					<div className="formItem inputLabel">
						<label htmlFor="level">Nível de Ensino</label>
						<select onChange={handleInputChange} id="level" name="level" value={data?.level} defaultValue={data?.level} required>
							<option value="" disabled>  Selecione um nível </option>
							<option value="Ensino Infantil">Ensino Infantil</option>
							<option value="Ensino Fundamental">Ensino Fundamental</option>
						</select>
					</div>
				</div>

				<div className='formGroup'>
					<img src={books} alt="Livros" />

					<InputLabel
						name="series"
						labelName="Série"
						type="text"
						value={data.series}
						onChange={handleInputChange}
					/>

					<div className="formItem inputLabel">
						<label htmlFor="subject">Matéria</label>
						<select onChange={handleInputChange} id="subject" name="subject" value={data?.subject} defaultValue={data?.subject} required>
							<option value="" disabled>  Selecione uma matéria </option>
							{
								subjects.map((el: any) => <option key={'subject_' + el.id} value={el.id}>{el.name}</option>)
							}
						</select>
					</div>
				</div>

				<div className='formGroup'>
					<img src={teacher} alt="Professora" />
					<Button type="button" text="Iniciar" className="primary" func={validateAndProceed} />
				</div>
			</div>
		</div>
	</>;
};

export default PlanningInit;
