import React, { useContext } from 'react';
import { FaEye, FaFilePdf } from 'react-icons/fa6';
import dates from '~/utils/dates';
import './styles.css';

// Components
import Infos from './infos';
import Export from './export';

// Contexts
import { ModalContext } from '~/contexts/modal';

const VisitTermBox: React.FC<any> = (props: any) => {
    const visitDate = dates.getDateFormatted(props.visit_date, 'dd/mm/yyyy');

    // Contexts
    const { setModalOpen, setModalButton, setModalClass, setModalTitle, setModalBody } = useContext(ModalContext);

    function openModal(type?: string, data?: any) {
        if (type === 'export') {
            setModalButton(false);
            setModalClass('createVisitTerm')
            setModalTitle('Exportando Termo de Visita')
            setModalBody(<Export data={data} />)
        } else {
            setModalClass('visitTermInfos')
            setModalTitle('Visualizando Termo de Visita')
            setModalBody(<Infos data={props} />)
        }

        setModalOpen(true);
    }

    return <div className="visitTermBox">
        <div className="infos">
            <h3>{ props.school.name }</h3>

            <div className="sup">
                <b>Supervisor:</b> { props.supervision_name }
            </div>

            <hr />

            <div className="date">
                <b>Visita:</b> { visitDate }
            </div>

            <div className="time">
                <b>Horário:</b> { props.visit_time_init } às { props.visit_time_end }
            </div>
        </div>

        <div className="buttons">
            <button type="button" className="view" onClick={() => openModal('export', props)} title="Exportar Termo de Visita">
                <FaFilePdf size={16} /><span> Exportar</span>
            </button>

            <button type="button" className="view" onClick={() => openModal()} title="Visualizar Termo de Visita">
                <FaEye size={16} /><span> Visualizar</span>
            </button>
        </div>
    </div>;
}

export default VisitTermBox;