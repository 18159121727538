import React, { ChangeEvent, useContext, useEffect, useRef, useState } from "react";
import api from "~/services/api";
import './styles.css';

// Contexts
import { GeneralContext } from "~/contexts";
import treats from "~/utils/treats";

const ModalCreate: React.FC<any> = ({ data, openModal }: any) => {
    const [activityGrade, setActivityGrade] = useState<any>([]);
    const [loadData, setLoadData] = useState<boolean>(false);
    const [error, setError]       = useState<string | null>(null);

    // Contexts
    const { user } = useContext(GeneralContext);

    const onChange = (e: ChangeEvent<HTMLInputElement>, listID: number) => {
        const { name, value } = e.target;

        let data = JSON.parse(JSON.stringify(activityGrade));
        data[listID] = {...data[listID], [name]: Number(value)}

        setActivityGrade(data);
    };

    const getGrade = (id: number, n?: boolean) => {
        let filter = data.grades.filter((el: any) => el.student_id === id);
        let ret    = filter.length === 1 ? filter[0].grade : (n ? 0 : '-');

        return ret
    }

    const getActivityGradeData = () => {
        let gradeData: any = [];

        if (data.students.length > 0) {
            data.students.map((el: any) => {
                gradeData.push({
                    activity_id: data.activity_id,
                    student_id: el.id,
                    student_name: treats.formatNames(el.name),
                    grade: getGrade(el.id, true)
                })
            })
        }

        setActivityGrade(gradeData);
    }

    async function saveDataForm() {
        api.post('teacher/activity-test-grade', activityGrade, {
            headers: { Authorization: user.token }
        }).then(resp => {
            setLoadData(false);
            openModal('GradesStudents', { ...data, grades: resp.data });
        }).then(errr => {
            setError('Erro ao cadastrar notas');
            setLoadData(false);
        })
    }

    useEffect(() => {
        getActivityGradeData();
    }, [])

    return !loadData ? (
        <form method="post" onSubmit={(event: React.FormEvent<HTMLFormElement>) => event.preventDefault()} className="create">
            {error && <div className="error">{error}</div>}

            <div className="studentsList">
                <div className="studentHeader">
                    <div className="name">Aluno</div>
                    <div className="grade">Nota</div>
                </div>

                {
                    activityGrade.length > 0 ? activityGrade.map((el: any, idx: number) => (
                        <div className="studentLine" key={`std_${el.student_id}`}>
                            <div className="name">{el.student_name}</div>
                            <div className="grade">
                                <input
                                    type="number"
                                    placeholder="0"
                                    min="0"
                                    max="10"
                                    name="grade"
                                    value={el.grade}
                                    onChange={e => onChange(e, idx)}
                                    required
                                />
                            </div>
                        </div>
                    )) : (
                        <div className="alertInfo">Nenhuma atividade cadastrada</div>
                    )
                }
            </div>

            <div className="btns">
                <button id="btnAdd" type="button" onClick={saveDataForm}>Salvar</button>
                <button id="btnCancel" type="button" onClick={() => openModal('GradesStudents', data)}>Voltar</button>
            </div>
        </form>
    ) : (
        <div className="saving">Salvando dados...</div>
    );
};

export default ModalCreate;
