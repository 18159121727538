import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import api from '~/services/api';

// Contexts
import { GeneralContext } from '~/contexts';

// Components
import TemplateTeacher from '../Template';
import Loading from '~/components/Default/Loading';

const TeacherPlanning: React.FC = () => {
    const [ready, setReady] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [plans, setPlans] = useState<any>(null);

    // Contexts
    const { breadcrumbs, setBreadcrumbs } = useContext(GeneralContext);

    function getContent() {
        if (error) return <div className='noData'>{error}</div>

        return plans && plans.length > 0 ? (
            <div className='plans'>
                {
                    plans.map((planData: any) => console.log(planData))
                }
            </div>
        ) : <div className='noData'>Não foi possível localizar nenhum plano de aula</div>
    }

    // Get page data
    useEffect(() => {
        !ready && setReady(true);
        // !ready && api.get('teacher/plans', {
        //     headers: { Authorization: user.token }
        // }).then(resp => {
        //     setPlans(resp.data);
        // }).catch(err => {
        //     setError('Erro ao carregar os dados dos planos de aula');
        //     setReady(true);
        // });
    }, [ready]);

    // Breadcrumbs
    useEffect(() => {
        (!breadcrumbs || breadcrumbs.curr!=="Planejamento de Aulas") && setBreadcrumbs({
            curr: 'Planejamento de Aulas',
            links: [
                { name: 'Home', url: '/'},
                { name: 'Professor', url: '/professor'},
                { name: 'Planejamento' }
            ]
        });
    }, [breadcrumbs]);

    return <TemplateTeacher
        page="Planejamento de Aulas"
        pageTitle="Professor - Planejamento de Aulas"
        className="teacherPlanning"
        buttons={[{ nameButton: "Criar Planejamento", url: "cadastro" }]}
    >
        { ready ? getContent() : <Loading /> }
    </TemplateTeacher>;
}

export default TeacherPlanning;