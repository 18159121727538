import { ChangeEvent, useContext, useEffect, useState } from 'react';
import dates from '~/utils/dates';
import Treats from '~/utils/treats';
import validateForm from '~/utils/validateForm';
import env from '~/utils/env';
import api from '~/services/api';
import './styles.css';

// Contexts
import { GeneralContext } from '~/contexts';
import { ModalContext } from '~/contexts/modal';

// Components
import InputLabel, { InputLabelMask } from '~/components/Forms/InputLabel';

// Base Data
let baseData = {
    "SUPERVISOR_NAME"            : "",
    "SUPERVISOR_RG"              : "",
    "SUPERVISOR_CPF"             : "",
    "EDUCATION_AGENCY"           : "",
    "SCHOOL_ID"                  : "",
    "SCHOOL_NAME"                : "",
    "SCHOOL_ADDRESS"             : "",
    "VISIT_DATE"                 : "",
    "VISIT_OBJECT"               : "",
    "VISIT_INIT_TIME"            : "",
    "VISIT_END_TIME"             : "",
    "SCHOOL_RESPONSIBLE_PRESENT" : "",
    "SCHOOL_RESPONSIBLE_POSITION": "",
    "NAME_POSITIONS"             : "",
    "OBSERVATIONS_CONCLUSIONS"   : "",
    "CITY"                       : env.city,
    "DATE"                       : ""
};

const VisitTermDoc: React.FC<any> = ({ setReadyPage } : any) => {
    const [ready, setReady]       = useState<boolean>(false);
    const [loadData, setLoadData] = useState<boolean>(false);
    const [data, setData]         = useState<any>(baseData);
    const [schools, setSchools]   = useState<any>([]);
    const [errors, setErrors]     = useState<any>(null);

    // Contexts
    const { user } = useContext(GeneralContext);
    const { setModalOpen } = useContext(ModalContext);

    function onChange(event: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) {
        let { name, value, type } = event.target;

        // Set Data
        if (name === 'SCHOOL_ID') { // Specific to school
            if (value !== '') {
                let schoolData = schools.filter((el: any) => el.id === Number(value))
                setData({
                    ...data,
                    'SCHOOL_ID': value,
                    'SCHOOL_NAME': Treats.formatNames(schoolData[0].name),
                    'SCHOOL_ADDRESS': Treats.formatNames(schoolData[0].address + (schoolData[0].address_number ? ', ' + schoolData[0].address_number : '') + ' - ' + schoolData[0].address_neighborhood)
                });
            } else {
                setData({
                    ...data,
                    'SCHOOL_ID': value,
                    'SCHOOL_NAME': "",
                    'SCHOOL_ADDRESS': ""
                });
            }
        } else {
            setData({ ...data, [name]: value });
        }
    }

    function generateDate (date?: string, type?: string) {
        let dateBase = date ? dates.getDate(date) : dates.getDate();

        return type && type==='short' ?
            dateBase.day + '/' + dateBase.month + '/' + dateBase.year :
            dateBase.day + ' de ' + dateBase.monthName + ' de ' + dateBase.year;
    }

    function getContent () {
        if (loadData) return <div className="saving">Gerando documento...</div>

        return <>
            <form method="post" onSubmit={(event: React.FormEvent<HTMLFormElement>) => event.preventDefault()}>
                <h3>Dados o Supervisor</h3>

                <div className="form-group no-margin">
                    <InputLabel
                        name="SUPERVISOR_NAME"
                        type='text'
                        className='supervisor_name'
                        labelName='Nome do Supervisor'
                        value={data?.SUPERVISOR_NAME}
                        onChange={onChange}
                        error={errors && errors['SUPERVISOR_NAME']}
                    />

                    <InputLabel
                        name="EDUCATION_AGENCY"
                        type='text'
                        className='agency'
                        labelName='Agência de Ensino'
                        placeholder='Secretaria da Educação'
                        value={data?.EDUCATION_AGENCY}
                        onChange={onChange}
                        error={errors && errors['EDUCATION_AGENCY']}
                    />
                </div>

                <div className="form-group no-margin">
                    <InputLabelMask
                        name='SUPERVISOR_RG'
                        value={data?.SUPERVISOR_RG}
                        type='text'
                        mask="99.999.999-9"
                        placeholder='12.345.678-9'
                        labelName='RG'
                        onChange={onChange}
                        error={errors && errors['SUPERVISOR_RG']}
                    />

                    <InputLabelMask
                        name='SUPERVISOR_CPF'
                        value={data?.SUPERVISOR_CPF}
                        type='text'
                        mask="999.999.999-99"
                        placeholder='123.456.789-10'
                        labelName='CPF'
                        onChange={onChange}
                        error={errors && errors['SUPERVISOR_CPF']}
                    />
                </div>

                <hr />

                <h3>Dados da Visita</h3>

                <div className="form-group no-margin">
                    <div className="inputLabel">
                        <label>Escola</label>
                        <select name="SCHOOL_ID" onChange={onChange} defaultValue={data?.SCHOOL_ID} disabled={schools && schools.length>0 ? false : true}>
                            <option value="">-- Selecione a Escola --</option>

                            {
                                schools.map((el: any) => <option key={`school_${el.id}`} value={el.id}>
                                    { el.name }
                                </option>)
                            }
                        </select>
                    </div>
                </div>

                <div className="form-group no-margin">
                    <InputLabel
                        name="VISIT_DATE"
                        type='date'
                        className='date_visit'
                        labelName='Data da Visita'
                        value={data?.VISIT_DATE}
                        onChange={onChange}
                        error={errors && errors['VISIT_DATE']}
                    />

                    <InputLabel
                        name="VISIT_INIT_TIME"
                        type='time'
                        className='time_visit'
                        labelName='Hora de Início'
                        value={data?.VISIT_INIT_TIME}
                        onChange={onChange}
                        error={errors && errors['VISIT_INIT_TIME']}
                    />

                    <InputLabel
                        name="VISIT_END_TIME"
                        type='time'
                        className='time_visit'
                        labelName='Hora de Termino'
                        value={data?.VISIT_END_TIME}
                        onChange={onChange}
                        error={errors && errors['VISIT_END_TIME']}
                    />
                </div>

                <div className="form-group no-margin">
                    <InputLabel
                        name="VISIT_OBJECT"
                        type='text'
                        labelName='Objetivo da Visita'
                        value={data?.VISIT_OBJECT}
                        onChange={onChange}
                        error={errors && errors['VISIT_OBJECT']}
                    />
                </div>

                <div className="form-group no-margin">
                    <InputLabel
                        name="SCHOOL_RESPONSIBLE_PRESENT"
                        type='text'
                        labelName='Nome do Representante'
                        value={data?.SCHOOL_RESPONSIBLE_PRESENT}
                        onChange={onChange}
                        error={errors && errors['SCHOOL_RESPONSIBLE_PRESENT']}
                    />

                    <InputLabel
                        name="SCHOOL_RESPONSIBLE_POSITION"
                        type='text'
                        labelName='Cargo do Representante'
                        value={data?.SCHOOL_RESPONSIBLE_POSITION}
                        onChange={onChange}
                        error={errors && errors['SCHOOL_RESPONSIBLE_POSITION']}
                    />
                </div>

                <div className="form-group no-margin">
                    <InputLabel
                        name="NAME_POSITIONS"
                        type='text'
                        labelName='Outros Acompanhantes'
                        value={data?.NAME_POSITIONS}
                        onChange={onChange}
                        error={errors && errors['NAME_POSITIONS']}
                    />
                </div>

                <hr />

                <h3>Conclusão</h3>

                <div className="form-group no-margin">
                    <div className="inputLabel">
                        <textarea
                            name="OBSERVATIONS_CONCLUSIONS"
                            onChange={onChange}
                            rows={4}
                        >
                            {data?.OBSERVATIONS_CONCLUSIONS}
                        </textarea>
                    </div>
                </div>
            </form>

            <div className="buttons">
                {errors && <div className="error">{errors}</div>}
                <button id="btnAdd" type="button" onClick={saveDataForm}>Salvar</button>
                <button id="btnCancel" type="button" onClick={() => setModalOpen(false)}>Cancelar</button>
            </div>
        </>
    }

    function saveDataForm() {
        setErrors(false);

        // Check Errors
        if (validateForm.checkFields(data, ['DATE'])) {
            setErrors('Todos os campos devem estar preenchidos')
            return false
        } else if (validateForm.checkFormat(data, ['VISIT_DATE', 'VISIT_INIT_TIME', 'VISIT_END_TIME', 'SUPERVISOR_RG', 'SUPERVISOR_CPF'])) {
            setErrors('Dados inválidos encontrados')
            return false
        }

        let DocData            = JSON.parse(JSON.stringify(data));
            DocData.DATE       = generateDate();
            DocData.VISIT_DATE = generateDate(DocData.VISIT_DATE + ' 03:00:00', 'short');

        setLoadData(true);

        api.post('docs/visit_term', DocData, {
            headers: { Authorization: user.token },
            responseType: 'blob'
        }).then(async resp => {
            setLoadData(false);
            setReadyPage(false);
            setModalOpen(false);

            // Generate Doc
            let url = window.URL.createObjectURL(new Blob([resp.data], { type: 'application/pdf' }));
            window.open(url, 'Termo de Visita', "directories=no,titlebar=no,toolbar=no,location=no,status=no,menubar=no,scrollbars=no,resizable=no,width=900,height=600");
        }).catch(errr => {
            console.log(errr)
        });
    }

    useEffect(() => {
        !ready && api.get('school/students', {
            headers: { Authorization: user.token }
        }).then(resp => {
            setSchools(resp.data)
            setReady(true)
        }).catch(errr => {
            console.log(errr)
        });
    }, [ready])

    return ready ? getContent() : <></>
}

export default VisitTermDoc;