import React, { ChangeEvent } from 'react';
import './styles.css';

// Component
import InputLabel from '~/components/Forms/InputLabel';

const NewStudentDeficiency: React.FC<any> = ({ data, setData, errors } : any) => {
    async function onChange(event: ChangeEvent<HTMLInputElement | HTMLSelectElement>) {
        let { name, value } = event.target;
        setData({ ...data, [name]: value });
    }

    return (
        <>
            <div className="formGroup">
                <div className="formItem w1-4">
                    <label htmlFor="reduced_mobility">Mobilidade reduzida?</label>
                    <select onChange={onChange} id="reduced_mobility" name="reduced_mobility" value={data?.reduced_mobility} defaultValue={data?.reduced_mobility} required>
                        <option value="">Selecione</option>
                        <option value="true">Sim</option>
                        <option value="false">Não</option>
                    </select>
                </div>

                <div className="formItem w1-4">
                    <InputLabel
                        name="reduced_mobility_type"
                        type='text'
                        labelName='Tipo de mobilidade reduzida'
                        onChange={onChange}
                        value={data?.reduced_mobility_type}
                        error={errors && errors['reduced_mobility_type']}
                    />
                </div>

                <div className="formItem w1-4">
                    <label htmlFor="school_support">Suporte da escola?</label>
                    <select onChange={onChange} id="school_support" name="school_support" value={data?.school_support} defaultValue={data?.school_support} required>
                        <option value="">Selecione</option>
                        <option value="true">Sim</option>
                        <option value="false">Não</option>
                    </select>
                </div>

                <div className="formItem w1-4">
                    <label htmlFor="school_support_daily_activities">Suporte com atividades diárias?</label>
                    <select onChange={onChange} id="school_support_daily_activities" name="school_support_daily_activities" value={data?.school_support_daily_activities} defaultValue={data?.school_support_daily_activities} required>
                        <option value="">Selecione</option>
                        <option value="true">Sim</option>
                        <option value="false">Não</option>
                    </select>
                </div>
            </div>

            <div className="formGroup">
                <div className="formItem w1-4">
                    <label htmlFor="school_support_activities">Suporte com atividades da escola?</label>
                    <select onChange={onChange} id="school_support_activities" name="" value={data?.school_support_activities} defaultValue={data?.school_support_activities} required>
                        <option value="">Selecione</option>
                        <option value="true">Sim</option>
                        <option value="false">Não</option>
                    </select>
                </div>

                <div className="formItem w1-4">
                    <label htmlFor="school_support_hygiene">Suporte com higiene?</label>
                    <select onChange={onChange} id="school_support_hygiene" name="school_support_hygiene" value={data?.school_support_hygiene} defaultValue={data?.school_support_hygiene} required>
                        <option value="">Selecione</option>
                        <option value="true">Sim</option>
                        <option value="false">Não</option>
                    </select>
                </div>

                <div className="formItem w1-4">
                    <label htmlFor="school_support_locomotion">Suporte com locomoção?</label>
                    <select onChange={onChange} id="school_support_locomotion" name="school_support_locomotion" value={data?.school_support_locomotion} defaultValue={data?.school_support_locomotion} required>
                        <option value="">Selecione</option>
                        <option value="true">Sim</option>
                        <option value="false">Não</option>
                    </select>
                </div>

                <div className="formItem w1-4">
                    <label htmlFor="school_support_food">Suporte com alimentação?</label>
                    <select onChange={onChange} id="school_support_food" name="school_support_food" value={data?.school_support_food} defaultValue={data?.school_support_food} required>
                        <option value="">Selecione</option>
                        <option value="true">Sim</option>
                        <option value="false">Não</option>
                    </select>
                </div>
            </div>

            <div className="formGroup">
                <div className="formItem w1-4">
                    <InputLabel
                        name="start_term_activities_daily"
                        type='date'
                        labelName='Início das atividades diárias'
                        onChange={onChange}
                        value={data?.start_term_activities_daily}
                        error={errors && errors['start_term_activities_daily']}
                    />
                </div>

                <div className="formItem w1-4">
                    <InputLabel
                        name="end_term_activities_daily"
                        type='date'
                        labelName='Termino das atividades diárias'
                        onChange={onChange}
                        value={data?.end_term_activities_daily}
                        error={errors && errors['end_term_activities_daily']}
                    />
                </div>

                <div className="formItem w1-4">
                    <InputLabel
                        name="start_term_school_activities"
                        type='date'
                        labelName='Início das atividades escolares'
                        onChange={onChange}
                        value={data?.start_term_school_activities}
                        error={errors && errors['start_term_school_activities']}
                    />
                </div>

                <div className="formItem w1-4">
                    <InputLabel
                        name="end_term_school_activities"
                        type='date'
                        labelName='Termino das atividades escolares'
                        onChange={onChange}
                        value={data?.end_term_school_activities}
                        error={errors && errors['end_term_school_activities']}
                    />
                </div>
            </div>
        </>
    );
};

export default NewStudentDeficiency;