import React from 'react';
import { FaEye, FaPencil, FaTrash } from 'react-icons/fa6';
import './styles.css';

const LineBox: React.FC<any> = ({data, openModal}: any) => {
    function getButtons() {
        return <>
            <button type="button" className="view" onClick={() => openModal("view", data)} title="Visualizar Linha">
                <FaEye size={16} /> <span>Visualizar</span>
            </button>

            <button type="button" className="edit" onClick={() => openModal("edit", data)} title="Editar Linha">
                <FaPencil size={14} />
            </button>

            <button type="button" className="inactivate" onClick={() => openModal('delete', data)} title="Deletar Linha">
                <FaTrash size={14} />
            </button>
        </>
    }

    return <div className="lineBox">
        <div className="infos">
            <h3>{data.name}</h3>

            <div className="description">
                {data.description}
            </div>

        </div>

        <div className="buttons">
            {getButtons()}
        </div>
    </div>;
}

export default LineBox;
