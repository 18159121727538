import React, { useContext, useEffect, useState } from 'react';
import api from '~/services/api';
import './styles.css';

// Contexts
import { GeneralContext } from '~/contexts';
import { ModalContext } from '~/contexts/modal';

// Components
import Template from '~/components/Default/Template';
import HeaderSearchAndForm from '~/components/Default/HeaderSearchAndForm';
import Loading from '~/components/Default/Loading';
import Create from './Modal/create';
import View from './Modal/view';
import Remove from './Modal/remove';
import TransportStudentsBox from './Box';

const TransportStudents: React.FC = () => {
    const [ready, setReady]               = useState(false);
    const [students, setStudents]         = useState<any>(null);
    const [studentsList, setStudentsList] = useState<any>([]);
    const [error, setError]               = useState<string | null>(null);

    // Contexts
    const { user, breadcrumbs, setBreadcrumbs } = useContext(GeneralContext);
    const { setModalOpen,setModalClass, setModalTitle, setModalBody } = useContext(ModalContext);

    function openModal(type: string, data?: any) {
        if (type === "add") {
            setModalClass('createEditStudent')
            setModalTitle('Adicionar Aluno a Linha')
            setModalBody(<Create  setModalOpen={setModalOpen} setReady={setReady} />)
        } else if (type === "edit") {
            setModalClass('createEditStudent')
            setModalTitle('Editar Aluno da Linha')
            setModalBody(<Create data={data} setModalOpen={setModalOpen} setReady={setReady} />)
        } else if (type === "view") {
            setModalClass('viewStudent')
            setModalTitle('Visualizar Aluno da Linha')
            setModalBody(<View data={data} setModalOpen={setModalOpen} setReady={setReady} />)
        } else if (type === "delete") {
            setModalClass('deleteStudent')
            setModalTitle('Remover Aluno da Linha')
            setModalBody(<Remove data={data} setModalOpen={setModalOpen} setReady={setReady} />)
        }
        setModalOpen(true)
    }

    function getContent() {
        if (error) return <div className='noData'>{error}</div>

        return <>
            <HeaderSearchAndForm
                buttons={[{ nameButton: "Adicionar aluno a linha", onClick: () => {openModal("add", students)}, className: "add" }
                ]}
                search={{
                    dataList: studentsList,        // Substitua 'yourDataList' pela sua lista de dados
                    filterKey: ["name", "ra_number"],             // Substitua 'nome' pela chave que você deseja filtrar
                    setFilteredList: setStudents   // Substitua 'setYourList' pela função que define a lista filtrada
                }}
                isViewButton={true}
                isViewSearch={true}
            />

            {
                students && students.length > 0 ? (
                    <div className='studentsRoutes'>
                        {
                            students.map((studentData: any) => (
                                <TransportStudentsBox
                                    key={'student_' + studentData.id}
                                    data={studentData}
                                    openModal={openModal}
                                />
                            ))
                        }
                    </div>
                ) : <div className='noData'>Não foi possível localizar nenhum estudante</div>
            }
        </>
    }

    useEffect(() => {
        !ready && api.get('transport/students', {
            headers: { Authorization: user.token }
        }).then(resp => {
            setStudents(resp.data);
            setStudentsList(resp.data);
            setReady(true);
        }).catch(err => {
            setError('Erro ao carregar os veículos')
            setReady(true)
        });
    }, [ready])

    // Breadcrumbs
    useEffect(() => {
        (!breadcrumbs || breadcrumbs.curr!=="Alunos") && setBreadcrumbs({
            curr: 'Alunos',
            links: [
                { name: 'Home', url: '/'},
                { name: 'Transporte', url: '/transporte'},
                { name: 'Alunos' }
            ]
        });
    }, [breadcrumbs]);

    return <Template page="Alunos" pageTitle="Transporte - Alunos" className="transportStudents">
       { ready ? getContent() : <Loading /> }
    </Template>;
}

export default TransportStudents;