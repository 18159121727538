import React, { ChangeEvent, useContext, useEffect, useState } from "react";
import { FaTrash } from "react-icons/fa6";
import api from "~/services/api";
import './styles.css';

// Contexts
import { GeneralContext } from "~/contexts";

const dataBase = {
    id: 0,
    type: 0,
    name: '',
    description: ''
}

const types = [
    { type: 'Breakfast', name: 'Café da Manhã' },
    { type: 'Lunch', name: 'Almoço' },
    { type: 'Snack', name: 'Lanche' },
    { type: 'Dinner', name: 'Jantar' },
    { type: 'Supper', name: 'Ceia' }
]

const ModalCreate: React.FC<any> = ({ data, setReady, setModalOpen }: any) => {
    const [meals, setMeals]       = useState<any[]>(data.menu.length>0 ? data.menu : [dataBase]);
    const [loadData, setLoadData] = useState<boolean>(false);
    const [error, setError]       = useState<string | null>(null);

    // Contexts
    const { user } = useContext(GeneralContext);

    function addItem() {
        setMeals([...meals, { ...dataBase, id: meals.length+1 }]);
    }

    function removeItem(id: number) {
        if (meals.length === 1) return;
        let schBase = JSON.parse(JSON.stringify(meals));
        let idx     = meals.findIndex((el: any) => id === el.id);

        // Remove Item
        schBase.splice(idx, 1);

        // Recreate Requests
        setMeals(schBase);
    }

    function onChange (index: number, event: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) {
            const { name, value } = event.target;
            const newSchedules    = [...meals];

            newSchedules[index] = {
                ...newSchedules[index],
                [name]: value,
            };

            setMeals(newSchedules);
        }

    const saveDataForm = async () => {
        if (meals.length===0) {
            setError("Você precisa adicionar pelo menos 1 item.");
            return;
        }

        // Clear states
        setLoadData(true);
        setError(null);

        // Set Body POST
        let dataFormat: any = {
            day  : data.day,
            month: data.month,
            year : data.year,
            meals : meals
        };

        // Send data to server
        await api.post('/nutrition/schedule', dataFormat, {
            headers: { Authorization: user.token }
        }).then(data => {
            setLoadData(false);
            setReady(false);
            setModalOpen(false);
        }).catch(errr => {
            console.log(errr)
            setError("Erro ao salvar os dados. Tente novamente.")
        });
    }

    return !loadData ? <>
        <form method="post" onSubmit={(event: React.FormEvent<HTMLFormElement>) => event.preventDefault()} className="editCreateMenu">
            {error && <p style={{ color: 'red' }}>{error}</p>}

            {
                meals.map((data: any, idx: number) => <>
                    { idx> 0 ? <hr /> : <></> }
                    <div key={`prod_${idx}`} className="formGroup">
                        <div className="line">
                            <div className="inputLabel type">
                                <label htmlFor="type">Tipo</label>
                                <select
                                    id="type"
                                    name="type"
                                    value={data.type}
                                    onChange={e => onChange(idx, e)}
                                    >
                                    <option value="">Selecione o Tipo</option>
                                    {
                                        types.map(el => <option value={ el.type }>{ el.name }</option>)
                                    }
                                </select>
                            </div>

                            <button
                                type='button'
                                disabled={meals.length>1 ? false : true}
                                onClick={() => removeItem(data.id)}
                            >
                                <FaTrash size={14} />
                            </button>
                        </div>

                        <div className="inputLabel name">
                            <label htmlFor="name">Título</label>
                            <input
                                id="name"
                                name="name"
                                type="text"
                                value={data.name}
                                placeholder="ex.: Pão e Leite"
                                onChange={e => onChange(idx, e)}
                                required
                            />
                        </div>

                        <div className="inputLabel description">
                            <label htmlFor="description">Descrição</label>
                            <textarea
                                id="description"
                                name="description"
                                rows={3}
                                value={data.description}
                                placeholder="ex.: Pão com manteiga com leite e achocolatado"
                                onChange={e => onChange(idx, e)}
                                required
                            />
                        </div>
                    </div>
                </>)
            }
        </form>

        <div className="buttons">
            <button className='add' type='button' onClick={addItem}>Adicionar refeição a lista</button>
            <button className='save' type='button' onClick={saveDataForm}>Salvar</button>
            <button className="cancel" type='button' onClick={() => setModalOpen(false)}>Cancelar</button>
        </div>
    </> : (
        <>Salvando dados...</>
    );
};

export default ModalCreate;
