import React, { useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FaBox, FaBoxesPacking, FaBoxesStacked, FaBusSimple, FaCarrot, FaScrewdriverWrench, FaTag } from 'react-icons/fa6';
import { GeneralContext } from '~/contexts/general';
import { hasPermission } from '~/utils/permissions';

const Warehouse: React.FC = () => {
    const { user } = useContext(GeneralContext);
    const location = useLocation();

    function submenu() {
        return (
            <div className='submenu'>
                {
                    hasPermission(user.permissions, user.role, 'almoxarifado', 'compras') && (
                        <Link to="/almoxarifado/requisicao" className={location.pathname === '/almoxarifado/requisicao' ? ' active' : ''}>
                            <FaTag />
                            <span>Requisições</span>
                        </Link>
                    )
                }

                {
                    hasPermission(user.permissions, user.role, 'almoxarifado', 'compras') && (
                        <Link to="/almoxarifado/compras" className={location.pathname === '/almoxarifado/compras' ? ' active' : ''}>
                            <FaBox />
                            <span>Compras</span>
                        </Link>
                    )
                }

                {
                    hasPermission(user.permissions, user.role, 'almoxarifado', 'nutricao') && (
                        <Link to="/almoxarifado/nutricao" className={location.pathname === '/almoxarifado/nutricao' ? ' active' : ''}>
                            <FaCarrot />
                            <span>Nutrição</span>
                        </Link>
                    )
                }

                {
                    hasPermission(user.permissions, user.role, 'almoxarifado', 'manutencao') && (
                        <Link to="/almoxarifado/manutencao" className={location.pathname === '/almoxarifado/manutencao' ? ' active' : ''}>
                            <FaScrewdriverWrench />
                            <span>Manutenção</span>
                        </Link>
                    )
                }

                {
                    hasPermission(user.permissions, user.role, 'almoxarifado', 'transporte') && (
                        <Link to="/almoxarifado/transporte" className={location.pathname === '/almoxarifado/transporte' ? ' active' : ''}>
                            <FaBusSimple />
                            <span>Transporte</span>
                        </Link>
                    )
                }
            </div>
        );
    }

    return (
        <>
            <Link to="/almoxarifado" className={location.pathname.indexOf('/almoxarifado') === 0 ? ' active' : ''}>
                <FaBoxesStacked />
                <span>Almoxarifado</span>
            </Link>

            {location.pathname.indexOf('/almoxarifado') === 0 && submenu()}
        </>
    );
}

export default Warehouse;
