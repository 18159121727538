import React from "react";
import './styles.css';
import dates from './../../../../utils/dates';

const ModalView: React.FC<any> = ({ data, openModal, setModalOpen }: any) => {
    function getNameType (type: string) {
        switch (type) {
            case 'Breakfast':
                return 'Café da Manhã';
            case 'Lunch':
                return 'Almoço';
            case 'Snack':
                return 'Lanche';
            case 'Dinner':
                return 'Jantar';
            case 'Supper':
                return 'Ceia';
        }
    }

    function getContent() {
        // Check Exist Menu
        if (!data.menu || data.menu.length===0) return <>
            <div className="noInfos">Não há cardápio registrado para esse dia.</div>

            <div className="btns">
                <button id="btnCreate" onClick={() => openModal('Create', data)}>Criar Cardápio</button>
                <button id="btnCancel" onClick={() => setModalOpen(false)}>Cancelar</button>
            </div>
        </>

        // Generate Template
        return (
            <>
                <h3>Cardápio de {data.day} de {dates.getNameMonth(data.month)} de {data.year}</h3>

                <hr />

                {
                    data.menu.map((el: any, idx: number) => <div key={`meal_${idx}`} className="line">
                        <h4>{getNameType(el.type)} {<span>{el.name}</span>}</h4>
                        <div className="desc">{el.description ? el.description : 'Não Informado'}</div>
                    </div>)
                }

                <div className="btns">
                    <button id="btnEdit" onClick={() => openModal('Edit', data)}>Editar Cardápio</button>
                    <button id="btnCancel" onClick={() => setModalOpen(false)}>Cancelar</button>
                </div>
            </>
        );
    }

    return getContent();
};

export default ModalView;
