import React from 'react';
import './styles.css';

const CategoryBox: React.FC<any> = (props) => {
    return <div className="categoryBox">
        <div className="name">{props.name}</div>
        <div className="desc">{props.description}</div>
        <div className="btns"></div>
    </div>;
}

export default CategoryBox;
