const treats = {
    treatStudentData: function (data: any) {
        let ret: any = {
            profile: {
                school_id                      : data.school_id,
                class_id                       : data.class_id,
                name                           : data.name,
                social_name                    : data.social_name ? data.social_name : null,
                sex                            : data.sex,
                color_race                     : data.color_race,
                birth                          : data.birth ? data.birth + ' 03:00:00' : null,
                birth_city                     : data.birth_city ? data.birth_city : null,
                birth_uf                       : data.birth_uf ? data.birth_uf : null,
                mother_name                    : data.mother_name ? data.mother_name : null,
                father_name                    : data.father_name ? data.father_name : null,
                mail                           : data.mail ? data.mail : null,
                nationality                    : data.nationality,
                country_origin                 : data.country_origin ? data.country_origin  : null,
                organ_donor                    : data.organ_donor === 'true' ? true : false,
                blood_type                     : data.blood_type ? data.blood_type : null,
                internet                       : data.internet === 'true' ? true : false,
                pc_smartphone_tablet           : data.pc_smartphone_tablet === 'true' ? true: false,
                deceased                       : data.deceased === 'true' ? true : false,
                deceased_date                  : data.deceased_date ? data.deceased_date + ' 03:00:00' : null,
                quilombola                     : data.quilombola === 'true' ? true : false,
                family_bag                     : data.family_bag === 'true' ? true : false,
                family_bag_cod                 : data.family_bag === 'true' && Number(data.family_bag_cod) > 0 ? Number(data.family_bag_cod) : null,
            },
            docs: {
                inep_code                      : data.inep_code ? data.inep_code : null,
                birth_certificate              : data.birth_certificate ? data.birth_certificate : null,
                date_birth_certificate         : data.date_birth_certificate ? data.date_birth_certificate + ' 03:00:00' : null,
                cpf                            : data.cpf ? data.cpf.replace(/\.\-/gmi, '') : null,
                cns_number                     : data.cns_number ? data.cns_number : null,
                date_civil_doc                 : data.date_civil_doc ? data.date_civil_doc + ' 03:00:00' : null,
            },
            address: this._checkAddress(data) ? {
                address                        : data.address,
                number                         : data.number && data.number!=="SN" && data.number!=="S/N" ? data.number : null,
                neighborhood                   : data.neighborhood,
                complement                     : data.complement ? data.complement : null,
                city                           : data.city,
                uf                             : data.uf,
                cep                            : data.cep.replace(/-/gmi, '').trim(),
                public_area                    : data.public_area,
                latitude                       : data.latitude,
                longitude                      : data.longitude,
            } : null,
            deficiency: {
                reduced_mobility               : data.reduced_mobility === 'true' ? true : false,
                reduced_mobility_type          : data.reduced_mobility_type ? data.reduced_mobility_type : null,
                school_support                 : data.school_support === 'true' ? true : false,
                school_support_daily_activities: data.school_support_daily_activities === 'true' ? true : false,
                school_support_activities      : data.school_support_activities === 'true' ? true : false,
                school_support_hygiene         : data.school_support_hygiene === 'true' ? true : false,
                school_support_locomotion      : data.school_support_locomotion === 'true' ? true : false,
                school_support_food            : data.school_support_food === 'true' ? true : false,
                start_term_activities_daily    : data.start_term_activities_daily ? data.start_term_activities_daily + ' 03:00:00' : null,
                end_term_activities_daily      : data.end_term_activities_daily ? data.end_term_activities_daily + ' 03:00:00' : null,
                start_term_school_activities   : data.start_term_school_activities ? data.start_term_school_activities + ' 03:00:00' : null,
                end_term_school_activities     : data.end_term_school_activities ? data.end_term_school_activities + ' 03:00:00' : null,
            },
            resourceAssessment: {
                interpreter_guide              : data.interpreter_guide === 'true' ? true : false,
                interpret_libras               : data.interpret_libras === 'true' ? true : false,
                lip_reading                    : data.lip_reading === 'true' ? true : false,
                none                           : data.none === 'true' ? true : false,
                expanded_test                  : data.expanded_test === 'true' ? true : false,
                expanded_test_font_size        : data.expanded_test_font_size ? data.expanded_test_font_size : null,
                braille_test                   : data.braille_test === 'true' ? true : false,
                transcription_assistance       : data.transcription_assistance === 'true' ? true : false,
                reader_aid                     : data.reader_aid === 'true' ? true : false,
                libras_video_test              : data.libras_video_test === 'true' ? true : false,
                audio_visual_def_code          : data.audio_visual_def_code === 'true' ? true : false,
                portuguese_language_test       : data.portuguese_language_test === 'true' ? true : false,
            }
        }

        return ret;
    },

    _checkAddress: function (address: any) {
        return !address.address ||
            !address.neighborhood ||
            !address.city ||
            !address.uf ||
            !address.cep ||
            !address.public_area ||
            !address.latitude ||
            !address.longitude ? false : true;
    }
}

export default treats;