import React from 'react';
import { FaEye, FaPencil, FaTrash } from 'react-icons/fa6';
import './styles.css';

const TransportStudentsBox: React.FC<any> = ({ data, openModal }: any) => {
    function getButtons() {
        return <>
            <button type="button" className="view" onClick={() => openModal('view', data)} title="Visualizar">
                <FaEye size={16} /> <span>Visualizar</span>
            </button>

            <button type="button" className="edit" onClick={() => openModal("edit", data)} title="Editar">
                <FaPencil size={14} />
            </button>

            <button type="button" className="inactivate" onClick={() => openModal('delete', data)} title="Deletar">
                <FaTrash size={14} />
            </button>
        </>
    }

    return <div className="studentBox">
        <div className="infos">
            <h3>{data.student.name}</h3>
            <div className="description"><b>Linha:</b> { data.route.name }</div>
        </div>

        <div className="buttons">
            { getButtons() }
        </div>
    </div>;
}

export default TransportStudentsBox;
