import React, { useContext, useEffect, useState } from 'react';
import Treats from '~/utils/treats';
import dates from '~/utils/dates';
import env from '~/utils/env';
import api from '~/services/api';
import './styles.css';

// Contexts
import { GeneralContext } from '~/contexts';
import { ModalContext } from '~/contexts/modal';

// Base Data
let dataBase = {
    "SUPERVISOR_NAME"            : "",
    "SUPERVISOR_RG"              : "",
    "SUPERVISOR_CPF"             : "",
    "EDUCATION_AGENCY"           : "",
    "SCHOOL_ID"                  : "",
    "SCHOOL_NAME"                : "",
    "SCHOOL_ADDRESS"             : "",
    "VISIT_DATE"                 : "",
    "VISIT_OBJECT"               : "",
    "VISIT_INIT_TIME"            : "",
    "VISIT_END_TIME"             : "",
    "SCHOOL_RESPONSIBLE_PRESENT" : "",
    "SCHOOL_RESPONSIBLE_POSITION": "",
    "NAME_POSITIONS"             : "",
    "OBSERVATIONS_CONCLUSIONS"   : "",
    "CITY"                       : env.city,
    "DATE"                       : ""
};

const SupervisionDirectionVisitTermReports: React.FC<any> = ({data}: any) => {
    const [errors, setErrors] = useState<any>(null);

    // Contexts
    const { user } = useContext(GeneralContext);
    const { setModalOpen } = useContext(ModalContext);

    async function saveDataForm () {
        // Set errors false
        setErrors(false);

        // Dates
        console.log(data)
        let visitDate = dates.getDateFormatted(data.visit_date, 'dd/mm/yyyy');
        let docDate   = dates.getDate();

        // Set dataForm
        let dataForm = dataBase;
        dataForm.SCHOOL_ID                   = data.school.id;
        dataForm.SCHOOL_NAME                 = Treats.formatNames(data.school.name);
        dataForm.SCHOOL_ADDRESS              = Treats.formatNames(data.school.address + (data.school.address_number ? ', ' + data.school.address_number : '') + ' - ' + data.school.address_neighborhood);
        dataForm.SCHOOL_RESPONSIBLE_PRESENT  = data.school_representative_name;
        dataForm.SCHOOL_RESPONSIBLE_POSITION = data.school_representative_position;
        dataForm.NAME_POSITIONS              = data.school_others_representative;

        dataForm.SUPERVISOR_NAME             = data.supervision_name;
        dataForm.EDUCATION_AGENCY            = data.supervision_agency;
        dataForm.SUPERVISOR_RG               = data.supervision_rg;
        dataForm.SUPERVISOR_CPF              = data.supervision_cpf;

        dataForm.VISIT_DATE                  = visitDate;
        dataForm.VISIT_INIT_TIME             = data.visit_time_init;
        dataForm.VISIT_END_TIME              = data.visit_time_end;
        dataForm.VISIT_OBJECT                = data.visit_objective;
        dataForm.OBSERVATIONS_CONCLUSIONS    = data.visit_conclusion;
        dataForm.DATE                        = docDate.day + ' de ' + docDate.monthName + ' de ' + docDate.year;

        // Get report
        api.post('docs/visit_term', dataForm, {
            headers: { Authorization: user.token },
            responseType: 'blob'
        }).then(async resp => {
            let url = window.URL.createObjectURL(new Blob([resp.data], { type: 'application/pdf' }));
            window.open(url, 'Termo de Visita', "directories=no,titlebar=no,toolbar=no,location=no,status=no,menubar=no,scrollbars=no,resizable=no,width=900,height=600");
            setModalOpen(false);
        }).catch(errr => {
            console.log(errr)
        });
    }

    useEffect(() => {
        saveDataForm();
    }, [])

    return !errors ? <div className="saving">Gerando relatório...</div> : <div className="error">{errors}</div>
}

export default SupervisionDirectionVisitTermReports;