import React, { ChangeEvent, useContext, useEffect, useRef, useState } from "react";
import api from "~/services/api";
import './styles.css';

// Contexts
import { GeneralContext } from "~/contexts";
import { Checkbox } from "~/components/Forms";

// Constant
const activityBase = {
    classId        : '',
    teacherId      : '',
    bimester       : '1',
    schoolSubjectId: '',
    schoolYear     : '',
    title          : '',
    description    : '',
    dateEnd        : '',
    weight         : '',
    attachment1    : '',
    attachment2    : '',
    extraActivity  : 0
};

const ModalCreate: React.FC<any> = ({ class_id, disciplines, openModal }: any) => {
    const [activity, setActivity] = useState<any>(activityBase);
    const [loadData, setLoadData] = useState<boolean>(false);
    const [error, setError]       = useState<string | null>(null);
    const attachment1             = useRef<HTMLInputElement>(null);
    const attachment2             = useRef<HTMLInputElement>(null);

    // Contexts
    const { user, teacher } = useContext(GeneralContext);

    const onChange = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const { name, value } = e.target;
        setActivity({ ...activity, [name]: value });
    };

    const onChangeCheckbox = (e: ChangeEvent<HTMLInputElement>) => {
        const { name, checked } = e.target;

        setActivity((prevVehicle: any) => ({
            ...prevVehicle,
            [name]: checked ? 1 : 0
        }));
    };

    const onChangeFile = (e: ChangeEvent<HTMLInputElement>) => {
        const { name } = e.currentTarget;
        const files = e.currentTarget.files;

        let file = files && files.length > 0 ? files[0] : '';

        setActivity({
            ...activity,
            [name]: file
        });
    };

    async function saveDataForm() {
        setLoadData(true);

        // Set Datas
        const dataForm = new FormData();
        dataForm.append('class_id', activity.classId);
        dataForm.append('teacher_id', activity.teacherId);
        dataForm.append('bimester', activity.bimester);
        dataForm.append('school_subject_id', activity.schoolSubjectId);
        dataForm.append('school_year', activity.schoolYear);
        dataForm.append('title', activity.title);
        dataForm.append('description', activity.description);
        dataForm.append('date_end', activity.dateEnd);
        dataForm.append('weight', activity.weight);
        dataForm.append('extra_activity', activity.extraActivity);
        dataForm.append('attachment1', activity.attachment1);
        dataForm.append('attachment2', activity.attachment2);

        api.post('teacher/tests', dataForm, {
            headers: {
                Authorization: user.token,
                "Content-Type": "multipart/form-data"
            }
        }).then(resp => {
            setLoadData(false);
            openModal('Tests');
        }).then(errr => {
            setError('Erro ao cadastrar atividade');
            setLoadData(false);
        })
    }

    useEffect(() => {
        setActivity({
            ...activity,
            classId: class_id,
            teacherId: user.role==='admin' ? teacher.id : user.employee_id,
            schoolYear: new Date().getFullYear()
        })
    }, [])

    return !loadData ? (
        <form method="post" onSubmit={(event: React.FormEvent<HTMLFormElement>) => event.preventDefault()} className="create">
            {error && <div className="error">{error}</div>}

            <div className="form-group">
                <div className="input-group">
                    <label htmlFor="schoolSubjectId">Matérias</label>
                    <select
                        id="schoolSubjectId"
                        name="schoolSubjectId"
                        onChange={onChange}
                        defaultValue={activity.schoolSubjectId !== '' ? activity.schoolSubjectId : ''}
                    >
                        <option value="">  Selecione uma matéria </option>
                        {
                            disciplines.map((el: any) => <option
                                key={`disc_${el.id}`}
                                value={el.discipline}
                            >
                                {el.discipline}
                            </option>)
                        }
                    </select>
                </div>
            </div>

            <div className="form-group">
                <div className="input-group">
                    <label>Título da Avaliação</label>
                    <input
                        type="text"
                        placeholder="Ex.: Trabalho em dupla"
                        name="title"
                        value={activity.title}
                        onChange={onChange}
                        required
                    />
                </div>
            </div>

            <div className="form-group">
                <div className="input-group">
                    <label>Descrição da Avaliação</label>
                    <input
                        type="text"
                        placeholder="Ex.: Redação para avaliar escrita dos alunos"
                        name="description"
                        value={activity.description}
                        onChange={onChange}
                        required
                    />
                </div>
            </div>

            <div className="form-group">
                <div className="input-group">
                    <label>Data da Avaliação</label>
                    <input
                        type="date"
                        placeholder="05/04/2025"
                        name="dateEnd"
                        value={activity.dateEnd}
                        onChange={onChange}
                        required
                    />
                </div>

                <div className="input-group">
                    <label htmlFor="bimester">Bimestre da Avaliação</label>

                    <select
                        id="bimester"
                        name="bimester"
                        onChange={onChange}
                        defaultValue={activity.bimester}
                    >
                        <option value="1">Primeiro</option>
                        <option value="2">Segundo</option>
                        <option value="3">Terceiro</option>
                        <option value="4">Quarto</option>
                    </select>
                </div>
            </div>

            <div className="form-group">
                <div className="input-group">
                    <label>Peso</label>
                    <input
                        type="text"
                        placeholder="1"
                        name="weight"
                        value={activity.weight}
                        onChange={onChange}
                        required
                    />
                </div>

                <div className="input-group">
                    <Checkbox
                        id='extraActivity'
                        name='extraActivity'
                        labelName='É uma atividade extra?'
                        onChange={onChangeCheckbox}
                        checked={activity.extraActivity}
                    />
                </div>
            </div>

            <hr />

            <div className="form-group">
                <div className="input-group">
                    <label>Anexo 1</label>

                    <div className="attachmentBox">
                        <button className={`attachment${typeof activity.attachment1==='object' ? ' active' : ''}`} type="button" onClick={() => attachment1.current?.click()}>
                            {typeof activity.attachment1==='object' ? activity.attachment1.name : 'Selecione um arquivo...'}
                        </button>

                        {
                            typeof activity.attachment1==='object' ? (
                                <button className="remove" type="button" onClick={() => setActivity({...activity, attachment1: ''})}>X</button>
                            ) : <></>
                        }
                    </div>

                    <input className="fileHide" name='attachment1' type='file' onChange={onChangeFile} ref={attachment1} />
                </div>

                <div className="input-group">
                    <label>Anexo 2</label>
                    <div className="attachmentBox">
                        <button className={`attachment${typeof activity.attachment2==='object' ? ' active' : ''}`} type="button" onClick={() => attachment2.current?.click()}>
                            {typeof activity.attachment2==='object' ? activity.attachment2.name : 'Selecione um arquivo...'}
                        </button>

                        {
                            typeof activity.attachment2==='object' ? (
                                <button className="remove" type="button" onClick={() => setActivity({...activity, attachment2: ''})}>X</button>
                            ) : <></>
                        }
                    </div>

                    <input className="fileHide" name='attachment2' type='file' onChange={onChangeFile} ref={attachment2} />
                </div>
            </div>

            <div className="form-group">
                <div className="input-group btns">
                    <button id="btnAdd" type="button" onClick={saveDataForm}>Salvar</button>
                    <button id="btnCancel" type="button" onClick={() => openModal('Tests')}>Voltar</button>
                </div>
            </div>
        </form>
    ) : (
        <div className="saving">Salvando dados...</div>
    );
};

export default ModalCreate;
