import React, { ChangeEvent, useContext, useEffect, useState } from "react";
import api from "~/services/api";
import './styles.css';

// Contexts
import { GeneralContext } from "~/contexts";

// Constant
const vehicleBase = {
    name         : "",
    description  : "",
    license_plate: "",
    brand        : "",
    model        : "",
    color        : "",
    seats        : 0
};

const ModalCreate: React.FC<any> = ({ data, setReady, setModalOpen }: any) => {
    const [vehicle, setVehicle]   = useState<any>(vehicleBase);
    const [loadData, setLoadData] = useState<boolean>(false);
    const [error, setError]       = useState<string | null>(null);

    // Contexts
    const { user } = useContext(GeneralContext);

    const onChange = (e: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setVehicle((prevVehicle: any) => ({
            ...prevVehicle,
            [name]: value
        }));
    };

    async function saveDataForm() {
        if (
            vehicle.name.trim() === ""||
            vehicle.description.trim() === ""||
            vehicle.license_plate.trim() === ""||
            vehicle.brand.trim() === ""||
            vehicle.model.trim() === "" ||
            vehicle.color.trim() === "" ||
            vehicle.seats === "0"
        ) {
            setError("Preencha todos os campos");
            return;
        }

        // Clear states
        setLoadData(true);
        setError(null);

        // Set Body POST
        let dataFormat: any = vehicle;

        // Send data to server
        await api.post('/transport/vehicles', dataFormat, {
            headers: { Authorization: user.token }
        }).then(data => {
            setLoadData(false);
            setReady(false);
            setModalOpen(false);
        }).catch(errr => {
            console.log(errr)
            setError("Erro ao salvar os dados. Tente novamente")
        });
    }

    useEffect(() => {
        if (data) setVehicle(data)
    }, [])

    return !loadData ? (
        <form method="post" onSubmit={(event: React.FormEvent<HTMLFormElement>) => event.preventDefault()} className="create">
            {error && <div className="error">{error}</div>}

            <div className="form-group">
                <div className="input-group">
                    <label>Nome</label>
                    <input
                        type="text"
                        placeholder="Ex.: Veículo A"
                        name="name"
                        value={vehicle.name}
                        onChange={onChange}
                        required
                    />
                </div>
            </div>

            <div className="form-group">
                <div className="input-group">
                    <label>Descrição</label>
                    <input
                        type="text"
                        placeholder="Ex.: Descrição do veículo"
                        name="description"
                        value={vehicle.description}
                        onChange={onChange}
                        required
                    />
                </div>
            </div>

            <div className="form-group">
                <div className="input-group">
                    <label>Marca</label>
                    <input
                        type="text"
                        placeholder="Ex.: Toyota"
                        name="brand"
                        value={vehicle.brand}
                        onChange={onChange}
                        required
                    />
                </div>

                <div className="input-group">
                    <label>Modelo</label>
                    <input
                        type="text"
                        placeholder="Ex.: Corolla"
                        name="model"
                        value={vehicle.model}
                        onChange={onChange}
                        required
                    />
                </div>
            </div>

            <div className="form-group">
                <div className="input-group">
                    <label>Placa</label>
                    <input
                        type="text"
                        placeholder="Ex.: ABC-1234"
                        name="license_plate"
                        value={vehicle.license_plate}
                        onChange={onChange}
                        required
                    />
                </div>


                <div className="input-group">
                    <label>Cor</label>
                    <input
                        type="text"
                        placeholder="Ex.: Preto"
                        name="color"
                        value={vehicle.color}
                        onChange={onChange}
                        required
                    />
                </div>

                <div className="input-group">
                    <label>Assentos</label>
                    <input
                        type="number"
                        placeholder="Ex.: 5"
                        name="seats"
                        value={vehicle.seats}
                        onChange={onChange}
                        required
                    />
                </div>
            </div>

            <div className="form-group">
                <div className="input-group btns">
                    <button id="btnAdd" type="button" onClick={saveDataForm}>Salvar</button>
                    <button id="btnCancel" type="button" onClick={() => setModalOpen(false)}>Cancelar</button>
                </div>
            </div>
        </form>
    ) : (
        <>Salvando dados...</>
    );
};

export default ModalCreate;
