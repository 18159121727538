import React from 'react';
import { FaEye } from 'react-icons/fa6';
import './styles.css';


const TransportAttendanceBox: React.FC<any> = ({ data, openModal }: any) => {
    return <div className="transportAttendanceBox">
        <div className="infos">
            <h3>{data.name}</h3>
            <div className="description">{data.students.length} aluno{data.students.length>1 ? 's' : ''} cadastrado{data.students.length>1 ? 's' : ''}</div>
            <hr />
            <div className="driver"><b>Motorista:</b> { data.driver.name }</div>
            <div className="monitor"><b>Monitor:</b> { data.monitor.name }</div>
        </div>

        <div className="buttons">
            <button type="button" className="view" onClick={() => openModal("view", data)} title="Visualizar">
                <FaEye size={16} /> <span>Visualizar</span>
            </button>
        </div>
    </div>;
}

export default TransportAttendanceBox;
