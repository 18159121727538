import React, { useContext, useEffect, useState } from 'react';
import { FaRegFileAlt } from 'react-icons/fa';
import './styles.css';

// Contexts
import { ModalContext } from '~/contexts/modal';

// Components
import CreateActivities from '../Modal/activitiesCreate';
import ListActivities from '../Modal/activitiesList';
import ViewActivities from '../Modal/activitiesView';
import CreateTest from '../Modal/testsCreate';
import ListTest from '../Modal/testsList';
import ViewTest from '../Modal/testsView';

interface Props {
    id         : number;
    class_id   : number;
    class_serie: number;
    class_acron: string;
    school     : { name: string };
    teaching   : { name: string };
    shift      : { name: string };
    teachers   : Array<any>;
}

const SchoolBox: React.FC<any> = (props: Props) => {
    const [disciplines, setDisciplines] = useState<any>([]);

    // Contexts
    const { setModalOpen, setModalClass, setModalTitle, setModalBody } = useContext(ModalContext);

    function openModal(type: string, data?: any) {
        switch (type) {
            case 'Tests':
                setModalClass('classSchoolTestsList')
                setModalTitle(`Provas da ${props.class_serie}ª Série${ props.class_acron ? ' ' + props.class_acron.toUpperCase() : '' }`)
                setModalBody(<ListTest id={props.id} openModal={openModal} setModalOpen={setModalOpen} />)
                break;

            case 'TestsView':
                setModalClass('classSchoolTestsView')
                setModalTitle(`Visualizando Prova da ${props.class_serie}ª Série${ props.class_acron ? ' ' + props.class_acron.toUpperCase() : '' }`)
                setModalBody(<ViewTest data={data} openModal={openModal} />)
                break;

            case 'TestsCreate':
                setModalClass('classSchoolTestsCreate')
                setModalTitle(`Criando Provas da ${props.class_serie}ª Série${ props.class_acron ? ' ' + props.class_acron.toUpperCase() : '' }`)
                setModalBody(<CreateTest class_id={props.id} disciplines={disciplines} openModal={openModal} />)
                break;

            case 'Activities':
                setModalClass('classSchoolActivitiesList')
                setModalTitle(`Atividades da ${props.class_serie}ª Série${ props.class_acron ? ' ' + props.class_acron.toUpperCase() : '' }`)
                setModalBody(<ListActivities id={props.id} openModal={openModal} setModalOpen={setModalOpen} />)
                break;

            case 'ActivitiesView':
                setModalClass('classSchoolActivitiesView')
                setModalTitle(`Visualizando Atividade da ${props.class_serie}ª Série${ props.class_acron ? ' ' + props.class_acron.toUpperCase() : '' }`)
                setModalBody(<ViewActivities data={data} openModal={openModal} setModalOpen={setModalOpen} />)
                break;

            case 'ActivitiesCreate':
                setModalClass('classSchoolActivitiesCreate')
                setModalTitle(`Criando Atividade da ${props.class_serie}ª Série${ props.class_acron ? ' ' + props.class_acron.toUpperCase() : '' }`)
                setModalBody(<CreateActivities class_id={props.id} disciplines={disciplines} openModal={openModal} />)
                break;
        }

        setModalOpen(true);
    }

    // Set Disciplines
    useEffect(() => {
        setDisciplines(props.teachers)
    }, [])

    return <div className="classBox">
        <div className="infos">
            <h3>{props.class_serie}ª Série{ props.class_acron ? ' ' + props.class_acron.toUpperCase() : '' }</h3>

            <div className="school">
                <b>Escola:</b> { props.school.name }
            </div>

            <div className="teaching">
                <b>Tipo:</b> { props.teaching.name }
            </div>

            <div className="shift">
                <b>Período:</b> { props.shift.name }
            </div>
        </div>

        <div className="buttons">
            <button type="button" className="view" onClick={() => openModal('Tests')} title="Visualizar Classe">
                <FaRegFileAlt  size={16} /><span> Provas</span>
            </button>

            <button type="button" className="view" onClick={() => openModal('Activities')} title="Professores da Classe">
                <FaRegFileAlt  size={16} /><span> Atividades</span>
            </button>
        </div>
    </div>;
}



export default SchoolBox;