import React, { useContext, useState } from "react";
import { RiLoader2Line } from "react-icons/ri";
import dates from "~/utils/dates";
import api from "~/services/api";
import './styles.css';

// Contexts
import { GeneralContext } from "~/contexts";

const ModalView: React.FC<any> = ({ data, openModal }: any) => {
    const [downReady, setDownReady] = useState<any>({ attachment_1: false, attachment_2: false });

    // Contexts
    const { user } = useContext(GeneralContext);

    // Concat Attachment
    let attachments: any = [];
    if (data.attachment_1) attachments.push(data.attachment_1)
    if (data.attachment_2) attachments.push(data.attachment_2)

    function downloadAttachment (attachment: string, file: string) {
        setDownReady({ ...downReady, [attachment]: true });

        // Get Extension
        let extBase = file.split('.');
        let extFile = extBase[extBase.length - 1];

        api.get(`files/${file}`, {
            headers: { Authorization: user.token },
            responseType: 'blob'
        }).then(resp => {
            let url      = window.URL.createObjectURL(new Blob([resp.data]));
            let link     = document.createElement('a');
            let fileName = `Anexo 1.${extFile}`;

            setTimeout(() => {
                link.href = url;
                link.setAttribute("id", "download");
                link.setAttribute('download', fileName); //or any other extension
                document.body.appendChild(link);
                link.click();
                link.parentNode?.removeChild(link);
                setDownReady({ ...downReady, [attachment]: false });
            }, 1000);
        })
    }

    return <>
        <div className="testsInfos">
            <h3>{data.title}</h3>
            <div className="desc">{data.description}</div>

            <hr />

            <div className="teacher"><b>Professor(a):</b> {data.teacher.name}</div>
            <div className="extra"><b>Disciplina:</b> {data.schoolSubject.name}</div>
            <div className="date"><b>Data da Prova:</b> {dates.getDateFormatted(data.date_end + ' 03:00:00', 'dd/mm/yyyy')}</div>
            <div className="bimester"><b>Bimestre da Atividade:</b> {data.bimester}º Bimestre</div>
            <div className="weight"><b>Peso da Atividade:</b> {data.weight}</div>
            <div className="extra"><b>Atividade Extra:</b> {data.extra_activity === '1' ? 'Sim' : 'Não'}</div>

            {
                data.attachment_1 || data.attachment_2 ? <>
                    <hr />

                    <div className="attachments">
                        {
                            attachments.length>0 ? attachments.map((el: any, idx: number) => (
                                <button
                                    key={`att_${idx}`}
                                    type="button"
                                    className={downReady[`attachment_${idx + 1}`] ? ' down' : ''}
                                    onClick={() => downloadAttachment(`attachment_${idx + 1}`, el)}
                                    disabled={downReady.attachment_1 || downReady.attachment_2 ? true : false}
                                >
                                    {
                                        !downReady[`attachment_${idx + 1}`] ?
                                        `Download do Anexo ${idx + 1}` :
                                        <RiLoader2Line size={16} />
                                    }
                                </button>
                            )) : <></>
                        }
                    </div>
                </> : <></>
            }

        </div>

        <div className="btns">
            <button id="btnCancel" onClick={() => openModal('Tests')}>Voltar</button>
        </div>
    </>;
};

export default ModalView;
