import React, { useContext, useEffect, useState } from 'react';
import api from '~/services/api';
import './styles.css';

// Contexts
import { GeneralContext } from '~/contexts';

// Components
import Template from '~/components/Default/Template';
import HeaderSearchAndForm from '~/components/Default/HeaderSearchAndForm';
import Loading from '~/components/Default/Loading';
import EmployeesBox from './Box';

// Interfaces
interface Employee {
    id        : number;
    name      : string;
    position  : string;
    email     : string;
    phone     : string;
    department: string;
    schools   : any;
}

const SecretaryEducationEmployees: React.FC = () => {
    const [ready, setReady]                 = useState(false);
    const [employees, setEmployees]         = useState<Employee[]>([]);
    const [employeesList, setEmployeesList] = useState<Employee[]>([]);
    const [error, setError]                 = useState<string | null>(null);

    // Contexts
    const { user, breadcrumbs, setBreadcrumbs } = useContext(GeneralContext);

    function getContent() {
        if (error) return <div className='noData'>{error}</div>

        return <>
            <HeaderSearchAndForm
                buttons={[{ url: "novo", nameButton: "Adicionar funcionário", className: "add" }]}
                search={{
                    dataList: employeesList, // Substitua 'yourDataList' pela sua lista de dados
                    filterKey: ["name"], // Substitua 'nome' pela chave que você deseja filtrar
                    setFilteredList: setEmployees // Substitua 'setYourList' pela função que define a lista filtrada
                }}
                isViewButton={true}
                isViewSearch={true}
            />

            {
                employees && employees.length > 0 ? (
                    <div className='employees'>
                        {
                            employees.map((employeeData: any) => (
                                <EmployeesBox
                                    key={'employees_' + employeeData.id}
                                    {...employeeData}
                                />
                            ))
                        }
                    </div>
                ) : <div className='noData'>Não foi possível localizar nenhum funcionário</div>
            }
        </>
    }

    // Fetch employees data from API
    useEffect(() => {
        !ready && api.get('employees', {
            headers: { Authorization: user.token }
        }).then(resp => {
            setEmployees(resp.data)
            setEmployeesList(resp.data)
            setReady(true)
        }).catch(err => {
            setError('Erro ao carregar os dados dos funcionários')
                setReady(true)
        });
    }, []);

    // Breadcrumbs
    useEffect(() => {
        (!breadcrumbs || breadcrumbs.curr !== "Funcionários da Educação") && setBreadcrumbs({
            curr: 'Funcionários da Educação',
            links: [
                { name: 'Home', url: '/' },
                { name: 'Secretaria da Educação', url: '/secretaria-educacao' },
                { name: 'Funcionários' }
            ]
        });
    }, [breadcrumbs]);

    return (
        <Template page="Funcionários da Educação" pageTitle="Secretaria da Educação - Funcionários da Educação" className="secEducEmployees">
            { ready ? getContent() : <Loading /> }
        </Template>
    );
}

export default SecretaryEducationEmployees;
