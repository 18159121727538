import React, { useContext, useEffect, useState } from 'react';
import api from '~/services/api';
import './styles.css'

// Contexts
import { GeneralContext } from '~/contexts';
import { ModalContext } from '~/contexts/modal';

// Components
import Template from '~/components/Default/Template';
import Loading from '~/components/Default/Loading';
import HeaderSearchAndForm from '~/components/Default/HeaderSearchAndForm';
import Response from './Modal/response';
import RequestBox from './Box';
import View from './Modal/view';

export interface Request {
    id           : number;
    resource_id  : number[];
    quantity     : number;
    delivery     : boolean;
    delivery_date: Date;
}

const WarehouseRequests: React.FC = () => {
    const [ready, setReady]             = useState(false);
    const [error, setError]             = useState<string | null>(null);
    const [request, setRequest]         = useState<Request[]>([]);
    const [requestList, setRequestList] = useState<Request[]>([]);

    // Filters States
    const [filters, setFilters]           = useState<any>({user: 0, type: 0});
    const [usersRequest, setUsersRequest] = useState<any>([]);
    const [typesRequest, setTypesRequest] = useState<any>([]);

    //ContextS
    const { user, breadcrumbs, setBreadcrumbs } = useContext(GeneralContext);
    const { setModalOpen, setModalClass, setModalTitle, setModalBody } = useContext(ModalContext);

    function openModal(type: string, data?: any) {
        if (type==="response") {
            setModalClass('whResponse')
            setModalTitle('Responder Solicitação de Material')
            setModalBody(<>Resposta a solicitação</>)
            setModalBody(<Response {...data} setModalOpen={setModalOpen} setReady={setReady} />)
        } else if (type === "view") {
            setModalClass('whView')
            setModalTitle('Lista Produtos Solicitados')
            setModalBody(<View {...data} setModalOpen={setModalOpen} setReady={setReady} />)
        }

        setModalOpen(true)
    }

    function getFilters(requests: any) {
        let users: any = []

        requests.map((el: any) => {
            let dataUser = { id: el.user.id, name: el.user.name }

            if (users.findIndex((el2: any) => el2.id === dataUser.id) < 0) users.push(dataUser)
        })

        setUsersRequest(users)
    }

    function getSelects() {
        return <>
            {
                usersRequest.length>1 ? <div className="users">
                    <select id="users" name="users" onChange={onChangeFilters} defaultValue={filters.user}>
                        <option value="">-- Usuários --</option>
                        {
                            usersRequest.map((el: any) => <option
                                key={`user_${el.id}`}
                                value={el.id}
                            >
                                {el.name}
                            </option>)
                        }
                    </select>
                </div> : <></>
            }

            {
                typesRequest.length>1 ? <div className="types">
                    <select id="types" name="types" onChange={onChangeFilters} defaultValue={filters.type}>
                        <option value="">-- Categorias --</option>
                        {
                            typesRequest.map((el: any) => <option
                                key={`user_${el.id}`}
                                value={el.id}
                            >
                                {el.name}
                            </option>)
                        }
                    </select>
                </div> : <></>
            }
        </>
    }

    function onChangeFilters(e: React.ChangeEvent<HTMLSelectElement>) {
        let { name, value } = e.currentTarget;
        let data: any = filters;

        if (name === 'users') {
            data = { ...filters, user: value ? Number(value) : 0 }
            setFilters(data)
        } else if (name === 'types') {
            data = { ...filters, type: value ? Number(value) : 0 }
            setFilters(data)
        }

        // Filter Requests
        if (data.user !== 0 || data.type !== 0) {
            let listFiltered: any = requestList;
            if (data.user !== 0) listFiltered = listFiltered.filter((item: any) => item.user.id === data.user);
            if (data.type !== 0) listFiltered = listFiltered.filter((item: any) => item.type.id === data.type);

            setRequest(listFiltered)
        } else setRequest(requestList)
    }

    function getContent() {
        if (error) return <div className='noData'>{error}</div>

        return <>
            <HeaderSearchAndForm
                buttons={[{ nameButton: "" }]}
                search={{
                    dataList: requestList,
                    filterKey: ["type.name", "user.name"],
                    setFilteredList: setRequest
                }}
                module={getSelects()}
                isViewButton={false}
                isViewSearch= {true}
            />

            {
                request && request.length > 0 ? (
                    <div className='request'>
                        {
                            request.map((requestData: any) => (
                                <RequestBox
                                    key={`request_box_${requestData.id}`}
                                    {...requestData}
                                    openModal={openModal}
                                />
                            ))
                        }
                    </div>
                ) : <div className='noData'>Não foi possível localizar nenhuma requisição</div>
            }
        </>
    }

    async function loadInfos() {
        try {
            const [requestsResponse, requestTypes] = await Promise.all([
                api.get('warehouse_requests', { headers: { Authorization: user.token } }),
                api.get('warehouse_resource_type', { headers: { Authorization: user.token } }),
            ]);

            // Set Datas
            setRequest(requestsResponse.data);
            setRequestList(requestsResponse.data);
            getFilters(requestsResponse.data);
            setTypesRequest(requestTypes.data);

            // Quando ambos os estados forem setados, setReady(true) é chamado.
            setReady(true);
        } catch (error) {
            alert('Erro ao carregar informações do formulário')
            console.error('Erro ao carregar informações:', error);
            // Trate o erro apropriadamente, talvez com um setReady(false) ou exibição de mensagem de erro.
        }
    }

    useEffect(() => {
        if (!ready) loadInfos()
    }, [ready])

    // Breadcrumbs
    useEffect(() => {
        (!breadcrumbs || breadcrumbs.curr !== "Requisições") && setBreadcrumbs({
            curr: 'Requisições',
            links: [
                { name: 'Home', url: '/' },
                { name: 'Almoxarifado', url: '/almoxarifado' },
                { name: 'Requisições' }
            ]
        });
    }, [breadcrumbs]);

    return (
        <Template page="Requisições" pageTitle="Almoxarifado - Requisições" className="warehouseRequest">
           { ready ? getContent() : <Loading /> }
        </Template>
    )
}

export default WarehouseRequests;