import { ChangeEvent, useContext, useEffect, useState } from 'react';
import Treats from '~/utils/treats';
import dates from '~/utils/dates';
import validateForm from '~/utils/validateForm';
import env from '~/utils/env';
import api from '~/services/api';
import './styles.css';

// Contexts
import { GeneralContext } from '~/contexts';
import { ModalContext } from '~/contexts/modal';

// Components
import InputLabel, { InputLabelMask } from '~/components/Forms/InputLabel';

// Base Data
let baseData = {
    "RESPONSIBLE_STUDENT_NAME"   : "",
    "RESPONSIBLE_STUDENT_RG"     : "",
    "RESPONSIBLE_STUDENT_CPF"    : "",
    "SCHOOL_ID"                  : "",
    "SCHOOL_NAME"                : "",
    "SCHOOL_ADDRESS"             : "",
    "MEETING_DATE"               : "",
    "START_TIME"                 : "",
    "END_TIME"                   : "",
    "STUDENT_ID"                 : "",
    "STUDENT_NAME"               : "",
    "STUDENT_CLASS"              : "",
    "CITY"                       : env.city,
    "DATE"                       : "",
    "SCHOOL_RESPONSIBLE_NAME"    : "",
    "SCHOOL_RESPONSIBLE_POSITION": ""
};

const DeclarationAttendanceDoc: React.FC = () => {
    const [ready, setReady]       = useState<boolean>(false);
    const [loadData, setLoadData] = useState<boolean>(false);
    const [data, setData]         = useState<any>(baseData);
    const [schools, setSchools]   = useState<any>([]);
    const [students, setStudents] = useState<any>([]);
    const [errors, setErrors]     = useState<any>(null);

    // Contexts
    const { user } = useContext(GeneralContext);
    const { setModalOpen } = useContext(ModalContext);

    function onChange(event: ChangeEvent<HTMLInputElement | HTMLSelectElement>) {
        let { name, value } = event.target;

        // Set Data
        if (name === 'SCHOOL_ID') { // Specific to school
            if (value !== '') {
                let schoolData = schools.filter((el: any) => el.id === Number(value))
                setData({
                    ...data,
                    'SCHOOL_ID': value,
                    'SCHOOL_NAME': Treats.formatNames(schoolData[0].name),
                    'SCHOOL_ADDRESS': Treats.formatNames(schoolData[0].address + (schoolData[0].address_number ? ', ' + schoolData[0].address_number : '') + ' - ' + schoolData[0].address_neighborhood)
                });

                // Get students of school
                getStudents(schoolData[0].id);
            } else {
                setData({
                    ...data,
                    'SCHOOL_ID': value,
                    'SCHOOL_NAME': "",
                    'SCHOOL_ADDRESS': ""
                });

                // Remove students list
                setStudents([]);
            }
        } else if (name === 'STUDENT_ID') { // Specific to student
            let studentData = students.filter((el: any) => el.id === Number(value))
            console.log(studentData)
            setData({
                ...data,
                'STUDENT_ID': value,
                'STUDENT_NAME': Treats.formatNames(studentData[0].name),
                'STUDENT_CLASS': Treats.formatNames(studentData[0].school_class.class_serie + ' ano ' + studentData[0].school_class.class_acron)
            });
        } else {
            setData({ ...data, [name]: value });
        }
    }

    function getStudents (school_id: number) {
        api.get(`student/school/${school_id}`, {
            headers: { Authorization: user.token }
        }).then(resp => {
            setStudents(resp.data)
        }).catch(errr => {
            console.log(errr)
        });
    }

    function generateDate (date?: string, type?: string) {
        let dateBase = date ? dates.getDate(date) : dates.getDate();

        return type && type==='short' ?
            dateBase.day + '/' + dateBase.month + '/' + dateBase.year :
            dateBase.day + ' de ' + dateBase.monthName + ' de ' + dateBase.year;
    }

    function getContent () {
        if (loadData) return <div className="saving">Gerando documento...</div>

        return <>
            <form method="post" onSubmit={(event: React.FormEvent<HTMLFormElement>) => event.preventDefault()}>
                <h3>Dados da Reunião</h3>

                <div className="form-group no-margin">
                    <div className="inputLabel">
                        <label>Escola</label>
                        <select name="SCHOOL_ID" onChange={onChange} defaultValue={data?.SCHOOL_ID} disabled={schools && schools.length>0 ? false : true}>
                            <option value="">-- Selecione a Escola --</option>

                            {
                                schools.map((el: any) => <option key={`school_${el.id}`} value={el.id}>
                                    { el.name }
                                </option>)
                            }
                        </select>
                    </div>
                </div>

                <div className="form-group no-margin">
                    <InputLabel
                        name="MEETING_DATE"
                        type='date'
                        className='date_meeting'
                        labelName='Data da Reunião'
                        value={data?.MEETING_DATE}
                        onChange={onChange}
                        error={errors && errors['MEETING_DATE']}
                    />

                    <InputLabel
                        name="START_TIME"
                        type='time'
                        className='time_meeting'
                        labelName='Hora de Início'
                        value={data?.START_TIME}
                        onChange={onChange}
                        error={errors && errors['START_TIME']}
                    />

                    <InputLabel
                        name="END_TIME"
                        type='time'
                        className='time_meeting'
                        labelName='Hora de Termino'
                        value={data?.END_TIME}
                        onChange={onChange}
                        error={errors && errors['END_TIME']}
                    />
                </div>

                <hr />

                <h3>Dados do Aluno</h3>

                <div className="form-group no-margin">
                    <InputLabel
                        name="RESPONSIBLE_STUDENT_NAME"
                        type='text'
                        labelName='Nome do Responsável'
                        value={data?.RESPONSIBLE_STUDENT_NAME}
                        onChange={onChange}
                        error={errors && errors['RESPONSIBLE_STUDENT_NAME']}
                    />
                </div>

                <div className="form-group no-margin">
                    <InputLabelMask
                        name='RESPONSIBLE_STUDENT_RG'
                        value={data?.RESPONSIBLE_STUDENT_RG}
                        type='text'
                        mask="99.999.999-9"
                        placeholder='12.345.678-9'
                        labelName='RG'
                        onChange={onChange}
                        error={errors && errors['RESPONSIBLE_STUDENT_RG']}
                    />

                    <InputLabelMask
                        name='RESPONSIBLE_STUDENT_CPF'
                        value={data?.RESPONSIBLE_STUDENT_CPF}
                        type='text'
                        mask="999.999.999-99"
                        placeholder='123.456.789-10'
                        labelName='CPF'
                        onChange={onChange}
                        error={errors && errors['RESPONSIBLE_STUDENT_CPF']}
                    />
                </div>

                <div className="form-group no-margin">
                    <div className="inputLabel">
                        <label>Aluno</label>
                        <select name="STUDENT_ID" onChange={onChange} defaultValue={data?.STUDENT_ID} disabled={students && students.length>0 ? false : true}>
                            <option value="">-- Selecione a Escola --</option>

                            {
                                students.map((el: any) => <option key={`student_${el.id}`} value={el.id}>
                                    { Treats.formatNames(el.name) }
                                </option>)
                            }
                        </select>
                    </div>
                </div>

                <hr />

                <h3>Assinatura do Documento</h3>

                <div className="form-group no-margin">
                    <InputLabel
                        name="SCHOOL_RESPONSIBLE_NAME"
                        type='text'
                        labelName='Nome'
                        value={data?.SCHOOL_RESPONSIBLE_NAME}
                        onChange={onChange}
                        error={errors && errors['SCHOOL_RESPONSIBLE_NAME']}
                    />

                    <InputLabel
                        name="SCHOOL_RESPONSIBLE_POSITION"
                        type='text'
                        labelName='Cargo'
                        value={data?.SCHOOL_RESPONSIBLE_POSITION}
                        onChange={onChange}
                        error={errors && errors['SCHOOL_RESPONSIBLE_POSITION']}
                    />
                </div>
            </form>

            <div className="buttons">
                {errors && <div className="error">{errors}</div>}

                <button id="btnAdd" type="button" onClick={saveDataForm}>Salvar</button>
                <button id="btnCancel" type="button" onClick={() => setModalOpen(false)}>Cancelar</button>
            </div>
        </>
    }

    function saveDataForm() {
        setErrors(false);

        // Check Errors
        if (validateForm.checkFields(data, ['DATE'])) {
            setErrors('Todos os campos devem estar preenchidos')
            return false
        } else if (validateForm.checkFormat(data, ['MEETING_DATE', 'START_TIME', 'END_TIME', 'RESPONSIBLE_STUDENT_RG', 'RESPONSIBLE_STUDENT_CPF'])) {
            setErrors('Dados inválidos encontrados')
            return false
        }

        let DocData          = JSON.parse(JSON.stringify(data));
        DocData.DATE         = generateDate();
        DocData.MEETING_DATE = generateDate(DocData.MEETING_DATE + ' 03:00:00', 'short');

        setLoadData(true);

        api.post('docs/declaration_of_attendance', DocData, {
            headers: { Authorization: user.token },
            responseType: 'blob'
        }).then(async resp => {
            let url = window.URL.createObjectURL(new Blob([resp.data], { type: 'application/pdf' }));
            window.open(url, 'Declaração de Comparecimento', "directories=no,titlebar=no,toolbar=no,location=no,status=no,menubar=no,scrollbars=no,resizable=no,width=900,height=600");
            setLoadData(false);
            // setModalOpen(false);
        }).catch(errr => {
            console.log(errr)
        });
    }

    useEffect(() => {
        !ready && api.get('school/students', {
            headers: { Authorization: user.token }
        }).then(resp => {
            setSchools(resp.data)
            setReady(true)
        }).catch(errr => {
            console.log(errr)
        });
    }, [ready])

    return ready ? getContent() : <></>
}

export default DeclarationAttendanceDoc;