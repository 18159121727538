import React from 'react';
import dates from '~/utils/dates';
import './styles.css';

const VisitTermInfos: React.FC<any> = ({ data }) => {
    let visitDate = dates.getDate(data.visit_ate);

    return <>
        <h3>{ data.school.name }</h3>

        <h4>Supervisor</h4>
        <p><b>Nome:</b> { data.supervision_name }</p>
        <p><b>Agência:</b> { data.supervision_agency }</p>
        <p><b>RG:</b> { data.supervision_rg }</p>
        <p><b>CPF:</b> { data.supervision_cpf }</p>

        <hr />

        <h4>Representantes da Escola</h4>
        <p><b>Nome:</b> { data.school_representative_name }</p>
        <p><b>Cargo:</b> { data.school_representative_position }</p>
        <p><b>Outros:</b> { data.school_others_representative }</p>

        <hr />

        <h4>Visita</h4>
        <p><b>Data:</b> { visitDate.day } de { visitDate.monthName } de { visitDate.year }</p>
        <p><b>Horário:</b> das { data.visit_time_init } às { data.visit_time_end }</p>
        <p><b>Objetivo:</b> { data.visit_objective }</p>

        <hr />

        <h4>Conclusão</h4>
        { data.visit_conclusion }
    </>;
}

export default VisitTermInfos;