import React from 'react';
import dates from '~/utils/dates';
import './styles.css';

const EmployeeInfos: React.FC<any> = ({ data }) => {
    const yearsOld = dates.getYearsOld(data.birth_date);
    const birth    = dates.getDateFormatted(data.birth_date, 'dd/mm/yyyy');

    return <>
        <h3>{data.name}</h3>
        <p><b>Nascimento:</b> { birth }</p>
        <p><b>Idade:</b> {yearsOld.years > 0 ? yearsOld.years + ' ano' + (yearsOld.years>1 ? 's' : '') : yearsOld.months + (yearsOld.months>1 ? ' meses' : ' mês')}</p>
        <p><b>Gênero:</b> {data.gender}</p>
        <p><b>Estado Civil:</b> {data.marital_status}</p>
        <p><b>Email:</b> {data.mail}</p>
        <p><b>Cargo:</b> {data.position?.name}</p>

        <hr />

        <h4>Locais de Trabalho</h4>
        {
            data.workInformation && <>
                <p><b>Local:</b> {data.workInformation.school?.name || data.workInformation.other_units?.name || 'Sem local cadastrado'}</p>
                <p><b>Departamento:</b> {data.workInformation.department}</p>
                <p><b>Contratação:</b> {data.workInformation.contract_type}</p>
                <p><b>Admissão:</b> {dates.getDateFormatted(data.workInformation.admission_date, 'dd/mm/yyyy')}</p>
                <p><b>Rescisão:</b> {data.workInformation.contract_end_date ? dates.getDateFormatted(data.workInformation.contract_end_date, 'dd/mm/yyyy') : '-'}</p>
                <p><b>Horário de Trabalho:</b> {dates.getTimeFormatted(data.workInformation.work_start_time, 'HH:MM')} às {dates.getTimeFormatted(data.workInformation.work_end_time, 'HH:MM')}</p>
                <p><b>Temporário:</b> {data.workInformation.is_temporary ? 'Sim' : 'Não'}</p>
            </>
        }

        <hr />

        <h4>Permissão do usuário</h4>
        <p><b>Permissão:</b> {data.rulesCategory?.name || "Nenhuma permissão definida"}</p>
    </>;
}

export default EmployeeInfos;