import React, { ChangeEvent, useContext, useEffect, useState } from "react";
import api from "~/services/api";
import './styles.css';

// Contexts
import { GeneralContext } from "~/contexts";

// Constant
const routeBase = {
    name       : "",
    description: "",
    vehicle_id : "0",
    driver_id  : "0",
    monitor_id : "0"
}

const ModalCreate: React.FC<any> = ({ data, setReady, setModalOpen }: any) => {
    const [vehicle, setVehicle]   = useState<any[]>([]);
    const [driver, setDriver]     = useState<any[]>([]);
    const [monitor, setMonitor]   = useState<any[]>([]);
    const [route, setRoute]       = useState<any>(routeBase);
    const [loadData, setLoadData] = useState<boolean>(false);
    const [error, setError]       = useState<string | null>(null);

    // Contexts
    const { user } = useContext(GeneralContext);

    const onChange = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const { name, value } = e.target;

        setRoute((prevLine: any) => ({
            ...prevLine,
            [name]: value
        }));
    };

    async function loadInfos() {
        try {
            const [drivers, monitors, vehicles] = await Promise.all([
                api.get('transport/vehicles', { headers: { Authorization: user.token } }),
                api.get('transport/drivers', { headers: { Authorization: user.token } }),
                api.get('transport/monitors', { headers: { Authorization: user.token } })
            ]);

            setVehicle(drivers.data);
            setDriver(monitors.data);
            setMonitor(vehicles.data);

            setReady(true);
        } catch (error) {
            console.error('Erro ao carregar informações:', error);
        }
    }

    async function saveDataForm() {
        if (
            route.name.trim() === "" ||
            route.description.trim() === "" ||
            route.vehicle_id === "0" || route.vehicle_id === 0 ||
            route.driver_id === "0" || route.driver_id === 0 ||
            route.monitor_id === "0" || route.monitor_id === 0
        ) {
            setError("Preencha todos os campos");
            return;
        }

        // Clear states
        setLoadData(true);
        setError(null);

        // Set Body POST
        let dataFormat: any = route;

        // Send data to server
        await api.post('/transport/routes', dataFormat, {
            headers: { Authorization: user.token }
        }).then(data => {
            setLoadData(false);
            setReady(false);
            setModalOpen(false);
        }).catch(errr => {
            console.log(errr)
            setError("Erro ao salvar os dados. Tente novamente")
        });
    }

    useEffect(() => {
        loadInfos();
        if (data) setRoute(data)
    }, []);

    return !loadData ? (
        <form method="post" onSubmit={(event: React.FormEvent<HTMLFormElement>) => event.preventDefault()} className="createRoute">
            {error && <div className="error">{error}</div>}

            <div className="form-group">
                <div className="input-group">
                    <label>Nome</label>
                    <input
                        type="text"
                        placeholder="Ex.: Linha de Alunos"
                        name="name"
                        value={route.name}
                        onChange={onChange}
                        required
                    />
                </div>
            </div>

            <div className="form-group">
                <div className="input-group">
                    <label>Descrição</label>
                    <input
                        type="text"
                        placeholder="Ex.: Linha para pegar os alunos e levar a escola"
                        name="description"
                        value={route.description}
                        onChange={onChange}
                        required
                    />
                </div>
            </div>

            <div className="form-group">
                <div className="input-group">
                    <label>Veiculo</label>
                    <select
                        name="vehicle_id"
                        value={route.vehicle_id}
                        onChange={onChange}
                        required
                    >
                        <option value="0">Selecione um veículo</option>
                        {
                            vehicle.map((el: any) => (
                                <option key={el.id} value={el.id}>
                                    {el.name} - {el.license_plate}
                                </option>
                            ))
                        }
                    </select>
                </div>
            </div>

            <div className="form-group">
                <div className="input-group">
                    <label>Motorista</label>
                    <select
                        name="driver_id"
                        value={route.driver_id}
                        onChange={onChange}
                        required
                    >
                        <option value="0">Selecione um motorista</option>
                        {
                            driver.map((el: any) => (
                                <option key={el.id} value={el.id}>
                                    {el.name}
                                </option>
                            ))
                        }
                    </select>
                </div>

                <div className="input-group">
                    <label>Monitor</label>
                    <select
                        name="monitor_id"
                        value={route.monitor_id}
                        onChange={onChange}
                        required
                    >
                        <option value="0">Selecione um monitor</option>
                        {
                            monitor.map((el: any) => (
                                <option key={el.id} value={el.id}>
                                    {el.name}
                                </option>
                            ))
                        }
                    </select>
                </div>
            </div>

            <div className="form-group">
                <div className="input-group btns">
                    <button id="btnAdd" type="button" onClick={saveDataForm}>Salvar</button>
                    <button id="btnCancel" type="button" onClick={() => setModalOpen(false)}>Cancelar</button>
                </div>
            </div>
        </form>
    ) : (
        <>Salvando dados...</>
    );
};

export default ModalCreate;