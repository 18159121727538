import React from "react";
import treats from "~/utils/treats";
import './styles.css';

const ModalView: React.FC<any> = ({ data, openModal }: any) => {
    return <>
        <h3>{data.name}</h3>

        {
            data.students.length > 0 ? data.students.map((el: any) => (
                <div className="studentLine" key={`student_${el.student.id}`}>
                    <div className="student">{treats.formatNames(el.student.name)}</div>
                    <div className="buttons">
                        <button name="presences" onClick={() => openModal('presence', data, el)}>Ver Presença</button>
                    </div>
                </div>
            )) : (
                <div className="alertInfo">Nenhum aluno cadastrado na linha</div>
            )
        }
    </>;
};

export default ModalView;
