import React, { useContext, useEffect, useState } from "react";
import { FaListCheck } from "react-icons/fa6";
import dates from "~/utils/dates";
import api from "~/services/api";
import './styles.css';

// Contexts
import { GeneralContext } from "~/contexts";

const ModalList: React.FC<any> = ({ id, openModal, setModalOpen }: any) => {
    const [ready, setReady] = useState(false);
    const [data, setData]   = useState<any>([]);

    // Contexts
    const { user } = useContext(GeneralContext);

    function getListContent () {
        return <>
            <div className="testsList">
                {
                    data.length > 0 ? data.map((el: any) => (
                        <div className="testLine" key={`test_${el.id}`}>
                            <div className="title">{el.title}</div>
                            <div className="date">{dates.getDateFormatted(el.date_end + ' 03:00:00', 'dd/mm/yyyy')}</div>

                            <button
                                className="btnView"
                                title="Notas"
                                onClick={() => openModal('GradesStudents', { activity_id: el.id, students: el.schoolClass.students, grades: el.grades })}
                            >
                                <FaListCheck size={12} />
                            </button>
                        </div>
                    )) : (
                        <div className="alertInfo">Nenhuma atividade cadastrada</div>
                    )
                }
            </div>

            <div className="btns">
                <button id="btnCancel" onClick={() => setModalOpen(false)}>Cancelar</button>
            </div>
        </>
    }

    // Get page data
    useEffect(() => {
        !ready && api.get(`teacher/activity-test-grade/${id}`, {
            headers: { Authorization: user.token }
        }).then(resp => {
            setData(resp.data);
            setReady(true);
        }).catch(() => {
            setReady(true);
        });
    }, [ready]);

    return ready ? <>
        { getListContent() }
    </> : <div className="loading">Carregando...</div>;
};

export default ModalList;
