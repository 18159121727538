import { useContext, useEffect, useState } from "react";
import api from "~/services/api";
import './styles.css';

// Contexts
import { GeneralContext } from "~/contexts";
import { ModalContext } from "~/contexts/modal";

// Components
import CategoryBox from "../Box/categories";
import { FormCategory } from ".";

const Categories = () => {
    const [ready, setReady]           = useState(false);
    const [categories, setCategories] = useState<any>(null);

    // Contexts
    const { user } = useContext(GeneralContext);
    const { setModalOpen, setModalClass, setModalTitle, setModalBody } = useContext(ModalContext);

    function addCategory() {
        setModalClass('category')
        setModalTitle('Adicionar Nova Categoria')
        setModalBody(<FormCategory />)
        setModalOpen(true)
    }

    useEffect(() => {
        !ready && api.get('warehouse_resource_type', {
            headers: { Authorization: user.token }
        }).then(resp => {
            setCategories(resp.data);
            setReady(true);
        });
    }, [ready]);

    return ready ? <>
        <div className="header">
            <div className="name">Nome</div>
            <div className="desc">Descrição</div>
            <div className="btns"></div>
        </div>

        {
            categories.map((el: any) => <CategoryBox key={`cat_${el.id}`} {...el} />)
        }

        <div className="btns">
            <button
                type="button"
                name="add"
                className="addCat"
                onClick={() => addCategory()}
            >Adicionar</button>

            <button
                type="button"
                name="close"
                className="closeCat"
                onClick={() => setModalOpen(false)}
            >Fechar</button>
        </div>
    </> : <></>
};

export default Categories;
