import React from 'react';
import { FaEye, FaPencil, FaTrash } from 'react-icons/fa6';
import './styles.css';

const VehiclesBox: React.FC<any> = (props: any) => {
    function getButtons() {
        return <>
            <button type="button" className="view" onClick={() => props.openModal('view', props)} title="Visualizar">
                <FaEye size={16} /> <span>Visualizar</span>
            </button>

            <button type="button" className="edit" onClick={() => props.openModal("edit", props)} title="Deletar Produto">
                <FaPencil size={14} />
            </button>

            <button type="button" className="inactivate" onClick={() => props.openModal('delete', props)} title="Deletar">
                <FaTrash size={14} />
            </button>
        </>
    }

    return <div className="vehiclesBox">
        <div className="infos">
            <h3>{props.name}</h3>

            <div className="description">
                { props.description }
            </div>


        </div>

        <div className="buttons">
            {getButtons()}
        </div>
    </div>;
}

export default VehiclesBox;
