import React, { useContext, useEffect, useState } from 'react';
import api from '~/services/api';
import './styles.css';

// Contexts
import { GeneralContext } from '~/contexts';
import { ModalContext } from '~/contexts/modal';

const SupervisionDirectionTeachersReports: React.FC<any> = ({reportName, data}: any) => {
    const [errors, setErrors] = useState<any>(null);

    // Contexts
    const { user } = useContext(GeneralContext);
    const { setModalOpen, setModalButton } = useContext(ModalContext);

    async function saveDataForm () {
        // Set errors false
        setErrors(false);

        // Check if exists data
        if (data.eventDays.length === 0) {
            setErrors("Não há informações para o relatório!")
            setModalButton(true)
            return false;
        }

        api.post('docs/calendar', data, {
            headers: { Authorization: user.token },
            responseType: 'blob'
        })
        .then(resp => {
            let url      = window.URL.createObjectURL(new Blob([resp.data]));
            let link     = document.createElement('a');
            let fileName = `${reportName}.xlsx`;

            link.href = url;
            link.setAttribute("id", "download");
            link.setAttribute('download', fileName); //or any other extension
            document.body.appendChild(link);
            link.click();
            link.parentNode?.removeChild(link);
            setModalOpen(false);
        }).catch(errr => {
            console.log(errr)
        });
    }

    useEffect(() => {
        saveDataForm();
    }, [])

    return !errors ? <div className="saving">Gerando relatório...</div> : <div className="error">{errors}</div>
}

export default SupervisionDirectionTeachersReports;