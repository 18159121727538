import React, { ChangeEvent, useContext, useState } from 'react';
import sort_by from '~/utils/sort';
import {states, countries} from './datas/infos';
import './styles.css';

// Contexts
import { GeneralContext } from '~/contexts';

// Component
import InputLabel from '~/components/Forms/InputLabel';
import api from '~/services/api';

const NewStudent: React.FC<any> = ({ schools, data, setData, errors}: any) => {
    const [classs, setClasss] = useState<any>([]);

    //Contexts
    const { user } = useContext(GeneralContext);

    async function onChange(event: ChangeEvent<HTMLInputElement | HTMLSelectElement>) {
        let { name, value } = event.target;
        setData({ ...data, [name]: value });

        // Set Class by School
        if (name === 'school_id') await getClasss(value)
    }

    async function getClasss(id: string) {
        if (id === '') setClasss([]);
        else await api.get(`/school_classes/school/${id}`, {
            headers: { Authorization: user.token }
        }).then(resp => {
            resp.data.sort(sort_by({ name: 'class_serie' }, { name: 'class_acron' }))
            setClasss(resp.data)
        }).catch(errr => {
            console.log(errr)
        })
    }

    return (
        <>
            <div className="formGroup">
                <div className="formItem w1-4">
                    <label htmlFor="school_id">Escola</label>
                    <select id="school_id" name="school_id" onChange={onChange} value={data?.school_id} required>
                        <option value="">Selecione uma escola</option>

                        {
                            schools.map((schoolItem: any) => (
                                <option key={schoolItem.id} value={schoolItem.id}>
                                    {schoolItem.name}
                                </option>
                            ))
                        }
                    </select>
                </div>

                <div className="formItem w40">
                    <label htmlFor="class_id">Turma</label>
                    <select id="class_id" name="class_id" onChange={onChange} value={data?.class_id} defaultValue={data?.class_id} disabled={classs.length>0? false : true} required>
                        <option value="">Selecione a turma</option>
                        {
                            classs.map((classItem: any) => (
                                <option key={classItem.id} value={classItem.id}>
                                    { `${classItem.class_serie}${classItem.class_acron} - ${classItem.shift.name} (${classItem.class_id})` }
                                </option>
                            ))
                        }
                    </select>
                </div>
            </div>

            <hr />

            <div className="formGroup">
                <div className="formItem w1-2">
                    <InputLabel
                        name="name"
                        type='text'
                        labelName='Nome Completo'
                        onChange={onChange}
                        value={data?.name}
                        error={errors && errors['name']}
                    />
                </div>

                <div className="formItem w1-2">
                    <InputLabel
                        name="social_name"
                        type='text'
                        labelName='Nome social'
                        onChange={onChange}
                        value={data?.social_name}
                        error={errors && errors['social_name']}
                    />
                </div>

                <div className="formItem w1-4">
                    <InputLabel
                        name="mail"
                        type='text'
                        labelName='Email'
                        onChange={onChange}
                        value={data?.mail}
                        error={errors && errors['mail']}
                    />
                </div>
            </div>

            <div className="formGroup">
                <div className="formItem w1-2">
                    <InputLabel
                        name="mother_name"
                        type='text'
                        labelName='Nome da Mãe'
                        onChange={onChange}
                        value={data?.mother_name}
                        error={errors && errors['mother_name']}
                    />
                </div>

                <div className="formItem w1-4">
                    <InputLabel
                        name="father_name"
                        type='text'
                        labelName='Nome do Pai'
                        onChange={onChange}
                        value={data?.father_name}
                        error={errors && errors['father_name']}
                    />
                </div>
            </div>

            <div className="formGroup">
                <div className="formItem w1-4">
                    <label htmlFor="nationality">Nacionalidade</label>
                    <select onChange={onChange} id="nationality" name="nationality" value={data?.nationality} defaultValue={data?.nationality} required>
                        <option value="Brasileiro">Brasileiro</option>
                        <option value="Estrangeiro">Estrangeiro</option>
                    </select>
                </div>

                <div className="formItem w1-4">
                    <InputLabel
                        name="birth"
                        type='date'
                        labelName='Data de nascimento'
                        onChange={onChange}
                        value={data?.birth}
                        error={errors && errors['birth']}
                    />
                </div>

                {
                    data?.nationality === 'Brasileiro' ? <>
                        <div className="formItem w1-8">
                            <InputLabel
                                name="birth_city"
                                type='text'
                                labelName='Cidade de Nascimento'
                                onChange={onChange}
                                value={data?.birth_city}
                                error={errors && errors['birth_city']}
                            />
                        </div>

                        <div className="formItem w1-8">
                            <label htmlFor="birth_uf">Estado de Nascimento</label>
                            <select onChange={onChange} id="birth_uf" name="birth_uf" value={data?.birth_uf} defaultValue={data?.birth_uf} required>
                                <option value="">Selecione um Estado</option>
                                {
                                    states.map((el: any) => (
                                        <option key={`state_${el.acron}`} value={el.acron}>
                                            {el.name}
                                        </option>
                                    ))
                                }
                            </select>
                        </div>
                    </> : (
                        <div className="formItem w1-4">
                            <label htmlFor="country_origin">País de Origem</label>
                            <select onChange={onChange} id="country_origin" name="country_origin" value={data?.country_origin} defaultValue={data?.country_origin} required>
                                <option value="">Selecione um País</option>
                                {
                                    countries.map((el: any) => (
                                        <option key={`country_${el.code}`} value={el.name}>
                                            {el.name}
                                        </option>
                                    ))
                                }
                            </select>
                        </div>
                    )
                }
            </div>

            <hr />

            <div className="formGroup">
                <div className="formItem w1-4">
                    <label htmlFor="sex">Gênero</label>
                    <select onChange={onChange} id="sex" name="sex" value={data?.sex} defaultValue={data?.sex} required >
                        <option value="">Selecione</option>
                        <option value="MASCULINO">Masculino</option>
                        <option value="FEMININO">Feminino</option>
                        <option value="OUTRO">Outro</option>
                    </select>
                </div>

                <div className="formItem w1-4">
                    <label htmlFor="color_race">Raça</label>
                    <select onChange={onChange} id="color_race" name="color_race" value={data?.color_race} defaultValue={data?.color_race} required >
                        <option value="">Selecione</option>
                        <option value="Branco">Branco</option>
                        <option value="Preto">Preto</option>
                        <option value="Pardo">Pardo</option>
                        <option value="Amarelo">Amarelo</option>
                        <option value="Indígena">Indígena</option>
                    </select>
                </div>

                <div className="formItem w1-4">
                    <label htmlFor="quilombola">Indígena</label>
                    <select onChange={onChange} id="quilombola" name="quilombola" value={data?.quilombola} defaultValue={data?.quilombola} required>
                        <option value="">Selecione</option>
                        <option value="true">Sim</option>
                        <option value="false">Não</option>
                    </select>
                </div>

                <div className="formItem w1-4">
                    <label htmlFor="blood_type">Tipo Sanguíneo</label>
                    <select onChange={onChange} id="blood_type" name="blood_type" value={data?.blood_type} defaultValue={data?.blood_type} required>
                        <option value="">Selecione</option>
                        <option value="O+">O+</option>
                        <option value="O-">O-</option>
                        <option value="A+">A+</option>
                        <option value="A-">A-</option>
                        <option value="B+">B+</option>
                        <option value="B-">B-</option>
                        <option value="AB+">AB+</option>
                        <option value="AB-">AB-</option>
                    </select>
                </div>
            </div>

            <div className="formGroup">
                <div className="formItem w1-4">
                    <label htmlFor="organ_donor">Doador de Órgãos</label>
                    <select onChange={onChange} id="organ_donor" name="organ_donor" value={data?.organ_donor} defaultValue={data?.organ_donor} required>
                        <option value="true">Sim</option>
                        <option value="false">Não</option>
                    </select>
                </div>

                <div className="formItem w1-4">
                    <label htmlFor="deceased">Falecido?</label>
                    <select onChange={onChange} id="deceased" name="deceased" value={data?.deceased} defaultValue={data?.deceased} required>
                        <option value="true">Sim</option>
                        <option value="false">Não</option>
                    </select>
                </div>

                <div className="formItem w1-4">
                    <InputLabel
                        name="deceased_date"
                        type='date'
                        labelName='Data de falecimento'
                        disabled={data?.deceased==='true' ? false : true}
                        onChange={onChange}
                        value={data?.deceased_date}
                        error={errors && errors['deceased_date']}
                    />
                </div>
            </div>

            <hr />

            <div className="formGroup">
                <div className="formItem w1-4">
                    <label htmlFor="internet">Acesso a Internet</label>
                    <select onChange={onChange} id="internet" name="internet" value={data?.internet} defaultValue={data?.internet} required>
                        <option value="true">Sim</option>
                        <option value="false">Não</option>
                    </select>
                </div>

                <div className="formItem w1-4">
                    <label htmlFor="pc_smartphone_tablet">Acesso Periféricos</label>
                    <select onChange={onChange} id="pc_smartphone_tablet" name="pc_smartphone_tablet" value={data?.pc_smartphone_tablet} defaultValue={data?.pc_smartphone_tablet} required>
                        <option value="">Selecione</option>
                        <option value="true">Sim - Table, celular ou PC</option>
                        <option value="false">Não</option>
                    </select>
                </div>
            </div>

            <div className="formGroup">
                <div className="formItem w1-4">
                    <label htmlFor="family_bag">Acesso a Bolsa familia</label>
                    <select onChange={onChange} id="family_bag" name="family_bag" value={data?.family_bag} defaultValue={data?.family_bag} required>
                        <option value="true">Sim</option>
                        <option value="false">Não</option>
                    </select>
                </div>

                <div className="formItem w1-4">
                    <InputLabel
                        name="family_bag_cod"
                        type='text'
                        labelName='Codigo da bolsa familia'
                        disabled={data?.family_bag==='true' ? false : true}
                        onChange={onChange}
                        value={data?.family_bag_cod}
                        error={errors && errors['family_bag_cod']}
                    />
                </div>
            </div>
        </>
    );
};

export default NewStudent;