const states = [
	{acron: "AC", name: "Acre"},
	{acron: "AL", name: "Alagoas"},
	{acron: "AP", name: "Amapá"},
	{acron: "AM", name: "Amazonas"},
	{acron: "BA", name: "Bahia"},
	{acron: "CE", name: "Ceará"},
	{acron: "DF", name: "Distrito Federal"},
	{acron: "ES", name: "Espirito Santo"},
	{acron: "GO", name: "Goiás"},
	{acron: "MA", name: "Maranhão"},
	{acron: "MS", name: "Mato Grosso do Sul"},
	{acron: "MT", name: "Mato Grosso"},
	{acron: "MG", name: "Minas Gerais"},
	{acron: "PA", name: "Pará"},
	{acron: "PB", name: "Paraíba"},
	{acron: "PR", name: "Paraná"},
	{acron: "PE", name: "Pernambuco"},
	{acron: "PI", name: "Piauí"},
	{acron: "RJ", name: "Rio de Janeiro"},
	{acron: "RN", name: "Rio Grande do Norte"},
	{acron: "RS", name: "Rio Grande do Sul"},
	{acron: "RO", name: "Rondônia"},
	{acron: "RR", name: "Roraima"},
	{acron: "SC", name: "Santa Catarina"},
	{acron: "SP", name: "São Paulo"},
	{acron: "SE", name: "Sergipe"},
	{acron: "TO", name: "Tocantins"},
];

const countries = [
    {
        "name": "Afeganistão",
        "acron": "AF",
        "acron2": "AFG",
        "code": "004"
    },
    {
        "name": "África do Sul",
        "acron": "ZA",
        "acron2": "ZAF",
        "code": "710"
    },
    {
        "name": "Albânia",
        "acron": "AL",
        "acron2": "ALB",
        "code": "008"
    },
    {
        "name": "Alemanha",
        "acron": "DE",
        "acron2": "DEU",
        "code": "276"
    },
    {
        "name": "Andorra",
        "acron": "AD",
        "acron2": "AND",
        "code": "020"
    },
    {
        "name": "Angola",
        "acron": "AO",
        "acron2": "AGO",
        "code": "024"
    },
    {
        "name": "Anguilla",
        "acron": "AI",
        "acron2": "AIA",
        "code": "660"
    },
    {
        "name": "Antártida",
        "acron": "AQ",
        "acron2": "ATA",
        "code": "010"
    },
    {
        "name": "Antígua e Barbuda",
        "acron": "AG",
        "acron2": "ATG",
        "code": "028"
    },
    {
        "name": "Antilhas Holandesas",
        "acron": "AN",
        "acron2": "ANT",
        "code": "530"
    },
    {
        "name": "Arábia Saudita",
        "acron": "SA",
        "acron2": "SAU",
        "code": "682"
    },
    {
        "name": "Argélia",
        "acron": "DZ",
        "acron2": "DZA",
        "code": "012"
    },
    {
        "name": "Argentina",
        "acron": "AR",
        "acron2": "ARG",
        "code": "032"
    },
    {
        "name": "Armênia",
        "acron": "AM",
        "acron2": "ARM",
        "code": "51"
    },
    {
        "name": "Aruba",
        "acron": "AW",
        "acron2": "ABW",
        "code": "533"
    },
    {
        "name": "Austrália",
        "acron": "AU",
        "acron2": "AUS",
        "code": "036"
    },
    {
        "name": "Áustria",
        "acron": "AT",
        "acron2": "AUT",
        "code": "040"
    },
    {
        "name": "Azerbaijão",
        "acron": "AZ  ",
        "acron2": "AZE",
        "code": "31"
    },
    {
        "name": "Bahamas",
        "acron": "BS",
        "acron2": "BHS",
        "code": "044"
    },
    {
        "name": "Bahrein",
        "acron": "BH",
        "acron2": "BHR",
        "code": "048"
    },
    {
        "name": "Bangladesh",
        "acron": "BD",
        "acron2": "BGD",
        "code": "050"
    },
    {
        "name": "Barbados",
        "acron": "BB",
        "acron2": "BRB",
        "code": "052"
    },
    {
        "name": "Belarus",
        "acron": "BY",
        "acron2": "BLR",
        "code": "112"
    },
    {
        "name": "Bélgica",
        "acron": "BE",
        "acron2": "BEL",
        "code": "056"
    },
    {
        "name": "Belize",
        "acron": "BZ",
        "acron2": "BLZ",
        "code": "084"
    },
    {
        "name": "Benin",
        "acron": "BJ",
        "acron2": "BEN",
        "code": "204"
    },
    {
        "name": "Bermudas",
        "acron": "BM",
        "acron2": "BMU",
        "code": "060"
    },
    {
        "name": "Bolívia",
        "acron": "BO",
        "acron2": "BOL",
        "code": "068"
    },
    {
        "name": "Bósnia-Herzegóvina",
        "acron": "BA",
        "acron2": "BIH",
        "code": "070"
    },
    {
        "name": "Botsuana",
        "acron": "BW",
        "acron2": "BWA",
        "code": "072"
    },
    {
        "name": "Brasil",
        "acron": "BR",
        "acron2": "BRA",
        "code": "076"
    },
    {
        "name": "Brunei",
        "acron": "BN",
        "acron2": "BRN",
        "code": "096"
    },
    {
        "name": "Bulgária",
        "acron": "BG",
        "acron2": "BGR",
        "code": "100"
    },
    {
        "name": "Burkina Fasso",
        "acron": "BF",
        "acron2": "BFA",
        "code": "854"
    },
    {
        "name": "Burundi",
        "acron": "BI",
        "acron2": "BDI",
        "code": "108"
    },
    {
        "name": "Butão",
        "acron": "BT",
        "acron2": "BTN",
        "code": "064"
    },
    {
        "name": "Cabo Verde",
        "acron": "CV",
        "acron2": "CPV",
        "code": "132"
    },
    {
        "name": "Camarões",
        "acron": "CM",
        "acron2": "CMR",
        "code": "120"
    },
    {
        "name": "Camboja",
        "acron": "KH",
        "acron2": "KHM",
        "code": "116"
    },
    {
        "name": "Canadá",
        "acron": "CA",
        "acron2": "CAN",
        "code": "124"
    },
    {
        "name": "Cazaquistão",
        "acron": "KZ",
        "acron2": "KAZ",
        "code": "398"
    },
    {
        "name": "Chade",
        "acron": "TD",
        "acron2": "TCD",
        "code": "148"
    },
    {
        "name": "Chile",
        "acron": "CL",
        "acron2": "CHL",
        "code": "152"
    },
    {
        "name": "China",
        "acron": "CN",
        "acron2": "CHN",
        "code": "156"
    },
    {
        "name": "Chipre",
        "acron": "CY",
        "acron2": "CYP",
        "code": "196"
    },
    {
        "name": "Cingapura",
        "acron": "SG",
        "acron2": "SGP",
        "code": "702"
    },
    {
        "name": "Colômbia",
        "acron": "CO",
        "acron2": "COL",
        "code": "170"
    },
    {
        "name": "Congo",
        "acron": "CG",
        "acron2": "COG",
        "code": "178"
    },
    {
        "name": "Coréia do Norte",
        "acron": "KP",
        "acron2": "PRK",
        "code": "408"
    },
    {
        "name": "Coréia do Sul",
        "acron": "KR",
        "acron2": "KOR",
        "code": "410"
    },
    {
        "name": "Costa do Marfim",
        "acron": "CI",
        "acron2": "CIV",
        "code": "384"
    },
    {
        "name": "Costa Rica",
        "acron": "CR",
        "acron2": "CRI",
        "code": "188"
    },
    {
        "name": "Croácia (Hrvatska)",
        "acron": "HR",
        "acron2": "HRV",
        "code": "191"
    },
    {
        "name": "Cuba",
        "acron": "CU",
        "acron2": "CUB",
        "code": "192"
    },
    {
        "name": "Dinamarca",
        "acron": "DK",
        "acron2": "DNK",
        "code": "208"
    },
    {
        "name": "Djibuti",
        "acron": "DJ",
        "acron2": "DJI",
        "code": "262"
    },
    {
        "name": "Dominica",
        "acron": "DM",
        "acron2": "DMA",
        "code": "212"
    },
    {
        "name": "Egito",
        "acron": "EG",
        "acron2": "EGY",
        "code": "818"
    },
    {
        "name": "El Salvador",
        "acron": "SV",
        "acron2": "SLV",
        "code": "222"
    },
    {
        "name": "Emirados Árabes Unidos",
        "acron": "AE",
        "acron2": "ARE",
        "code": "784"
    },
    {
        "name": "Equador",
        "acron": "EC",
        "acron2": "ECU",
        "code": "218"
    },
    {
        "name": "Eritréia",
        "acron": "ER",
        "acron2": "ERI",
        "code": "232"
    },
    {
        "name": "Eslováquia",
        "acron": "SK",
        "acron2": "SVK",
        "code": "703"
    },
    {
        "name": "Eslovênia",
        "acron": "SI",
        "acron2": "SVN",
        "code": "705"
    },
    {
        "name": "Espanha",
        "acron": "ES",
        "acron2": "ESP",
        "code": "724"
    },
    {
        "name": "Estados Unidos",
        "acron": "US",
        "acron2": "USA",
        "code": "840"
    },
    {
        "name": "Estônia",
        "acron": "EE",
        "acron2": "EST",
        "code": "233"
    },
    {
        "name": "Etiópia",
        "acron": "ET",
        "acron2": "ETH",
        "code": "231"
    },
    {
        "name": "Fiji",
        "acron": "FJ",
        "acron2": "FJI",
        "code": "242"
    },
    {
        "name": "Filipinas",
        "acron": "PH",
        "acron2": "PHL",
        "code": "608"
    },
    {
        "name": "Finlândia",
        "acron": "FI",
        "acron2": "FIN",
        "code": "246"
    },
    {
        "name": "França",
        "acron": "FR",
        "acron2": "FRA",
        "code": "250"
    },
    {
        "name": "Gabão",
        "acron": "GA",
        "acron2": "GAB",
        "code": "266"
    },
    {
        "name": "Gâmbia",
        "acron": "GM",
        "acron2": "GMB",
        "code": "270"
    },
    {
        "name": "Gana",
        "acron": "GH",
        "acron2": "GHA",
        "code": "288"
    },
    {
        "name": "Geórgia",
        "acron": "GE",
        "acron2": "GEO",
        "code": "268"
    },
    {
        "name": "Gibraltar",
        "acron": "GI",
        "acron2": "GIB",
        "code": "292"
    },
    {
        "name": "Grã-Bretanha (Reino Unido, UK)",
        "acron": "GB",
        "acron2": "GBR",
        "code": "826"
    },
    {
        "name": "Granada",
        "acron": "GD",
        "acron2": "GRD",
        "code": "308"
    },
    {
        "name": "Grécia",
        "acron": "GR",
        "acron2": "GRC",
        "code": "300"
    },
    {
        "name": "Groelândia",
        "acron": "GL",
        "acron2": "GRL",
        "code": "304"
    },
    {
        "name": "Guadalupe",
        "acron": "GP",
        "acron2": "GLP",
        "code": "312"
    },
    {
        "name": "Guam (Território dos Estados Unidos)",
        "acron": "GU",
        "acron2": "GUM",
        "code": "316"
    },
    {
        "name": "Guatemala",
        "acron": "GT",
        "acron2": "GTM",
        "code": "320"
    },
    {
        "name": "Guernsey",
        "acron": "G",
        "acron2": "GGY",
        "code": "832"
    },
    {
        "name": "Guiana",
        "acron": "GY",
        "acron2": "GUY",
        "code": "328"
    },
    {
        "name": "Guiana Francesa",
        "acron": "GF",
        "acron2": "GUF",
        "code": "254"
    },
    {
        "name": "Guiné",
        "acron": "GN",
        "acron2": "GIN",
        "code": "324"
    },
    {
        "name": "Guiné Equatorial",
        "acron": "GQ",
        "acron2": "GNQ",
        "code": "226"
    },
    {
        "name": "Guiné-Bissau",
        "acron": "GW",
        "acron2": "GNB",
        "code": "624"
    },
    {
        "name": "Haiti",
        "acron": "HT",
        "acron2": "HTI",
        "code": "332"
    },
    {
        "name": "Holanda",
        "acron": "NL",
        "acron2": "NLD",
        "code": "528"
    },
    {
        "name": "Honduras",
        "acron": "HN",
        "acron2": "HND",
        "code": "340"
    },
    {
        "name": "Hong Kong",
        "acron": "HK",
        "acron2": "HKG",
        "code": "344"
    },
    {
        "name": "Hungria",
        "acron": "HU",
        "acron2": "HUN",
        "code": "348"
    },
    {
        "name": "Iêmen",
        "acron": "YE",
        "acron2": "YEM",
        "code": "887"
    },
    {
        "name": "Ilha Bouvet (Território da Noruega)",
        "acron": "BV",
        "acron2": "BVT",
        "code": "074"
    },
    {
        "name": "Ilha do Homem",
        "acron": "IM",
        "acron2": "IMN",
        "code": "833"
    },
    {
        "name": "Ilha Natal",
        "acron": "CX",
        "acron2": "CXR",
        "code": "162"
    },
    {
        "name": "Ilha Pitcairn",
        "acron": "PN",
        "acron2": "PCN",
        "code": "612"
    },
    {
        "name": "Ilha Reunião",
        "acron": "RE",
        "acron2": "REU",
        "code": "638"
    },
    {
        "name": "Ilhas Aland",
        "acron": "AX",
        "acron2": "ALA",
        "code": "248"
    },
    {
        "name": "Ilhas Cayman",
        "acron": "KY",
        "acron2": "CYM",
        "code": "136"
    },
    {
        "name": "Ilhas Cocos",
        "acron": "CC",
        "acron2": "CCK",
        "code": "166"
    },
    {
        "name": "Ilhas Comores",
        "acron": "KM",
        "acron2": "COM",
        "code": "174"
    },
    {
        "name": "Ilhas Cook",
        "acron": "CK",
        "acron2": "COK",
        "code": "184"
    },
    {
        "name": "Ilhas Faroes",
        "acron": "FO",
        "acron2": "FRO",
        "code": "234"
    },
    {
        "name": "Ilhas Falkland (Malvinas)",
        "acron": "FK",
        "acron2": "FLK",
        "code": "238"
    },
    {
        "name": "Ilhas Geórgia do Sul e Sandwich do Sul",
        "acron": "GS",
        "acron2": "SGS",
        "code": "239"
    },
    {
        "name": "Ilhas Heard e McDonald (Território da Austrália)",
        "acron": "HM",
        "acron2": "HMD",
        "code": "334"
    },
    {
        "name": "Ilhas Marianas do Norte",
        "acron": "MP",
        "acron2": "MNP",
        "code": "580"
    },
    {
        "name": "Ilhas Marshall",
        "acron": "MH",
        "acron2": "MHL",
        "code": "584"
    },
    {
        "name": "Ilhas Menores dos Estados Unidos",
        "acron": "UM",
        "acron2": "UMI",
        "code": "581"
    },
    {
        "name": "Ilhas Norfolk",
        "acron": "NF",
        "acron2": "NFK",
        "code": "574"
    },
    {
        "name": "Ilhas Seychelles",
        "acron": "SC",
        "acron2": "SYC",
        "code": "690"
    },
    {
        "name": "Ilhas Solomão",
        "acron": "SB",
        "acron2": "SLB",
        "code": "090"
    },
    {
        "name": "Ilhas Svalbard e Jan Mayen",
        "acron": "SJ",
        "acron2": "SJM",
        "code": "744"
    },
    {
        "name": "Ilhas Tokelau",
        "acron": "TK",
        "acron2": "TKL",
        "code": "772"
    },
    {
        "name": "Ilhas Turks e Caicos",
        "acron": "TC",
        "acron2": "TCA",
        "code": "796"
    },
    {
        "name": "Ilhas Virgens (Estados Unidos)",
        "acron": "VI",
        "acron2": "VIR",
        "code": "850"
    },
    {
        "name": "Ilhas Virgens (Inglaterra)",
        "acron": "VG",
        "acron2": "VGB",
        "code": "092"
    },
    {
        "name": "Ilhas Wallis e Futuna",
        "acron": "WF",
        "acron2": "WLF",
        "code": "876"
    },
    {
        "name": "índia",
        "acron": "IN",
        "acron2": "IND",
        "code": "356"
    },
    {
        "name": "Indonésia",
        "acron": "ID",
        "acron2": "IDN",
        "code": "360"
    },
    {
        "name": "Irã",
        "acron": "IR",
        "acron2": "IRN",
        "code": "364"
    },
    {
        "name": "Iraque",
        "acron": "IQ",
        "acron2": "IRQ",
        "code": "368"
    },
    {
        "name": "Irlanda",
        "acron": "IE",
        "acron2": "IRL",
        "code": "372"
    },
    {
        "name": "Islândia",
        "acron": "IS",
        "acron2": "ISL",
        "code": "352"
    },
    {
        "name": "Israel",
        "acron": "IL",
        "acron2": "ISR",
        "code": "376"
    },
    {
        "name": "Itália",
        "acron": "IT",
        "acron2": "ITA",
        "code": "380"
    },
    {
        "name": "Jamaica",
        "acron": "JM",
        "acron2": "JAM",
        "code": "388"
    },
    {
        "name": "Japão",
        "acron": "JP",
        "acron2": "JPN",
        "code": "392"
    },
    {
        "name": "Jersey",
        "acron": "JE",
        "acron2": "JEY",
        "code": "832"
    },
    {
        "name": "Jordânia",
        "acron": "JO",
        "acron2": "JOR",
        "code": "400"
    },
    {
        "name": "Kênia",
        "acron": "KE",
        "acron2": "KEN",
        "code": "404"
    },
    {
        "name": "Kiribati",
        "acron": "KI",
        "acron2": "KIR",
        "code": "296"
    },
    {
        "name": "Kuait",
        "acron": "KW",
        "acron2": "KWT",
        "code": "414"
    },
    {
        "name": "Laos",
        "acron": "LA",
        "acron2": "LAO",
        "code": "418"
    },
    {
        "name": "Látvia",
        "acron": "LV",
        "acron2": "LVA",
        "code": "428"
    },
    {
        "name": "Lesoto",
        "acron": "LS",
        "acron2": "LSO",
        "code": "426"
    },
    {
        "name": "Líbano",
        "acron": "LB",
        "acron2": "LBN",
        "code": "422"
    },
    {
        "name": "Libéria",
        "acron": "LR",
        "acron2": "LBR",
        "code": "430"
    },
    {
        "name": "Líbia",
        "acron": "LY",
        "acron2": "LBY",
        "code": "434"
    },
    {
        "name": "Liechtenstein",
        "acron": "LI",
        "acron2": "LIE",
        "code": "438"
    },
    {
        "name": "Lituânia",
        "acron": "LT",
        "acron2": "LTU",
        "code": "440"
    },
    {
        "name": "Luxemburgo",
        "acron": "LU",
        "acron2": "LUX",
        "code": "442"
    },
    {
        "name": "Macau",
        "acron": "MO",
        "acron2": "MAC",
        "code": "446"
    },
    {
        "name": "Macedônia (República Yugoslava)",
        "acron": "MK",
        "acron2": "MKD",
        "code": "807"
    },
    {
        "name": "Madagascar",
        "acron": "MG",
        "acron2": "MDG",
        "code": "450"
    },
    {
        "name": "Malásia",
        "acron": "MY",
        "acron2": "MYS",
        "code": "458"
    },
    {
        "name": "Malaui",
        "acron": "MW",
        "acron2": "MWI",
        "code": "454"
    },
    {
        "name": "Maldivas",
        "acron": "MV",
        "acron2": "MDV",
        "code": "462"
    },
    {
        "name": "Mali",
        "acron": "ML",
        "acron2": "MLI",
        "code": "466"
    },
    {
        "name": "Malta",
        "acron": "MT",
        "acron2": "MLT",
        "code": "470"
    },
    {
        "name": "Marrocos",
        "acron": "MA",
        "acron2": "MAR",
        "code": "504"
    },
    {
        "name": "Martinica",
        "acron": "MQ",
        "acron2": "MTQ",
        "code": "474"
    },
    {
        "name": "Maurício",
        "acron": "MU",
        "acron2": "MUS",
        "code": "480"
    },
    {
        "name": "Mauritânia",
        "acron": "MR",
        "acron2": "MRT",
        "code": "478"
    },
    {
        "name": "Mayotte",
        "acron": "YT",
        "acron2": "MYT",
        "code": "175"
    },
    {
        "name": "México",
        "acron": "MX",
        "acron2": "MEX",
        "code": "484"
    },
    {
        "name": "Micronésia",
        "acron": "FM",
        "acron2": "FSM",
        "code": "583"
    },
    {
        "name": "Moçambique",
        "acron": "MZ",
        "acron2": "MOZ",
        "code": "508"
    },
    {
        "name": "Moldova",
        "acron": "MD",
        "acron2": "MDA",
        "code": "498"
    },
    {
        "name": "Mônaco",
        "acron": "MC",
        "acron2": "MCO",
        "code": "492"
    },
    {
        "name": "Mongólia",
        "acron": "MN",
        "acron2": "MNG",
        "code": "496"
    },
    {
        "name": "Montenegro",
        "acron": "ME",
        "acron2": "MNE",
        "code": "499"
    },
    {
        "name": "Montserrat",
        "acron": "MS",
        "acron2": "MSR",
        "code": "500"
    },
    {
        "name": "Myanma",
        "acron": "MM",
        "acron2": "MMR",
        "code": "104"
    },
    {
        "name": "Namíbia",
        "acron": "NA",
        "acron2": "NAM",
        "code": "516"
    },
    {
        "name": "Nauru",
        "acron": "NR",
        "acron2": "NRU",
        "code": "520"
    },
    {
        "name": "Nepal",
        "acron": "NP",
        "acron2": "NPL",
        "code": "524"
    },
    {
        "name": "Nicarágua",
        "acron": "NI",
        "acron2": "NIC",
        "code": "558"
    },
    {
        "name": "Níger",
        "acron": "NE",
        "acron2": "NER",
        "code": "562"
    },
    {
        "name": "Nigéria",
        "acron": "NG",
        "acron2": "NGA",
        "code": "566"
    },
    {
        "name": "Niue",
        "acron": "NU",
        "acron2": "NIU",
        "code": "570"
    },
    {
        "name": "Noruega",
        "acron": "NO",
        "acron2": "NOR",
        "code": "578"
    },
    {
        "name": "Nova Caledônia",
        "acron": "NC",
        "acron2": "NCL",
        "code": "540"
    },
    {
        "name": "Nova Zelândia",
        "acron": "NZ",
        "acron2": "NZL",
        "code": "554"
    },
    {
        "name": "Omã",
        "acron": "OM",
        "acron2": "OMN",
        "code": "512"
    },
    {
        "name": "Palau",
        "acron": "PW",
        "acron2": "PLW",
        "code": "585"
    },
    {
        "name": "Panamá",
        "acron": "PA",
        "acron2": "PAN",
        "code": "591"
    },
    {
        "name": "Papua-Nova Guiné",
        "acron": "PG",
        "acron2": "PNG",
        "code": "598"
    },
    {
        "name": "Paquistão",
        "acron": "PK",
        "acron2": "PAK",
        "code": "586"
    },
    {
        "name": "Paraguai",
        "acron": "PY",
        "acron2": "PRY",
        "code": "600"
    },
    {
        "name": "Peru",
        "acron": "PE",
        "acron2": "PER",
        "code": "604"
    },
    {
        "name": "Polinésia Francesa",
        "acron": "PF",
        "acron2": "PYF",
        "code": "258"
    },
    {
        "name": "Polônia",
        "acron": "PL",
        "acron2": "POL",
        "code": "616"
    },
    {
        "name": "Porto Rico",
        "acron": "PR",
        "acron2": "PRI",
        "code": "630"
    },
    {
        "name": "Portugal",
        "acron": "PT",
        "acron2": "PRT",
        "code": "620"
    },
    {
        "name": "Qatar",
        "acron": "QA",
        "acron2": "QAT",
        "code": "634"
    },
    {
        "name": "Quirguistão",
        "acron": "KG",
        "acron2": "KGZ",
        "code": "417"
    },
    {
        "name": "República Centro-Africana",
        "acron": "CF",
        "acron2": "CAF",
        "code": "140"
    },
    {
        "name": "República Democrática do Congo",
        "acron": "CD",
        "acron2": "COD",
        "code": "180"
    },
    {
        "name": "República Dominicana",
        "acron": "DO",
        "acron2": "DOM",
        "code": "214"
    },
    {
        "name": "República Tcheca",
        "acron": "CZ",
        "acron2": "CZE",
        "code": "203"
    },
    {
        "name": "Romênia",
        "acron": "RO",
        "acron2": "ROM",
        "code": "642"
    },
    {
        "name": "Ruanda",
        "acron": "RW",
        "acron2": "RWA",
        "code": "646"
    },
    {
        "name": "Rússia (antiga URSS) - Federação Russa",
        "acron": "RU",
        "acron2": "RUS",
        "code": "643"
    },
    {
        "name": "Saara Ocidental",
        "acron": "EH",
        "acron2": "ESH",
        "code": "732"
    },
    {
        "name": "Saint Vincente e Granadinas",
        "acron": "VC",
        "acron2": "VCT",
        "code": "670"
    },
    {
        "name": "Samoa Americana",
        "acron": "AS",
        "acron2": "ASM",
        "code": "016"
    },
    {
        "name": "Samoa Ocidental",
        "acron": "WS",
        "acron2": "WSM",
        "code": "882"
    },
    {
        "name": "San Marino",
        "acron": "SM",
        "acron2": "SMR",
        "code": "674"
    },
    {
        "name": "Santa Helena",
        "acron": "SH",
        "acron2": "SHN",
        "code": "654"
    },
    {
        "name": "Santa Lúcia",
        "acron": "LC",
        "acron2": "LCA",
        "code": "662"
    },
    {
        "name": "São Bartolomeu",
        "acron": "BL",
        "acron2": "BLM",
        "code": "652"
    },
    {
        "name": "São Cristóvão e Névis",
        "acron": "KN",
        "acron2": "KNA",
        "code": "659"
    },
    {
        "name": "São Martim",
        "acron": "MF",
        "acron2": "MAF",
        "code": "663"
    },
    {
        "name": "São Tomé e Príncipe",
        "acron": "ST",
        "acron2": "STP",
        "code": "678"
    },
    {
        "name": "Senegal",
        "acron": "SN",
        "acron2": "SEN",
        "code": "686"
    },
    {
        "name": "Serra Leoa",
        "acron": "SL",
        "acron2": "SLE",
        "code": "694"
    },
    {
        "name": "Sérvia",
        "acron": "RS",
        "acron2": "SRB",
        "code": "688"
    },
    {
        "name": "Síria",
        "acron": "SY",
        "acron2": "SYR",
        "code": "760"
    },
    {
        "name": "Somália",
        "acron": "SO",
        "acron2": "SOM",
        "code": "706"
    },
    {
        "name": "Sri Lanka",
        "acron": "LK",
        "acron2": "LKA",
        "code": "144"
    },
    {
        "name": "St. Pierre and Miquelon",
        "acron": "PM",
        "acron2": "SPM",
        "code": "666"
    },
    {
        "name": "Suazilândia",
        "acron": "SZ",
        "acron2": "SWZ",
        "code": "748"
    },
    {
        "name": "Sudão",
        "acron": "SD",
        "acron2": "SDN",
        "code": "736"
    },
    {
        "name": "Suécia",
        "acron": "SE",
        "acron2": "SWE",
        "code": "752"
    },
    {
        "name": "Suíça",
        "acron": "CH",
        "acron2": "CHE",
        "code": "756"
    },
    {
        "name": "Suriname",
        "acron": "SR",
        "acron2": "SUR",
        "code": "740"
    },
    {
        "name": "Tadjiquistão",
        "acron": "TJ",
        "acron2": "TJK",
        "code": "762"
    },
    {
        "name": "Tailândia",
        "acron": "TH",
        "acron2": "THA",
        "code": "764"
    },
    {
        "name": "Taiwan",
        "acron": "TW",
        "acron2": "TWN",
        "code": "158"
    },
    {
        "name": "Tanzânia",
        "acron": "TZ",
        "acron2": "TZA",
        "code": "834"
    },
    {
        "name": "Território Britânico do Oceano índico",
        "acron": "IO",
        "acron2": "IOT",
        "code": "086"
    },
    {
        "name": "Territórios do Sul da França",
        "acron": "TF",
        "acron2": "ATF",
        "code": "260"
    },
    {
        "name": "Territórios Palestinos Ocupados",
        "acron": "PS",
        "acron2": "PSE",
        "code": "275"
    },
    {
        "name": "Timor Leste",
        "acron": "TP",
        "acron2": "TMP",
        "code": "626"
    },
    {
        "name": "Togo",
        "acron": "TG",
        "acron2": "TGO",
        "code": "768"
    },
    {
        "name": "Tonga",
        "acron": "TO",
        "acron2": "TON",
        "code": "776"
    },
    {
        "name": "Trinidad and Tobago",
        "acron": "TT",
        "acron2": "TTO",
        "code": "780"
    },
    {
        "name": "Tunísia",
        "acron": "TN",
        "acron2": "TUN",
        "code": "788"
    },
    {
        "name": "Turcomenistão",
        "acron": "TM",
        "acron2": "TKM",
        "code": "795"
    },
    {
        "name": "Turquia",
        "acron": "TR",
        "acron2": "TUR",
        "code": "792"
    },
    {
        "name": "Tuvalu",
        "acron": "TV",
        "acron2": "TUV",
        "code": "798"
    },
    {
        "name": "Ucrânia",
        "acron": "UA",
        "acron2": "UKR",
        "code": "804"
    },
    {
        "name": "Uganda",
        "acron": "UG",
        "acron2": "UGA",
        "code": "800"
    },
    {
        "name": "Uruguai",
        "acron": "UY",
        "acron2": "URY",
        "code": "858"
    },
    {
        "name": "Uzbequistão",
        "acron": "UZ",
        "acron2": "UZB",
        "code": "860"
    },
    {
        "name": "Vanuatu",
        "acron": "VU",
        "acron2": "VUT",
        "code": "548"
    },
    {
        "name": "Vaticano",
        "acron": "VA",
        "acron2": "VAT",
        "code": "336"
    },
    {
        "name": "Venezuela",
        "acron": "VE",
        "acron2": "VEN",
        "code": "862"
    },
    {
        "name": "Vietnã",
        "acron": "VN",
        "acron2": "VNM",
        "code": "704"
    },
    {
        "name": "Zâmbia",
        "acron": "ZM",
        "acron2": "ZMB",
        "code": "894"
    },
    {
        "name": "Zimbábue",
        "acron": "ZW",
        "acron2": "ZWE",
        "code": "716"
    }
]

export {
	states,
	countries
}