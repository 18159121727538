import React, { useContext, useEffect, useState, ChangeEvent } from 'react';
import { FaTrash } from 'react-icons/fa6';
import api from '~/services/api';
import './styles.css';

// Contexts
import { GeneralContext } from '~/contexts';

// Components
import InputLabel from '~/components/Forms/InputLabel';

const NewRequestForm: React.FC<any> = (props: any) => {
    const [requests, setRequests] = useState<any[]>([{ quantity: "", productId: "", name: "", type: "", measure: "" }]);
    const [error, setError]       = useState<string | null>(null);

    // Contexts
    const { user } = useContext(GeneralContext);

    function addProd() {
        setRequests([...requests, { id: requests.length+1, quantity: "", productId: "" }]);
    }

    function removeProd(id: number) {
        if (requests.length === 1) return;
        let reqBase = JSON.parse(JSON.stringify(requests));
        let idx     = requests.findIndex((el: any) => id === el.id);

        // Remove Item
        reqBase.splice(idx, 1);

        // Recreate Requests
        setRequests(reqBase);
    }

    function onChange(index: number, event: ChangeEvent<HTMLInputElement | HTMLSelectElement>) {
        const { name, value } = event.target;
        const newRequests     = [...requests];

        newRequests[index]    = {
            ...newRequests[index],
            [name]: value
        };

        // Se o campo alterado for o select de produto
        if (name === 'productId') {
            // Encontre o produto selecionado
            const selectedProduct = props.products.find((prod: any) => String(prod.id) === value);

            // Atualize a unidade de medida e outros dados baseados no produto selecionado
            if (selectedProduct) {
                // Adiciona informações do produto selecionado ao objeto newRequests
                newRequests[index] = {
                    ...newRequests[index],
                    name: selectedProduct.name,
                    type: selectedProduct.resourceType, // Supondo que você tenha esse campo
                    measure: selectedProduct.resourceMeasure.measure
                };

            }
        }

        setRequests(newRequests);
    }

    function getProducts() {
        let type       = '';
        let prods: any = [];
        let items: any = [];

        // Loop separate types
        props.products.map((el: any, idx: number) => {
            if (type === '') type = el.type;
            else if (type !== el.type) {
                prods.push({ name: type, items });
                type = el.type;
                items = [];
            }

            // Item Create
            let item = {
                id         : el.id,
                name       : el.name,
                description: el.description,
                quantity   : el.quantity,
                measure    : el.resourceMeasure.measure
            }

            items.push(item);

            if (idx === (props.products.length - 1)) prods.push({ name: type, items });
        })

        // Loop to create select
        return prods.map((el: any) => <optgroup label={el.name} key={`prod_label_${el.name}`}>
            {
                el.items.map((el2: any) => <option key={`prod_${el2.id}`} value={el2.id}>
                    {`${el2.name} - Unidade de Medida: ${el2.measure}`}
                </option>)
            }
        </optgroup>)
    }

    async function sendForm() {
        let requestData = {
            user_id: user.id, // Número do ID do usuário
            resource_data: requests, // Texto descrevendo o recurso
            delivery_date: new Date(), // Data da entrega (formato Date)
        };

        api.post('warehouse_requests', requestData, {
            headers: { Authorization: user.token }
        }).then(resp => {
            props.setModalOpen(false);
            props.setReady(false);
        }).catch(err => {
            // setError('Erro ao salvar a requisição')
        });
    }

    function getContent() {
        if (error) return <div className='noData'>{error}</div>

        return <>
            {
                requests.map((data: any, idx: number) => (
                    <div key={`prod_${idx}`} className="formGroup">
                        <div className="inputLabel product">
                            <label htmlFor="product">Produto</label>
                            <select
                                name="productId"
                                value={data.productId}
                                onChange={(e) => onChange(idx, e)}
                            >
                                <option value="">Selecione um Produto</option>

                                { getProducts() }
                            </select>
                        </div>

                        <InputLabel
                            name="quantity"
                            className='quantity'
                            type='text'
                            value={data.quantity}
                            onChange={(e) => onChange(idx, e)}
                            labelName={`Quantidade${data.measure ? ' em ' + data.measure : ''}`}
                        />

                        <button
                            type='button'
                            onClick={() => removeProd(data.id)}
                        >
                            <FaTrash size={14} />
                        </button>
                    </div>
                ))
            }

            <div className="buttons">
                <button className='add' onClick={addProd}>Adicionar Produto na lista de requisição</button>
                <button className='save' onClick={sendForm}>Salvar</button>
            </div>
        </>
    }

    useEffect(() => {
    }, [requests])

    return getContent()
}

export default NewRequestForm;