import React from 'react';
import dates from '~/utils/dates';

const types = [
    { type: 'Breakfast', name: 'Café da Manhã' },
    { type: 'Lunch', name: 'Almoço' },
    { type: 'Snack', name: 'Lanche' },
    { type: 'Dinner', name: 'Jantar' },
    { type: 'Supper', name: 'Ceia' }
]

const ScheduleLine: React.FC<any> = ({ data }: any) => {
    let date  = dates.getDate(data.date);
    let items = JSON.parse(data.meals);

    function getMeals () {
        let ret: any = [];
        items.map((el: any) => {
            let mealName = types.filter(el2 => el2.type === el.type);
            let meal = mealName.length > 0 ? mealName[0].name : 'Não Identificado'
            ret.push(meal);
        })

        return ret.join(', ');
    }

    return <div className="scheduleLine">
        <div className="date">
            <small>Data do Cardápio</small>
            <span>{date.day} de {date.monthName} de { date.year }</span>
        </div>

        <div className="nutritionist">
            <small>Nutricionista</small>
            <span>{data.nutritionist.name}</span>
        </div>

        <div className="meals">
            <small>Refeições</small>
            <span>{items.length} Refeiç{items.length > 1 ? 'ões' : 'ão'} ({getMeals()})</span>
        </div>
    </div>;
}

export default ScheduleLine;
