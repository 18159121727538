import React, { ChangeEvent, useContext, useEffect, useState } from 'react';
import { FaArrowLeft } from 'react-icons/fa';
import { Link, useNavigate } from 'react-router-dom';
import dates from '~/utils/dates';
import sort_by from '~/utils/sort';
import api from '~/services/api';
import './styles.css';

// Contexts
import { GeneralContext } from '~/contexts';

// Components
import Template from '~/components/Default/Template';
import Loading from '~/components/Default/Loading';
import InputLabel from '~/components/Forms/InputLabel';

const initData = {
    school_id       : 0,
    school_year     : "",
    teaching_type_id: 0,
    class_type_id   : 0,
    shift_id        : 0,
    class_serie     : "",
    class_acron     : "",
    start_time      : "",
    end_time        : "",
    start_classes   : "",
    end_classes     : "",
    max_students    : "",
}

const SchoolSecretaryClassesForm: React.FC = () => {
    const [ready, setReady]         = useState(false);
    const [classType, setClassType] = useState<any>([]);
    const [school, setSchool]       = useState<any>([]);
    const [teaching, setTeaching]   = useState<any>([]);
    const [shift, setShift]         = useState<any>([]);
    const [data, setData]           = useState<any>(initData);

    // Navigate
    const navigate = useNavigate();

    // Contexts
    const { breadcrumbs, setBreadcrumbs } = useContext(GeneralContext);
    const { user } = useContext(GeneralContext);

    function onChange(event: ChangeEvent<HTMLInputElement | HTMLSelectElement>) {
        let { name, value } = event.target;
        setData({ ...data, [name]: value });
    }

    async function loadInfos() {
        try {
            const [classTypeResponse, schoolResponse, teachingResponse, shiftResponse] = await Promise.all([
                api.get('/class_type', { headers: { Authorization: user.token } }),
                api.get('/school', { headers: { Authorization: user.token } }),
                api.get('/teaching_type', { headers: { Authorization: user.token } }),
                api.get('/school_shift', { headers: { Authorization: user.token } })
            ]);

            setClassType(classTypeResponse.data.sort(sort_by({ name: 'name' })));
            setSchool(schoolResponse.data.sort(sort_by({ name: 'name' })));
            setTeaching(teachingResponse.data.sort(sort_by({ name: 'name' })));
            setShift(shiftResponse.data.sort(sort_by({ name: 'name' })));

            setReady(true);
        } catch (error) {
            alert('Erro ao carregar informações do formulário')
        }
    }

    async function saveDataForm() {
        let dataForm = data;
        dataForm.school_year = dates.getDate(data.start_classes).year;

        await api.post('school_classes', dataForm, {
            headers: { Authorization: user.token }
        })
        .then(resp => {
            navigate('/secretaria-escolar/turmas')
        }).catch(errr => {
            console.log(errr)
        })
    }

    useEffect(() => {
        if (!ready) loadInfos()
    }, [ready]);

    // Breadcrumbs
    useEffect(() => {
        if (!breadcrumbs || breadcrumbs.curr !== "Nova Turma") {
            setBreadcrumbs({
                curr: 'Nova Turma',
                links: [
                    { name: 'Home', url: '/' },
                    { name: 'secretaria escolar', url: '/secretaria-escolar' },
                    { name: 'Turmas', url: '/secretaria-escolar/turmas' },
                    { name: 'Nova Turma' }
                ]
            });
        }
    }, [breadcrumbs, setBreadcrumbs]);

    return (
        <Template page="Nova Turma" pageTitle="Cadastro de Nova Turma" className="secEducClassForm">
            {
                ready ? <>
                    <div className="headerBox">
                        <div className="buttons">
                            <Link to="/secretaria-escolar/turmas" className="btn back" title="Voltar para lista de Requisições">
                                <FaArrowLeft size={12} /><span> Voltar</span>
                            </Link>
                        </div>
                    </div>

                    <div className='contentForm'>
                        <div className="formGroup">
                            <div className="formItem w1-4">
                                <label htmlFor="school_id">Escola</label>
                                <select onChange={onChange} id="school_id" name="school_id" value={data?.school_id} required>
                                    <option value="">Selecione uma escola</option>
                                    {school.map((schoolItem: any) => (
                                        <option key={schoolItem.id} value={schoolItem.id}>
                                            {schoolItem.name}
                                        </option>
                                    ))}
                                </select>
                            </div>

                            <div className="formItem w1-4">
                                <label htmlFor="teaching_type_id">Nivel Escolar</label>
                                <select onChange={onChange} id="teaching_type_id" name="teaching_type_id" value={data?.teaching_type_id} required>
                                    <option value="">Selecione o nível escolar</option>
                                    {teaching.map((teachingItem: any) => (
                                        <option key={teachingItem.id} value={teachingItem.id}>
                                            {teachingItem.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>

                        <div className="formGroup">
                            <div className="formItem w1-4">
                                <label htmlFor="class_type_id">Tipo de Ensino</label>
                                <select onChange={onChange} id="class_type_id" name="class_type_id" value={data?.class_type_id} required>
                                    <option value="">Selecione o tipo de ensino</option>
                                    {classType.map((classTypeItem: any) => (
                                        <option key={classTypeItem.id} value={classTypeItem.id}>
                                            {classTypeItem.name}
                                        </option>
                                    ))}
                                </select>
                            </div>

                            <div className="formItem w1-4">
                                <label htmlFor="shift_id">Periodo</label>
                                <select onChange={onChange} id="shift_id" name="shift_id" value={data?.shift_id} required>
                                    <option value="">Selecione o período</option>
                                    {shift.map((shiftItem: any) => (
                                        <option key={shiftItem.id} value={shiftItem.id}>
                                            {shiftItem.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>

                        <hr />

                        <div className="formGroup">
                            <div className="formItem w1-4">
                                <InputLabel
                                    name="class_serie"
                                    type='number'
                                    labelName='Serie'
                                    onChange={onChange}
                                    value={data?.class_serie}
                                />
                            </div>

                            <div className="formItem w1-4">
                                <label htmlFor="class_acron">Sala</label>
                                <select onChange={onChange} id="class_acron" name="class_acron" value={data?.class_acron} defaultValue={data?.class_acron} required>
                                <option value="">Selecione</option>
                                    <option value="a">A</option>
                                    <option value="b">B</option>
                                    <option value="c">C</option>
                                    <option value="d">D</option>
                                    <option value="e">E</option>
                                    <option value="f">F</option>
                                </select>
                            </div>

                            <div className="formItem w1-2">
                                <InputLabel
                                    name="max_students"
                                    type='number'
                                    onChange={onChange}
                                    labelName='Quantaidade Maxima de alunos'
                                    value={data?.max_students}
                                />
                            </div>
                        </div>

                        <div className="formGroup">
                            <div className="formItem w1-2">
                                <InputLabel
                                    name="start_classes"
                                    type='date'
                                    labelName='Data Inicio da Turma'
                                    onChange={onChange}
                                    value={data?.start_classes}
                                />
                            </div>
                            <div className="formItem w1-2">
                                <InputLabel
                                    name="end_classes"
                                    type='date'
                                    labelName='Data Encerramento da Turma'
                                    onChange={onChange}
                                    value={data?.end_classes}
                                />
                            </div>

                            <div className="formItem w1-2">
                                <InputLabel
                                    name="start_time"
                                    type='time'
                                    labelName='Hora de inicio da aula'
                                    onChange={onChange}
                                    value={data?.start_time}
                                />
                            </div>
                            <div className="formItem w1-2">
                                <InputLabel
                                    name="end_time"
                                    type='time'
                                    labelName='Hora deEncerramento da aula'
                                    onChange={onChange}
                                    value={data?.end_time}
                                />
                            </div>
                        </div>

                        <div className="buttons">
                            <button className='save' onClick={saveDataForm}>Salvar</button>
                        </div>
                    </div>
                </> : <Loading />
            }
        </Template>
    );
}

export default SchoolSecretaryClassesForm;
