import React, { useContext, useEffect, useState } from 'react';
import api from '~/services/api';
import './styles.css';

// Contexts
import { GeneralContext } from '~/contexts';
import { ModalContext } from '~/contexts/modal';

// Components
import Template from '~/components/Default/Template';
import HeaderSearchAndForm from '~/components/Default/HeaderSearchAndForm';
import Loading from '~/components/Default/Loading';
import View from './Modal/view';
import MonitorsBox from './Box';

const TransportMonitors: React.FC = () => {
    const [ready, setReady]               = useState(false);
    const [error, setError]               = useState<string | null>(null);
    const [monitors, setMonitors]               = useState<any[]>([]);
    const [monitorsList, setMonitorsList]               = useState<any[]>([]);

    // Contexts
    const { user, breadcrumbs, setBreadcrumbs } = useContext(GeneralContext);
    const { setModalOpen, setModalClass, setModalTitle, setModalBody } = useContext(ModalContext);

    function openModal(data?: any) {
        setModalClass('monitorView')
        setModalTitle('Dados do Monitor')
        setModalBody(<View data={data} setModalOpen={setModalOpen} setReady={setReady} />)
        setModalOpen(true)
    }

    function getContent() {
        if (error) return <div className='noData'>{error}</div>

        return <>
            <HeaderSearchAndForm
                buttons={[
                    { nameButton: "Cadastrar Novo Monitor", url: "/secretaria-educacao/funcionarios/novo?type=4", className: "add" }
                ]}
                search={{
                    dataList: monitorsList,
                    filterKey: [],
                    setFilteredList:setMonitorsList
                }}
                placeholder='Filtrar Monitor...'
                isViewButton={true}
                isViewSearch={true}
            />

            {
                monitors && monitors.length > 0 ? (
                    <div className='monitors'>
                        {
                            monitors.map((monitorsData: any) => (
                                <MonitorsBox
                                    key={`monitors_box_${monitorsData.id}`}
                                    data={monitorsData}
                                    openModal={openModal}
                                />
                            ))
                        }
                    </div>
                ) : <div className='noData'>Não foi possível localizar nenhum monitor</div>
            }
        </>
    }

    useEffect(() => {
        !ready && api.get('transport/monitors', {
            headers: { Authorization: user.token }
        }).then((resp: any) => {
            setMonitors(resp.data);
            setMonitorsList(resp.data);
            setReady(true);
        }).catch((err: any) => {
            setError('Erro ao carregar os monitores')
            setReady(true)
        });
    }, [ready])

    // Breadcrumbs
    useEffect(() => {
        (!breadcrumbs || breadcrumbs.curr!=="Monitoras") && setBreadcrumbs({
            curr: 'Monitoras',
            links: [
                { name: 'Home', url: '/'},
                { name: 'Transporte', url: '/transporte'},
                { name: 'Monitoras' }
            ]
        });
    }, [breadcrumbs]);

    return <Template page="Monitoras" pageTitle="Transporte - Monitores" className="transportMonitors">
         { ready ? getContent() : <Loading /> }
    </Template>;
}

export default TransportMonitors;