import React, { useContext, useEffect, useState } from 'react';
import api from '~/services/api';
import './styles.css';

// Contexts
import { GeneralContext } from '~/contexts';

const EmployeeSchools: React.FC<any> = ({ id, schools, setModalOpen, openModal }: any) => {
    const [ready, setReady]                     = useState(false);
    const [employeeSchools, setEmployeeSchools] = useState<any>(null);

    // Contexts
    const { user } = useContext(GeneralContext);

    function getContent() {
        return <>
            {
                employeeSchools && employeeSchools.length > 0 ? <>
                    <div className="header">
                        <div className="school">Escola</div>
                    </div>

                    {
                        employeeSchools.map((el: any) => <div key={`school_${el.id}`} className='line'>
                            <div className="school">{el.school.name}</div>
                        </div>)
                    }
                </> : <div className='alert'>Nenhuma escola cadastrada para esse funcionário</div>
            }

            <div className="buttons">
                <button className='edit' onClick={() => {
                    openModal('schoolsEdit', {id, schools, employeeSchools})
                }}>
                    Editar
                </button>

                <button className='cancel' onClick={() => setModalOpen(false)}>
                    Cancelar
                </button>
            </div>
        </>
    }

    useEffect(() => {
        !ready && api.get(`employees/schools/${id}`, {
            headers: {
                Authorization: user.token
            }
        }).then(resp => {
            setEmployeeSchools(resp.data.schools);
            setReady(true);
        }).catch(errr => {
            console.log(errr)
        })
    }, [ready]);

    return ready ? getContent() : <></>;
}

export default EmployeeSchools;