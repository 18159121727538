import React, { useContext, useEffect, useState } from 'react';
import numbers from '~/utils/numbers';
import api from '~/services/api';
import './styles.css';

// Contexts
import { GeneralContext } from '~/contexts';

// Components
import Template from '~/components/Default/Template';
import Loading from '~/components/Default/Loading';

const Transport: React.FC = () => {
    const [ready, setReady] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [data, setData]   = useState<any>(null);

    // Contexts
    const { user, breadcrumbs, setBreadcrumbs } = useContext(GeneralContext);

    // Loading Data
    useEffect(() => {
        !ready && api.get('dashboard/transport', {
            headers: { Authorization: user.token }
        }).then((resp: any) => {
            setData(resp.data);
            console.log(resp.data);
            setReady(true);
        }).catch((err: any) => {
            setError('Erro ao carregar os dados do dashboard')
            setReady(true)
        });
    }, [ready]);

    // Breadcrumbs
    useEffect(() => {
        (!breadcrumbs || breadcrumbs.curr!=="Transporte") && setBreadcrumbs({
            curr: 'Transporte',
            links: [
                { name: 'Home', url: '/'},
                { name: 'Transporte' }
            ]
        });
    }, [breadcrumbs]);

    return <Template page="Transporte" pageTitle="Transporte" className="transport">
        {
            ready ? <div className="contentBox">
                <div className="boxLine two">
                    <div className='box lines'>
                        <h5>{ numbers.format(data.lines) }</h5>
                        <small>Linhas</small>
                    </div>

                    <div className='box vehicles'>
                        <h5>{ numbers.format(data.vehicles) }</h5>
                        <small>Veículos</small>
                    </div>
                </div>

                <div className="boxLine">
                    <div className='box'>
                        <h5>{ numbers.format(data.drivers) }</h5>
                        <small>Motoristas</small>
                    </div>

                    <div className='box'>
                        <h5>{ numbers.format(data.monitors) }</h5>
                        <small>Monitores(as)</small>
                    </div>

                    <div className='box'>
                        <h5>{ numbers.format(data.students) }</h5>
                        <small>Alunos</small>
                    </div>
                </div>
            </div> : <Loading />
        }
    </Template>;
}

export default Transport;