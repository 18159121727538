import React, { useContext, useEffect, useState } from 'react';
import { FaFilePdf } from 'react-icons/fa6';
import Treats from '~/utils/treats';
import Dates from '~/utils/dates';
import api from '~/services/api';
import './styles.css';

// Contexts
import { GeneralContext } from '~/contexts';
import { ModalContext } from '~/contexts/modal';

// Components
import TemplateSupDir from '../Template';
import Modal from './Modal';

const SupervisionDirectionStudentsReports: React.FC = () => {
    const [ready, setReady] = useState(false);
    const [data, setData]   = useState<any>([]);

    // Contexts
    const { user, breadcrumbs, setBreadcrumbs, school } = useContext(GeneralContext);
    const { setModalClass, setModalOpen, setModalButton, setModalTitle, setModalBody } = useContext(ModalContext);

    function openModal(data: any) {
        let tableHeader = [{ INFOS1: 'Nome', INFOS2: 'Idade', INFOS3: 'Ensino', INFOS4: 'Período', INFOS5: 'Classe', }];

        setModalClass('createRepStudents')
        setModalTitle('Relatório de Alunos')
        setModalBody(<Modal reportName="Relatório de Alunos" school={school} tableHeader={tableHeader} tableData={data} />)
        setModalButton(false)
        setModalOpen(true)
    }

    function generateReport() {
        let dataTable: any = [];

        data.map((el: any) => {
            dataTable.push({
                INFOS1: Treats.formatNames(el.name),
                INFOS2: treatYearsOld(el.birth),
                INFOS3: Treats.formatNames(el.school_class.teaching.name),
                INFOS4: Treats.formatNames(el.school_class.shift.name),
                INFOS5: `${Treats.formatNames(el.school_class.class_serie)} Série ${Treats.formatNames(el.school_class.class_acron)}`
            })
        })

        openModal(dataTable)
    }

    function treatYearsOld (date: any) {
        let yearsOld = Dates.getYearsOld(date);

        // Less than a year old
        if (yearsOld.years===0) return yearsOld.months + ' meses';
        else return yearsOld.years + ' ano' + (yearsOld.years>1 ? 's' : '')
    }

    // Get page data
    useEffect(() => {
        !ready && school && api.get(`student/school/${school.id}`, {
            headers: { Authorization: user.token }
        }).then(resp => {
            setData(resp.data);
            console.log(Dates.getYearsOld(resp.data[0].birth))
            setReady(true);
        }).catch(() => {
            setReady(true);
        });
    }, [ready]);

    // Breadcrumbs
    useEffect(() => {
        (!breadcrumbs || breadcrumbs.curr!=="Relatório de Alunos") && setBreadcrumbs({
            curr: 'Relatório de Alunos',
            links: [
                { name: 'Home', url: '/'},
                { name: 'Supervisão e Direção', url: '/supervisao-e-direcao'},
                { name: 'Alunos' }
            ]
        });
    }, [breadcrumbs]);

    return <TemplateSupDir
        page="Relatório de Alunos"
        pageTitle="Relatório de Alunos"
        className="supDirStudent"
        buttons={[
            {
                icons: <FaFilePdf size={14} />,
                title: "Exportar",
                nameButton: "Exportar",
                className: "report add",
                onClick: () => generateReport()
            }
        ]}
    >
        {
            data.length > 0 ? (
                <div className="report">
                    <div className="header">
                        <div className="name">Nome</div>
                        <div className="age">Idade</div>
                        <div className="teaching">Ensino</div>
                        <div className="shift">Período</div>
                        <div className="classs">Classe</div>
                    </div>
                    {
                        ready && data.map((el: any) => <div key={`std_${el.id}`} className='line'>
                            <div className="name">{Treats.formatNames(el.name)}</div>
                            <div className="age">{treatYearsOld(el.birth)}</div>
                            <div className="teaching">{Treats.formatNames(el.school_class.teaching.name)}</div>
                            <div className="shift">{Treats.formatNames(el.school_class.shift.name)}</div>
                            <div className="classs">{Treats.formatNames(el.school_class.class_serie)} Série {Treats.formatNames(el.school_class.class_acron)}</div>
                        </div>)
                    }
                </div>
            ) : <div className='alert'>Nenhum aluno localizado</div>
        }
    </TemplateSupDir>;
}

export default SupervisionDirectionStudentsReports;