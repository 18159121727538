import React, { ChangeEvent, useContext, useEffect, useState } from "react";
import Select from 'react-select';
import api from "~/services/api";
import './styles.css';

// Contexts
import { GeneralContext } from "~/contexts";

// Constant
const routeStudentBase = {
    route_id    : "0",
    student_id  : "0",
    student_name: "Selecine um aluno"
}

const ModalCreate: React.FC<any> = ({ data, setReady, setModalOpen }: any) => {
    const [routes, setRoutes]             = useState<any[]>([]);
    const [students, setStudents]         = useState<any[]>([]);
    const [routeStudent, setRouteStudent] = useState<any>(routeStudentBase);
    const [loadData, setLoadData]         = useState<boolean>(false);
    const [error, setError]               = useState<string | null>(null);

    // Contexts
    const { user } = useContext(GeneralContext);

    const onChange = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const { name, value } = e.target;

        setRouteStudent((prevLine: any) => ({
            ...prevLine,
            [name]: Number(value)
        }));
    };

    const onChangeSelect = (e: any, input: any) => {
        const name  = input.name;
        const value = e.value;
        const label = e.label;

        setRouteStudent((prevLine: any) => ({
            ...prevLine,
            [name]: value,
            'student_name': label
        }));
    };

    async function loadInfos() {
        try {
            const [routes, students] = await Promise.all([
                api.get('transport/routes', { headers: { Authorization: user.token } }),
                api.get('student/min', { headers: { Authorization: user.token } })
            ]);

            setRoutes(routes.data);

            let studentsTreat: any = [];
            students.data.map((el: any) => {
                studentsTreat.push({value: el.id, label: el.name})
            })

            setStudents(studentsTreat);

            setReady(true);
        } catch (error) {
            console.error('Erro ao carregar informações:', error);
        }
    }

    async function saveDataForm() {
        if (
            routeStudent.route_id === "0" || routeStudent.route_id === 0 ||
            routeStudent.student_id === "0" || routeStudent.student_id === 0
        ) {
            setError("Preencha todos os campos");
            return;
        }

        // Clear states
        setLoadData(true);
        setError(null);

        // Set Body POST
        let dataFormat: any = routeStudent;

        // Send data to server
        await api.post('/transport/students/new', dataFormat, {
            headers: { Authorization: user.token }
        }).then(data => {
            setLoadData(false);
            setReady(false);
            setModalOpen(false);
        }).catch(errr => {
            console.log(errr)
            setError("Erro ao salvar os dados. Tente novamente")
        });
    }

    useEffect(() => {
        loadInfos();
        if (data) setRouteStudent({route_id: data.route.id, student_id: data.student.id, student_name: data.student.name});
    }, []);

    return !loadData ? (
            <form method="post" onSubmit={(event: React.FormEvent<HTMLFormElement>) => event.preventDefault()} className="createRoute">
                {error && <div className="error">{error}</div>}

                <div className="form-group">
                    <div className="input-group">
                        <label>Linha</label>
                        <select
                            name="route_id"
                            value={routeStudent.route_id}
                            onChange={onChange}
                            required
                        >
                            <option value="0">Selecione uma linha</option>
                            {
                                routes.map((el: any) => (
                                    <option key={el.id} value={el.id}>
                                        {el.name}
                                    </option>
                                ))
                            }
                        </select>
                    </div>
                </div>

                <div className="form-group">
                    <div className="input-group">
                    <label>Aluno</label>
                        <Select
                            name="student_id"
                            className="studentsSelect"
                            placeholder="Selecione um aluno"
                            value={{value: routeStudent.student_id, label: routeStudent.student_name}}
                            noOptionsMessage={() => <>Nenhum aluno encontrado</>}
                            onChange={onChangeSelect}
                            options={students}
                            // menuIsOpen={true}
                        />
                    </div>
                </div>

                <div className="form-group">
                    <div className="input-group btns">
                        <button id="btnAdd" type="button" onClick={saveDataForm}>Salvar</button>
                        <button id="btnCancel" type="button" onClick={() => setModalOpen(false)}>Cancelar</button>
                    </div>
                </div>
            </form>
        ) : (
            <>Salvando dados...</>
        );
};

export default ModalCreate;
