import React, { ChangeEvent, useContext, useEffect, useState } from 'react';
import { FaArrowLeft } from 'react-icons/fa';
import { Link, useNavigate } from 'react-router-dom';
import api from '~/services/api';
import TreatDatas from './datas/treatDatas';
import './styles.css';

// Contexts
import { GeneralContext } from '~/contexts';

// Components
import Template from '~/components/Default/Template';
import Loading from '~/components/Default/Loading';
import NewStudent from './NewStudent';
import NewStudentDocs from './NewStudentDocs';
import NewStudentAddress from './NewStudentAddress';
import NewStudentDeficiency from './NewStudentDeficiency';
import NewStudentResourceAssessment from './NewStudentResourceAssessment';

// Constant
const initData = {
    // Dados do estudante (IStudent)
    id                             : 0,
    school_id                      : 0,
    class_id                       : 0,
    ra_number                      : 0,
    name                           : "",
    social_name                    : "",
    sex                            : "",
    color_race                     : "",
    birth                          : "",             // pode ser ajustado para "" se preferir lidar como string
    birth_city                     : "",
    birth_uf                       : "",
    mother_name                    : "",
    father_name                    : "",
    mail                           : "",
    nationality                    : "Brasileiro",
    country_origin                 : "",
    organ_donor                    : false,
    blood_type                     : "",
    internet                       : false,
    pc_smartphone_tablet           : false,
    deceased                       : false,
    deceased_date                  : "",
    quilombola                     : false,
    family_bag                     : false,
    family_bag_cod                 : "",

    // Endereço do estudante (IStudentAddress)
    student_id                     : 0,
    address                        : "",
    number                         : "",
    neighborhood                   : "",
    complement                     : "",
    city                           : "",
    uf                             : "",
    cep                            : 0,
    public_area                    : "",
    latitude                       : "-23.550520",
    longitude                      : "-46.633308",

    // Documentos do estudante (IStudentDocs)
    birth_certificate              : "",
    date_birth_certificate         : "",   // pode ser ajustado para "" se preferir lidar como string
    cpf                            : "",
    date_civil_doc                 : "",   // pode ser ajustado para "" se preferir lidar como string
    inep_code                      : "",
    cns_number                     : "",

    // Dados sobre Deficiência
    reduced_mobility               : false,
    reduced_mobility_type          : "",
    school_support                 : false,
    school_support_daily_activities: false,
    school_support_activities      : false,
    school_support_hygiene         : false,
    school_support_locomotion      : false,
    school_support_food            : false,
    start_term_activities_daily    : "",
    end_term_activities_daily      : "",
    start_term_school_activities   : "",
    end_term_school_activities     : "",

    // Recursos de Acessibilidade
    interpreter_guide              : false,
    interpret_libras               : false,
    lip_reading                    : false,
    none                           : false,
    expanded_test                  : false,
    expanded_test_font_size        : "",
    braille_test                   : false,
    transcription_assistance       : false,
    reader_aid                     : false,
    libras_video_test              : false,
    audio_visual_def_code          : false,
    portuguese_language_test       : false,

};

const SchoolSecretaryStudentsForm: React.FC = () => {
    const [ready, setReady] = useState(false);
    const [schools, setSchools] = useState<any>([]);
    const [studentDeficiency, setStudentDeficiency] = useState(false);
    const [studentResourceAssessment, setStudentResourceAssessment] = useState(false);
    const [data, setData] = useState<any>(initData);

    const navigate = useNavigate();

    // Contexts
    const { user } = useContext(GeneralContext);
    const { breadcrumbs, setBreadcrumbs } = useContext(GeneralContext);

    async function onChange(event: ChangeEvent<HTMLInputElement | HTMLSelectElement>) {
        let { name, value } = event.target;
        setData({ ...data, [name]: value });
    }

    const handleSelectChange = (event: ChangeEvent<HTMLInputElement>) => {
        if (event.target.name==='studentDeficiency') setStudentDeficiency(event.target.value === '1');
        else if (event.target.name==='studentResourceAssessment') setStudentResourceAssessment(event.target.value === '1');
    };

    async function loadInfos () {
        try {
            const [schools] = await Promise.all([
                api.get('/school', { headers: { Authorization: user.token } })
            ]);

            setSchools(schools.data);
            setReady(true);
        } catch (error) {
            alert('Erro ao carregar informações do formulário')
        }
    }

    async function saveDataForm() {
        let dataForm = TreatDatas.treatStudentData(data);

        await api.post('student', dataForm, {
            headers: { Authorization: user.token }
        })
        .then(resp => {
            navigate('/secretaria-escolar/alunos')
        }).catch(errr => {
            console.log(errr)
        })
    }

    useEffect(() => {
        if (!ready) loadInfos()
    }, []);

    // Breadcrumbs
    useEffect(() => {
        if (!breadcrumbs || breadcrumbs.curr !== "Novo Aluno") {
            setBreadcrumbs({
                curr: 'Novo Aluno',
                links: [
                    { name: 'Home', url: '/' },
                    { name: 'secretaria-escolar', url: '/secretaria-escolar' },
                    { name: 'Alunos', url: '/secretaria-escolar/alunos' },
                    { name: 'Novo Aluno' }
                ]
            });
        }
    }, [breadcrumbs, setBreadcrumbs]);

    return (
        <Template page="Novo Aluno" pageTitle="Cadastro de novo aluno" className="secEducStudentForm">
            {
                ready ? (
                    <>
                        <div className="headerBox">
                            <div className="buttons">
                                <Link to="/secretaria-escolar/alunos" className="btn back" title="Voltar para lista de Requisições">
                                    <FaArrowLeft size={12} /><span> Voltar</span>
                                </Link>
                            </div>
                        </div>

                        <div className='contentForm'>
                            <NewStudent schools={schools} data={data} setData={setData} onChange={onChange} />

                            <hr />

                            <NewStudentAddress data={data} setData={setData} />

                            <hr />

                            <NewStudentDocs data={data} setData={setData} />

                            <div className="studentDeficiencyBox">
                                <label>O aluno tem deficiência?</label>

                                <div className='studentDeficiencySelects'>
                                    <label>
                                        <input type="radio" name="studentDeficiency" value="1" checked={studentDeficiency === true} onChange={handleSelectChange} />
                                        <span>Sim</span>
                                    </label>

                                    <label>
                                        <input type="radio" name="studentDeficiency" value="0" checked={studentDeficiency === false} onChange={handleSelectChange} />
                                        <span>Não</span>
                                    </label>
                                </div>
                            </div>

                            { studentDeficiency && <NewStudentDeficiency data={data} setData={setData} /> }

                            { // Check if studentDeficiency is true and show select
                                studentDeficiency && (
                                    <div className="studentResourceAssessmentBox">
                                        <label>O aluno necessita de avaliação de recursos?</label>

                                        <div className='studentResourceAssessmentSelects'>
                                            <label>
                                                <input type="radio" name="studentResourceAssessment" value="1" checked={studentResourceAssessment === true} onChange={handleSelectChange} />
                                                <span>Sim</span>
                                            </label>

                                            <label>
                                                <input type="radio" name="studentResourceAssessment" value="0" checked={studentResourceAssessment === false} onChange={handleSelectChange} />
                                                <span>Não</span>
                                            </label>
                                        </div>
                                    </div>
                                )
                            }

                            { studentResourceAssessment && <NewStudentResourceAssessment data={data} setData={setData} /> }

                            <button className='save' onClick={saveDataForm}>Salvar</button>
                        </div>
                    </>
                ) : <Loading />
            }
        </Template>
    );
}

export default SchoolSecretaryStudentsForm;
