import React from "react";
import './styles.css';
import treats from "~/utils/treats";

const ModalView: React.FC<any> = ({ data, openModal }: any) => {
    const getGrade = (id: number) => {
        let filter = data.grades.filter((el: any) => el.student_id === id);
        let ret    = filter.length === 1 ? filter[0].grade : '-';

        return ret
    }

    function getListContent () {
        return <>
            <div className="studentsList">
                <div className="studentHeader">
                    <div className="name">Aluno</div>
                    <div className="grade">Nota</div>
                </div>

                {
                    data.students.length > 0 ? data.students.map((el: any) => (
                        <div className="studentLine" key={`std_${el.id}`}>
                            <div className="name">{treats.formatNames(el.name)}</div>
                            <div className="grade">{getGrade(el.id)}</div>
                        </div>
                    )) : (
                        <div className="alertInfo">Nenhuma atividade cadastrada</div>
                    )
                }
            </div>

            <div className="btns">
                <button id="btnCreate" onClick={() => openModal('GradesCreate', data)}>Editar Notas</button>
                <button id="btnCancel" onClick={() => openModal('GradesActivities')}>Voltar</button>
            </div>
        </>
    }

    return getListContent()
};

export default ModalView;
