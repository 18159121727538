import React, { useContext, useEffect, useState } from "react";
import { FaEye, FaTrash } from "react-icons/fa6";
import dates from "~/utils/dates";
import api from "~/services/api";
import './styles.css';

// Contexts
import { GeneralContext } from "~/contexts";

const ModalList: React.FC<any> = ({ id, openModal, setModalOpen }: any) => {
    const [ready, setReady]     = useState(false);
    const [data, setData]       = useState<any>([]);
    const [remove, setRemove]   = useState<any>(null);
    const [remLoad, setRemLoad] = useState<any>(null);

    // Contexts
    const { user } = useContext(GeneralContext);

    function getListContent () {
        return <>
            <div className="testsList">
                {
                    data.length > 0 ? data.map((el: any) => (
                        <div className="testLine" key={`test_${el.id}`}>
                            <div className="title">{el.title}</div>
                            <div className="date">{dates.getDateFormatted(el.date_end + ' 03:00:00', 'dd/mm/yyyy')}</div>

                            <button
                                className="btnView"
                                title="Visualizar"
                                onClick={() => openModal('ActivitiesView', el)}
                            >
                                <FaEye size={12} />
                            </button>
                            <button
                                className="btnDelete"
                                title="Excluir"
                                onClick={() => setRemove(el)}
                            >
                                <FaTrash size={12} />
                            </button>
                        </div>
                    )) : (
                        <div className="alertInfo">Nenhuma atividade cadastrada</div>
                    )
                }
            </div>

            <div className="btns">
                <button id="btnCreate" onClick={() => openModal('ActivitiesCreate')}>Criar Prova</button>
                <button id="btnCancel" onClick={() => setModalOpen(false)}>Cancelar</button>
            </div>
        </>
    }

    function getRemoveContent() {
        return remove !== null ? <div className="removeBox">
            {
                !remLoad ? <>
                    <div className="infos">
                        <p>Deseja realmente excluir a atividade <span>"{remove.title}"</span>?</p>
                        <p className="alert">Esse processo é irreversível!</p>
                    </div>

                    <div className="btns">
                        <button id="btnDelete" onClick={() => removeActivity(remove.id)}>Deletar</button>
                        <button id="btnCancel" onClick={() => setRemove(null)}>Cancelar</button>
                    </div>
                </> : <div className="removing">Excluindo dados...</div>
            }
        </div> : <></>
    }

    async function removeActivity (id: number) {
        setRemLoad(true);

        api.delete(`teacher/activities/${id}`, {
            headers: { Authorization: user.token }
        }).then(resp => {
            setTimeout(() => {
                setRemove(null);
                setRemLoad(false);
                setReady(false);
            }, 1000);
        }).catch(() => {
            setRemove(null);
            setRemLoad(false);
        });
    }

    // Get page data
    useEffect(() => {
        !ready && api.get(`teacher/activities/${id}`, {
            headers: { Authorization: user.token }
        }).then(resp => {
            setData(resp.data);
            setReady(true);
        }).catch(() => {
            setReady(true);
        });
    }, [ready]);

    return ready ? <>
        { getRemoveContent() }
        { remove === null ? getListContent() : <></> }
    </> : <div className="loading">Carregando...</div>;
};

export default ModalList;
