import React from 'react';
import { FaEye } from 'react-icons/fa6';
import './styles.css';

const MonitorsBox: React.FC<any> = ({ data, openModal }: any) => {
    return <div className="monitorsBox">
        <div className="infos">
            <h3>{data.name}</h3>

            <div className="routes">
                <b>Linhas:</b> {data.routesMonitor.length}
            </div>
        </div>

        <div className="buttons">
            <button type="button" className="view" onClick={() => openModal(data)} title="Informações do Motorista">
                <FaEye size={16} /> <span>Visualizar</span>
            </button>
        </div>
    </div>;
}

export default MonitorsBox;
