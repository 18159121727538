import React, { useContext, useEffect, useState } from 'react';

// Contexts
import { GeneralContext } from '~/contexts';
import './styles.css';

// Components
import Template from '~/components/Default/Template';
import Loading from '~/components/Default/Loading';
import HeaderSearchAndForm from '~/components/Default/HeaderSearchAndForm';
import { ModalContext } from '~/contexts/modal';
import Create from './Modal/create';
import View from './Modal/view';
import Delete from './Modal/delete';
import VehiclesBox from './Box';
import api from '~/services/api';

interface IVehiecles{
    id?:string;
    name:string;
    description:string;
    licence_plate:string;
    brand:string;
    model:string;
    color:string;
    seats:number;
}

const TransportVehicles: React.FC = () => {
    const [ready, setReady]               = useState(false);
    const [error, setError]               = useState<string | null>(null);
    const [vehicles, setVehicles]               = useState<IVehiecles[]>([]);
    const [vehiclesList, setVehiclesList]               = useState<IVehiecles[]>([]);

    // Contexts
    const { user, breadcrumbs, setBreadcrumbs } = useContext(GeneralContext);
    const { setModalOpen, setModalClass, setModalTitle, setModalBody } = useContext(ModalContext);

    function openModal(type: string, data?: any) {
        if (type === "create"){
            setModalClass('createEditVehicle')
            setModalTitle('Criar Novo Veiculo')
            setModalBody(<Create setModalOpen={setModalOpen} setReady={setReady} />)
        } else if (type === "edit"){
            setModalClass('createEditVehicle')
            setModalTitle('Editar Veiculo')
            setModalBody(<Create data={data} setModalOpen={setModalOpen} setReady={setReady} />)
        } else if (type === "view"){
            setModalClass('viewVehicle')
            setModalTitle('Dados do Veiculo')
            setModalBody(<View data={data}setModalOpen={setModalOpen} setReady={setReady} />)
        } else if (type === "delete"){
            setModalClass('deleteVehicle')
            setModalTitle('Deletar Veiculo')
            setModalBody(<Delete data={data} setModalOpen={setModalOpen} setReady={setReady} />)
        }
        setModalOpen(true)
    }

    function getContent() {
        if (error) return <div className='noData'>{error}</div>

        return <>
            <HeaderSearchAndForm
                buttons={[
                    { nameButton: "Cadastrar Novo Veiculo", onClick: ()=> openModal("create"), className: "add" }
                ]}
                search={{
                    dataList: vehiclesList,
                    filterKey: [],
                    setFilteredList:setVehiclesList
                }}
                isViewButton={true}
                isViewSearch={true}
            />

            {
                vehicles && vehicles.length > 0 ? (
                    <div className='vehicles'>
                        {
                            vehicles.map((vehiclesData: any) => (
                                <VehiclesBox
                                    key={`vehicles_box_${vehiclesData.id}`}
                                    {...vehiclesData}
                                    openModal={openModal}
                                />
                            ))
                        }
                    </div>
                ) : <div className='noData'>Não foi possível localizar nenhum veiculo</div>
            }
        </>
    }

    useEffect(() => {
        !ready && api.get('transport/vehicles', {
            headers: { Authorization: user.token }
        }).then(resp => {
            setVehicles(resp.data);
            setVehiclesList(resp.data);
            setReady(true);
        }).catch(err => {
            setError('Erro ao carregar os veículos')
            setReady(true)
        });
    }, [ready])

    // Breadcrumbs
    useEffect(() => {
        (!breadcrumbs || breadcrumbs.curr!=="Veículos") && setBreadcrumbs({
            curr: 'Veículos',
            links: [
                { name: 'Home', url: '/'},
                { name: 'Transporte', url: '/transporte'},
                { name: 'Veículos' }
            ]
        });
    }, [breadcrumbs]);

    return <Template page="Veículos" pageTitle="Transporte - Veículos" className="transportVehicles">
        { ready ? getContent() : <Loading /> }
    </Template>;
}

export default TransportVehicles;