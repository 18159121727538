import React from "react";
import './styles.css';


const ModalView: React.FC<any> = ({ data }: any) => {
    return <>
        <h3>{data.name}</h3>
        <p>{data.description}</p>

        <hr />

        <p><b>Marca:</b> {data.brand}</p>
        <p><b>Modelo:</b> {data.model}</p>

        <hr />

        <p><b>Placa:</b> {data.license_plate}</p>
        <p><b>Cor:</b> {data.color}</p>
        <p><b>Assentos:</b> {data.seats}</p>
    </>
}

export default ModalView;
