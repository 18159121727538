import React from 'react';
import { FaEye, FaTrash } from 'react-icons/fa6';
import './styles.css';

interface Props {
    user         : any;
    type         : any;
    resource_data: string;
    state        : string;
    justification: string;
    delivery_date: Date;
    openModal    : Function;
}

const RequestBox: React.FC<any> = (props: Props) => {
    function getButtons() {
        return <>
            <button type="button" className="view" onClick={() => props.openModal('View', props)} title="Deletar Cargo">
                <FaEye size={16} /> <span> Visualizar</span>
            </button>

            <button type="button" className="cancel" onClick={() => props.openModal('Cancel', props)} title="Editar Cargo">
                <FaTrash size={14} /><span> Cancelar</span>
            </button>
        </>
    }

    return <div className="requestBox">
        <div className="infos">
            <h3>{ props.type.name  }</h3>
            <h4>{ props.user.name  }</h4>

            <div className="dataRequest">
                <b>Data da Solicitação:</b> { new Date(props.delivery_date).toLocaleDateString('pt-BR', {day: '2-digit',month: '2-digit',year: 'numeric',}) }
            </div>

            <div className={`stateRequest ${props.state.toLowerCase()}`}>
                <b>Status da Solicitação:</b> { props.state }
            </div>
        </div>

        <div className="buttons">
            { getButtons() }
        </div>
    </div>;
}

export default RequestBox;
