import React, { useContext, useEffect, useState } from 'react';
import numbers from '~/utils/numbers';
import api from '~/services/api';
import './styles.css';

// Contexts
import { GeneralContext } from '~/contexts';

// Components
import Template from '~/components/Default/Template';
import Loading from '~/components/Default/Loading';
import SchedulesLine from './Box/Schedules'

const Transport: React.FC = () => {
    const [ready, setReady] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [data, setData]   = useState<any>(null);

    // Contexts
    const { user, breadcrumbs, setBreadcrumbs } = useContext(GeneralContext);

    // Loading Data
    useEffect(() => {
        !ready && api.get('dashboard/nutrition', {
            headers: { Authorization: user.token }
        }).then((resp: any) => {
            setData(resp.data);
            setReady(true);
        }).catch((err: any) => {
            setError('Erro ao carregar os dados do dashboard')
            setReady(true)
        });
    }, [ready]);

    // Breadcrumbs
    useEffect(() => {
        (!breadcrumbs || breadcrumbs.curr!=="Nutrição") && setBreadcrumbs({
            curr: 'Nutrição',
            links: [
                { name: 'Home', url: '/'},
                { name: 'Nutrição' }
            ]
        });
    }, [breadcrumbs]);

    return <Template page="Nutrição" pageTitle="Nutrição" className="nutrition">
        {
            ready ? <>
                <div className="contentBox">
                    <div className="boxLine two">
                        <div className='box schedules'>
                            <h5>{ numbers.format(data.schedules) }</h5>
                            <small>Cardápios</small>
                        </div>

                        <div className='box tips'>
                            <h5>{ numbers.format(data.tips) }</h5>
                            <small>Dicas</small>
                        </div>
                    </div>

                    <div className="boxLine">
                        {
                            data.students.map((el: any) => <div className='box'>
                                <h5>{ numbers.format(el.students) }</h5>
                                <small>{ el.name }</small>
                            </div>)
                        }
                    </div>
                </div>

                <hr />

                <h3>Próximos Cardápios</h3>

                <div className="schedulesList">
                    {
                        data.schedulesData.length > 0 ?
                            data.schedulesData.map((el: any) => <SchedulesLine key={`schedule_${el.id}`} data={el} />) :
                            <div className="alertLine">Não há nenhum cardápio cadastrado!</div>
                    }
                </div>
            </> : <Loading />
        }
    </Template>;
}

export default Transport;