import React, { useContext, useEffect, useState } from 'react';
import numbers from '~/utils/numbers';
import api from '~/services/api';
import './styles.css';

// Contexts
import { GeneralContext } from '~/contexts';
import { ModalContext } from '~/contexts/modal';

// Components
import Template from '~/components/Default/Template';
import Loading from '~/components/Default/Loading';
import HeaderSearchAndForm from '~/components/Default/HeaderSearchAndForm';
import { Categories, Measures } from './modal';
import RequestLine from './Box/requests';

const Warehouse: React.FC = () => {
    const [ready, setReady] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [data, setData]   = useState<any>(null);

    // Contexts
    const { user, breadcrumbs, setBreadcrumbs } = useContext(GeneralContext);
    const { setModalOpen, setModalClass, setModalTitle, setModalBody } = useContext(ModalContext);

    function modal(option: string) {
        if (option === "category") {
            setModalClass('category')
            setModalTitle('Categorias')
            setModalBody(<Categories />)
            setModalOpen(true)
        } else if (option === "measure") {
            setModalClass('measure')
            setModalTitle('Unidades de medida')
            setModalBody(<Measures />)
            setModalOpen(true)
        } else {
            setModalTitle('erro')
            setModalBody(<></>)
            setModalOpen(true)
        }
    }

    // Loading Data
    useEffect(() => {
        !ready && api.get('dashboard/warehouse', {
            headers: { Authorization: user.token }
        }).then((resp: any) => {
            setData(resp.data);
            setReady(true);
        }).catch((err: any) => {
            setError('Erro ao carregar os dados do dashboard')
            setReady(true)
        });
    }, [ready]);

    // Breadcrumbs
    useEffect(() => {
        (!breadcrumbs || breadcrumbs.curr!=="Almoxarifado") && setBreadcrumbs({
            curr: 'Almoxarifado',
            links: [
                { name: 'Home', url: '/'},
                { name: 'Almoxarifado' }
            ]
        });
    }, [breadcrumbs]);

    return <Template page="Almoxarifado" pageTitle="Almoxarifado" className="warehouse">
        {
            ready ? <>
                <HeaderSearchAndForm
                    buttons={[
                        { nameButton: "Categorias", className: "category", onClick: () => modal("category") },
                        { nameButton: "Unidades de Medida", className: "measure", onClick: () => modal("measure") }
                    ]}
                    isViewButton={true}
                    isViewSearch={false}
                />

                <div className="contentBox">
                    <div className="headerLine">
                        <div className='box requests'>
                            <h5>{ numbers.format(data.requests) }</h5>
                            <small>Requisições</small>
                        </div>

                        <div className="categories">
                            <div className='box pending'>
                                <h5>{ numbers.format(data.requestsPending) }</h5>
                                <small>Requisições Pendentes</small>
                            </div>

                            <div className='box approved'>
                                <h5>{ numbers.format(data.requestsApproved) }</h5>
                                <small>Requisições Aprovadas</small>
                            </div>

                            <div className='box denied'>
                                <h5>{ numbers.format(data.requestsDenied) }</h5>
                                <small>Requisições Negadas</small>
                            </div>

                            <div className='box'>
                                <h5>{ numbers.format(data.categories) }</h5>
                                <small>Categorias</small>
                            </div>

                            <div className='box'>
                                <h5>{ numbers.format(data.applicants) }</h5>
                                <small>Requerentes</small>
                            </div>

                            <div className='box'>
                                <h5>{ numbers.format(data.resources) }</h5>
                                <small>Produtos</small>
                            </div>

                            <div className='box'>
                                <h5>{ numbers.format(data.categoriesDivision.shopping) }</h5>
                                <small>Produtos de Compras</small>
                            </div>

                            <div className='box'>
                                <h5>{ numbers.format(data.categoriesDivision.nutrition) }</h5>
                                <small>Produtos de Nutrição</small>
                            </div>

                            <div className='box'>
                                <h5>{ numbers.format(data.categoriesDivision.maintenance) }</h5>
                                <small>Produtos de Manutenção</small>
                            </div>

                            <div className='box'>
                                <h5>{ numbers.format(data.categoriesDivision.transport) }</h5>
                                <small>Produtos de Transporte</small>
                            </div>
                        </div>
                    </div>

                    <hr />

                    <h3>Últimas Requisições Pendentes</h3>

                    <div className="lastRequests">
                        {
                            data.requestsData.length > 0 ? data.requestsData.map((el: any) => <RequestLine key={`request_${el.id}`} {...el} />) : <div className="alertLine">Não há nenhuma requição cadastrada!</div>
                        }
                    </div>
                </div>
            </> : <Loading />
        }
    </Template>;
}

export default Warehouse;