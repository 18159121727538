const treats = {
    formatNames: function (str: string) {
        let strBase     = str.trim().replace(/ +(?= )/g, '').toLowerCase().split(' ');
        let ignoreCases = ['de', 'da', 'das', 'do', 'e', 'o', 'emeb', '-'];
        let upperCases  = ['emeb', 'i', 'ii', 'iii'];

        return strBase.map((el: any) => {
            let strFinal = [];
            el = el.trim();

            if (ignoreCases.indexOf(el) < 0)
                strFinal.push(el[0].toUpperCase() + el.substring(1))
            else if (upperCases.indexOf(el) >= 0)
                strFinal.push(el.toUpperCase())
            else
                strFinal.push(el)

            return strFinal;
        }).join(" ")
    }
}

export default treats;