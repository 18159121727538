import React, { useContext, useEffect, useState } from 'react';
import api from '~/services/api';
import './styles.css';

// Contexts
import { GeneralContext } from '~/contexts';
import { ModalContext } from '~/contexts/modal';

// Components
import Template from '~/components/Default/Template';
import HeaderSearchAndForm from '~/components/Default/HeaderSearchAndForm';
import Loading from '~/components/Default/Loading';
import Create from './Modal/create';
import View from './Modal/view';
import Delete from './Modal/delete';
import LinesBox from './Box';

const TransportLines: React.FC = () => {
    const [ready, setReady]          = useState(false);
    const [error, setError]          = useState<string | null>(null);
    const [lines, setRoutes]         = useState<any[]>([]);
    const [linesList, setRoutesList] = useState<any[]>([]);

    // Contexts
    const { user, breadcrumbs, setBreadcrumbs } = useContext(GeneralContext);
    const { setModalOpen, setModalClass, setModalTitle, setModalBody } = useContext(ModalContext);

    function openModal(type: string, data?: any) {
        if (type === "create"){
            setModalClass('createEditRoute')
            setModalTitle('Criar Nova Linha')
            setModalBody(<Create setModalOpen={setModalOpen} setReady={setReady} />)
        } else if (type === "edit"){
            setModalClass('createEditRoute')
            setModalTitle('Editar Linha')
            setModalBody(<Create data={data} setModalOpen={setModalOpen} setReady={setReady} />)
        } else if (type === "view"){
            setModalClass('viewRoute')
            setModalTitle('Dados da Linha')
            setModalBody(<View data={data}setModalOpen={setModalOpen} setReady={setReady} />)
        } else if (type === "delete"){
            setModalClass('deleteRoute')
            setModalTitle('Deletar Linha')
            setModalBody(<Delete data={data} setModalOpen={setModalOpen} setReady={setReady} />)
        }
        setModalOpen(true)
    }

    function getContent() {
        if (error) return <div className='noData'>{error}</div>

        return <>
            <HeaderSearchAndForm
                buttons={[
                    { nameButton: "Cadastrar Nova Linha", onClick: ()=> openModal("create"), className: "add" }
                ]}
                search={{
                    dataList: linesList,
                    filterKey: [],
                    setFilteredList:setRoutesList
                }}
                isViewButton={true}
                isViewSearch={true}
            />

            {
                lines && lines.length > 0 ? (
                    <div className='linesEd'>
                        {
                            lines.map((linesData: any) => (
                                <LinesBox
                                    key={`route_${linesData.id}`}
                                    data={linesData}
                                    openModal={openModal}
                                />
                            ))
                        }
                    </div>
                ) : <div className='noData'>Não foi possível localizar nenhuma linha</div>
            }
        </>
    }

    useEffect(() => {
        !ready && api.get('transport/routes', {
            headers: { Authorization: user.token }
        }).then(resp => {
            setRoutes(resp.data);
            setRoutesList(resp.data);
            setReady(true);
        }).catch(err => {
            setError('Erro ao carregar as linhas')
            setReady(true)
        });
    }, [ready])

    // Breadcrumbs
    useEffect(() => {
        (!breadcrumbs || breadcrumbs.curr!=="Linhas") && setBreadcrumbs({
            curr: 'Linhas',
            links: [
                { name: 'Home', url: '/'},
                { name: 'Transporte', url: '/transporte'},
                { name: 'Linhas' }
            ]
        });
    }, [breadcrumbs]);

    return <Template page="Linhas" pageTitle="Transporte - Linhas" className="transportRoutes">
        { ready ? getContent() : <Loading /> }
    </Template>;
}

export default TransportLines;