import React, { useContext, useEffect, useState } from 'react';
import api from '~/services/api';
import './styles.css';

// Contexts
import { GeneralContext } from '~/contexts';
import { ModalContext } from '~/contexts/modal';

// Components
import Template from '~/components/Default/Template';
import Loading from '~/components/Default/Loading';
import HeaderSearchAndForm from '~/components/Default/HeaderSearchAndForm';
import StudentBox from './Box';

// Forms
import FormRA from './Form/Ra';

const SchoolSecretaryStudents: React.FC = () => {
    const [ready, setReady]               = useState(false);
    const [students, setStudents]         = useState<any>(null);
    const [studentsList, setStudentsList] = useState<any>([]);
    const [error, setError]               = useState<string | null>(null);

    // Contexts
    const { user, breadcrumbs, setBreadcrumbs } = useContext(GeneralContext);
    const {setModalOpen, setModalTitle, setModalBody} = useContext(ModalContext);

    function getContent() {
        if (error) return <div className='noData'>{error}</div>

        return <>
            <HeaderSearchAndForm
                buttons={[
                    { nameButton: "Adicionar com RA", onClick: () => { modalRA() }, className: "add" },
                    { url:"novo", nameButton: "Adicionar novo aluno", className: "add" }
                ]}
                search={{
                    dataList: studentsList,        // Substitua 'yourDataList' pela sua lista de dados
                    filterKey: ["name", "ra_number"],             // Substitua 'nome' pela chave que você deseja filtrar
                    setFilteredList: setStudents   // Substitua 'setYourList' pela função que define a lista filtrada
                }}
                isViewButton={true}
                isViewSearch={true}
            />

            {
                students && students.length > 0 ? (
                    <div className='students'>
                        {
                            students.map((studentData: any) => (
                                <StudentBox
                                    key={'student_' + studentData.id}
                                    {...studentData}
                                />
                            ))
                        }
                    </div>
                ) : <div className='noData'>Não foi possível localizar nenhum estudante</div>
            }
        </>
    }

    function modalRA() {
        setModalTitle('Adicionar Aluno via RA')
        setModalBody(<FormRA />)
        setModalOpen(true)
    }

    // Get page data
    useEffect(() => {
        !ready && api.get('secEduc/students', {
            headers: { Authorization: user.token }
        }).then(resp => {
            setStudents(resp.data);
            setStudentsList(resp.data);
            setReady(true);
        }).catch(err => {
            setError('Erro ao carregar os dados dos estudantes')
                setReady(true)
        });
    }, [ready]);

    // Breadcrumbs
    useEffect(() => {
        (!breadcrumbs || breadcrumbs.curr!=="Alunos") && setBreadcrumbs({
            curr: 'Alunos',
            links: [
                { name: 'Home', url: '/'},
                { name: 'Secretaria Escolar', url: '/secretaria-escolar'},
                { name: 'Alunos' }
            ]
        });
    }, [breadcrumbs]);

    return <Template page="Alunos" pageTitle="Secretaria Escolar - Alunos" className="secEscStudents">
        { ready ? getContent() : <Loading /> }
    </Template>;
}

export default SchoolSecretaryStudents;