import React from "react";
import './styles.css';

const ModalView: React.FC<any> = ({ data }: any) => {
    return <>
        <h3>{ data.name }</h3>
        <p>{data.description}</p>

        <hr />

        <p><b>Veículo:</b> { data.vehicle.name } ({ data.vehicle.license_plate })</p>
        <p><b>Modelo:</b> {data.vehicle.brand} {data.vehicle.model}</p>
        <p><b>Cor:</b> {data.vehicle.color}</p>

        <hr />

        <p><b>Motorista:</b> { data.driver.name } - { data.driver.phone }</p>
        <p><b>Monitor:</b> { data.monitor.name } - { data.monitor.phone }</p>
    </>;
};

export default ModalView;
