import React, { useContext, useEffect, useState } from "react";
import treats from "~/utils/treats";
import dates from "~/utils/dates";
import sort_by from '~/utils/sort';
import api from "~/services/api";
import './styles.css';

// Contexts
import { GeneralContext } from "~/contexts";

const ModalAttendance: React.FC<any> = ({ data, student, openModal, setReady, setModalOpen }: any) => {
    const [presences, setPresences] = useState<any>([]);
    const [loadData, setLoadData]   = useState<boolean>(false);
    const [error, setError]         = useState<string | null>(null);

    const date = dates.getDate();

    // Contexts
    const { user } = useContext(GeneralContext);

    function getDays () {
        return Array.from(Array(date.days), (e: any, idx: number) => {
            let day     = (idx + 1) <= 9 ? '0' + (idx + 1) : String(idx + 1);
            let weekend = dates.checkWeekend(date.year + '-' + date.month + '-' + day + ' 03:00:00');

            // Check Present / Fault or Weekend
            let presenceBase = presences.filter((el: any) => el.day === day)
            let presence     = { class: 'fault', value: 'Faltou' };

            if (presenceBase.length > 0 && presenceBase[0].status === 'present') presence = { class: 'present', value: 'Presente' }
            if (weekend) presence = { class: 'weekend', value: 'Final de Semana' }

            return <div className={`dayLine ${presence.class}`} key={`presence_${day}`}>
                <div className="day">{ day }</div>
                <div className={`status ${presence.class!=='weekend' ? presence.class : ''}`}>{ presence.value }</div>
                {
                    !weekend ?
                        <button className="button" onClick={() => changeStatus(day, presence.class)}>Alterar</button> :
                        <div className="button weekend"></div>
                }
            </div>
        })
    }

    function changeStatus(day: string, status: string) {
        let presencesBase = JSON.parse(JSON.stringify(presences));
        let statusReverse = status === 'fault' ? 'present' : 'fault';

        // Get a index day in presences
        let indexDay      = presencesBase.findIndex((el: any) => el.day === day);

        // Change status day
        if (indexDay >= 0) presencesBase[indexDay].status = statusReverse;
        else presencesBase.push({ day, status: statusReverse })

        // Sort data by day
        presencesBase.sort(sort_by({ name: 'day' }))

        // Set new json with presences
        setPresences(presencesBase)
    }

    async function saveDataForm () {
        // Clear states
        setLoadData(true);

        // Set Body POST
        let dataFormat: any = {
            student_id: student.student.id,
            year      : date.year,
            month     : date.month,
            presences
        };

        // Send data to server
        await api.post('/transport/students/presence', dataFormat, {
            headers: { Authorization: user.token }
        }).then(data => {
            setLoadData(false);
            setReady(false);
            setModalOpen(false);
        }).catch(errr => {
            setError("Erro ao salvar os dados. Tente novamente")
            setLoadData(false);
        });
    }

    useEffect(() => {
        let monthBase = student.presences.filter((el: any) => el.year === Number(date.year) && el.month === Number(date.month));
        let monthData = monthBase.length === 1 ? JSON.parse(monthBase[0].presence) : [];
        setPresences(monthData)
    }, [])

    // Refresh screen before update presences
    useEffect(() => {}, [presences])

    return !loadData ? (
        <form method="post" onSubmit={(event: React.FormEvent<HTMLFormElement>) => event.preventDefault()} className="updatePresences">
            <h3>{treats.formatNames(student.student.name)}</h3>
            <div className="description">Presenças de {date.monthName} de {date.year}</div>

            <hr />

            <div className="dayHeader">
                <div className="day">Dia</div>
                <div className="status">Status</div>
                <div className="button"></div>
            </div>

            <div className="daysBox">
                { getDays() }
            </div>

            <div className="btns">
                {error && <div className="error">{error}</div>}
                <button id="btnAdd" type="button" onClick={saveDataForm}>Salvar</button>
                <button id="btnCancel" type="button" onClick={() => openModal('view', data)}>Voltar</button>
            </div>
        </form>
    ) : (
        <>Salvando dados...</>
    );
};

export default ModalAttendance;
