import React, { ChangeEvent } from 'react';
import './styles.css';

// Component
import InputLabel from '~/components/Forms/InputLabel';

const NewStudentResourceAssessment: React.FC<any> = ({ data, setData, errors }: any) => {
    async function onChange(event: ChangeEvent<HTMLInputElement | HTMLSelectElement>) {
        let { name, value } = event.target;

        // Check dependencies inputs
        if (name === 'expanded_test' && value === 'false') {
            setData({ ...data, [name]: value, 'expanded_test_font_size': '' });
        } else {
            setData({ ...data, [name]: value });
        }
    }

    return (
        <>
            <div className="formGroup">
                <div className="formItem w1-4">
                    <label htmlFor="interpreter_guide">Intérprete?</label>
                    <select onChange={onChange} id="interpreter_guide" name="interpreter_guide" value={data?.interpreter_guide} defaultValue={data?.interpreter_guide} required>
                        <option value="">Selecione</option>
                        <option value="true">Sim</option>
                        <option value="false">Não</option>
                    </select>
                </div>

                <div className="formItem w1-4">
                    <label htmlFor="interpret_libras">Intérprete de libras?</label>
                    <select onChange={onChange} id="interpret_libras" name="interpret_libras" value={data?.interpret_libras} defaultValue={data?.interpret_libras} required>
                        <option value="">Selecione</option>
                        <option value="true">Sim</option>
                        <option value="false">Não</option>
                    </select>
                </div>

                <div className="formItem w1-4">
                    <label htmlFor="lip_reading">Leitura labial?</label>
                    <select onChange={onChange} id="lip_reading" name="lip_reading" value={data?.lip_reading} defaultValue={data?.lip_reading} required>
                        <option value="">Selecione</option>
                        <option value="true">Sim</option>
                        <option value="false">Não</option>
                    </select>
                </div>

                <div className="formItem w1-4">
                    <label htmlFor="none">Nenhum</label>
                    <select onChange={onChange} id="none" name="none" value={data?.none} defaultValue={data?.none} required>
                        <option value="">Selecione</option>
                        <option value="true">Sim</option>
                        <option value="false">Não</option>
                    </select>
                </div>
            </div>

            <div className="formGroup">
                <div className="formItem w1-4">
                    <label htmlFor="braille_test">Testes em braille?</label>
                    <select onChange={onChange} id="braille_test" name="braille_test" value={data?.braille_test} defaultValue={data?.braille_test} required>
                        <option value="">Selecione</option>
                        <option value="true">Sim</option>
                        <option value="false">Não</option>
                    </select>
                </div>

                <div className="formItem w1-4">
                    <label htmlFor="transcription_assistance">Transcrição assistência?</label>
                    <select onChange={onChange} id="transcription_assistance" name="transcription_assistance" value={data?.transcription_assistance} defaultValue={data?.transcription_assistance} required>
                        <option value="">Selecione</option>
                        <option value="true">Sim</option>
                        <option value="false">Não</option>
                    </select>
                </div>

                <div className="formItem w1-4">
                    <label htmlFor="expanded_test">Tamanho das fontes maiores?</label>
                    <select onChange={onChange} id="expanded_test" name="expanded_test" value={data?.expanded_test} defaultValue={data?.expanded_test} required>
                        <option value="">Selecione</option>
                        <option value="true">Sim</option>
                        <option value="false">Não</option>
                    </select>
                </div>

                <div className="formItem w1-4">
                    <InputLabel
                        name="expanded_test_font_size"
                        type='text'
                        labelName='Tamanho'
                        disabled={data?.expanded_test==='true' ? false : true}
                        onChange={onChange}
                        value={data?.expanded_test_font_size}
                        error={errors && errors['expanded_test_font_size']}
                    />
                </div>
            </div>

            <div className="formGroup">
                <div className="formItem w1-4">
                    <label htmlFor="reader_aid">Auxílio para leitura?</label>
                    <select onChange={onChange} id="reader_aid" name="reader_aid" value={data?.reader_aid} defaultValue={data?.reader_aid} required>
                        <option value="">Selecione</option>
                        <option value="true">Sim</option>
                        <option value="false">Não</option>
                    </select>
                </div>

                <div className="formItem w1-4">
                    <label htmlFor="libras_video_test">Video em libras?</label>
                    <select onChange={onChange} id="libras_video_test" name="libras_video_test" value={data?.libras_video_test} defaultValue={data?.libras_video_test} required>
                        <option value="">Selecione</option>
                        <option value="true">Sim</option>
                        <option value="false">Não</option>
                    </select>
                </div>

                <div className="formItem w1-4">
                    <label htmlFor="audio_visual_def_code">Código da deficiência audiovisual?</label>
                    <select onChange={onChange} id="audio_visual_def_code" name="audio_visual_def_code" value={data?.audio_visual_def_code} defaultValue={data?.audio_visual_def_code} required>
                        <option value="">Selecione</option>
                        <option value="true">Sim</option>
                        <option value="false">Não</option>
                    </select>
                </div>

                <div className="formItem w1-4">
                    <label htmlFor="portuguese_language_test">Teste de idioma?</label>
                    <select onChange={onChange} id="portuguese_language_test" name="portuguese_language_test" value={data?.portuguese_language_test} defaultValue={data?.portuguese_language_test} required>
                        <option value="">Selecione</option>
                        <option value="true">Sim</option>
                        <option value="false">Não</option>
                    </select>
                </div>
            </div>

        </>
    );
};

export default NewStudentResourceAssessment;