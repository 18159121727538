import React from 'react';
import { FaEye, FaPencil, FaTrash } from 'react-icons/fa6';
import dateFormat from 'dateformat';
import './styles.css';

interface Props {
    data     : any;
    openModal: Function;
}

const TipsBox: React.FC<any> = ({data, openModal}: Props) => {
    return <div className='tipsBox'>
        <div className="infos">
            <h3>{ data.title }</h3>

            <div className="author">
                <b>Autor:</b> { data.author ? data.author.name : 'Admin' }
            </div>

            <div className="date">
                Publicado em { dateFormat(data.date, 'dd/mm/yyyy') } às { dateFormat(data.date, 'HH:ss') }
            </div>
        </div>

        <div className="buttons">
            <button type="button" className="view" onClick={() => openModal("View", data)} title="Visualizar">
                <FaEye size={14} /> <span>Ver</span>
            </button>

            <button type="button" className="edit" onClick={() => openModal("Edit", data)} title="Editar">
                <FaPencil size={14} /> <span>Editar</span>
            </button>

            <button type="button" className="delete" onClick={() => openModal("Delete", data)} title="Excluir">
                <FaTrash size={14} /> <span>Excluir</span>
            </button>
        </div>
    </div>;
}

export default TipsBox;