import React, { useContext } from 'react';
import { FaChalkboardUser, FaEye } from 'react-icons/fa6';
import './styles.css';

// Components
import Infos from './infos';
import Teacher from './teacher';
import AddTeachers from '../Form/addTeacher';

// Contexts
import { ModalContext } from '~/contexts/modal';

interface Props {
    id         : number;
    class_serie: string;
    class_acron: string;
    school_year: number;
    school     : { name: string };
    teaching   : { name: string };
    shift      : { name: string };
}

const SchoolBox:React.FC<any> = (props: Props) => {
    // Contexts
    const { setModalOpen, setModalClass, setModalTitle, setModalBody } = useContext(ModalContext);

    function getShift (shift: string) {
        switch (shift) {
            case 'MANHA':
                return 'Manhã';
            case 'TARDE':
                return 'Tarde';
            case 'INTEGRAL':
                return 'Integral';
            default:
                return 'Não Informado';
        }
    }

    function openModal(type: string, data?: any) {
        switch (type) {
            case 'teacher':
                setModalClass('classTeacher')
                setModalTitle('Professor(es) da Classe')
                setModalBody(<Teacher id={props.id} setModalOpen={setModalOpen} openModal={openModal} />)
                break;

            case 'teacherEdit':
                setModalClass('classTeacherEdit')
                setModalTitle('Editar Professor(es) da Classe')
                setModalBody(<AddTeachers class_id={data.id} teachers={data.teachers} classTeacher={data.classTeacher} openModal={openModal} />)
                break;

            default:
                setModalClass('classInfos')
                setModalTitle('Visualizando Classe')
                setModalBody(<Infos id={props.id} />)
                break;
        }

        setModalOpen(true);
    }

    return <div className="classBox">
        <div className="infos">
            <h3>{Number(props.class_serie)>0 ? props.class_serie + ' ano' : 'Turma'} {props.class_acron.toUpperCase()} - { getShift(props.shift.name) }</h3>

            <div className="school">
                <b>Escola:</b> { props.school.name }
            </div>

            <div className="teaching">
                <b>Tipo:</b> { props.teaching.name }
            </div>

            <div className="shift">
                <b>Ano Letivo:</b> { props.school_year }
            </div>
        </div>

        <div className="buttons">
            <button type="button" className="view" onClick={() => openModal('infos')} title="Visualizar Classe">
                <FaEye size={16} /><span> Visualizar</span>
            </button>

            <button type="button" className="teachers" onClick={() => openModal('teacher')} title="Professores da Classe">
                <FaChalkboardUser size={16} /><span> Professor(es)</span>
            </button>
        </div>
    </div>;
}

export default SchoolBox;