import React, { useContext, useState, ChangeEvent, useEffect, useCallback } from 'react';
import { FaTrash } from 'react-icons/fa6';
import api from '~/services/api';
import './addSchoolStyles.css';

// Contexts
import { GeneralContext } from '~/contexts';

// Interface
interface SchoolsProps {
    id         ?: number,
    employee_id : number,
    school_id   : number,
    school      : any
}

const NewRequestForm: React.FC<any> = (props: any) => {
    const [ready, setReady]             = useState(false);
    const [schoolsList, setSchoolsList] = useState<any>([]);
    const [schools, setSchools]         = useState<SchoolsProps[]>([]);
    const [error, setError]             = useState<string | null>(null);

    // Contexts
    const { user } = useContext(GeneralContext);

    function addSchool() {
        setSchools([...schools, { id: schools.length + 1, employee_id: props.id, school_id: 0, school: {} }]);
    }

    function removeSchool(id: number) {
        if (schools.length === 1) return;
        let reqBase = JSON.parse(JSON.stringify(schools));
        let idx     = schools.findIndex((el: any) => id === el.id);

        // Remove Item
        reqBase.splice(idx, 1);

        // Recreate Requests
        setSchools(reqBase);
    }

    function onChange(index: number, event: ChangeEvent<HTMLSelectElement>) {
        const { name, value } = event.target;
        const newSchools = [...schools];

        // Get School if change school_id
        let school = value ? schoolsList.filter((el: any) => el.id===Number(value))[0] : '';

        newSchools[index]    = {
            ...newSchools[index],
            [name]: Number(value),
            school
        };

        setSchools(newSchools);
    }

    async function sendForm() {
        api.post('employees/school', schools, {
            headers: { Authorization: user.token }
        }).then(resp => {
            props.openModal('schools');
        }).catch(err => {
            setError('Erro ao carregar os dados das escolas')
            setReady(true)
        });
    }

    function getContent() {
        if (error) return <div className='noData'>{error}</div>

        return <>
            <div className='schoolsList'>
                {
                    schools && schools.map((data: any, idx: number) => (
                        <div key={`prod_${idx}`} className="formGroup">
                            <div className="inputLabel">
                                <label htmlFor="school">Escola</label>
                                <select
                                    name="school_id"
                                    value={data.school.id}
                                    onChange={(e) => onChange(idx, e)}
                                >
                                    <option value="">Selecione uma Escola</option>

                                    {
                                        schoolsList.map((school: any) => (
                                            <option key={`school_${school.id}`} value={school.id}>
                                                {school.name}
                                            </option>
                                        ))
                                    }
                                </select>
                            </div>

                            <button
                                type='button'
                                onClick={() => removeSchool(data.id)}
                            >
                                <FaTrash size={14} />
                            </button>
                        </div>
                    ))
                }
            </div>

            <div className="buttons">
                <button className='add' onClick={addSchool}>Adicionar Escola</button>

                <div className="btnLine">
                    <button className='save' onClick={sendForm}>Salvar</button>
                    <button className='cancel' onClick={() => props.openModal('schools')}>Cancelar</button>
                </div>
            </div>
        </>
    }

    // Fetch data
    const fetchData = useCallback(async () => {
        try {
            const [respSchoolsList, respSchools] = await Promise.all([
                api.get('school', { headers: { Authorization: user.token } }),
                api.get(`employees/schools/${props.id}`, { headers: { Authorization: user.token } }),
            ]);

            // Responses
            let respSl = respSchoolsList.data;
            let respSh = respSchools.data.schools;

            setSchoolsList(respSl);
            setSchools(respSh);
            setReady(true);
        } catch (error) {
            console.error('Error fetching data', error);
        }
    }, [user.token]);

    useEffect(() => {
        !ready && fetchData()
    }, [ready]);

    return ready ? getContent() : <></>
}

export default NewRequestForm;