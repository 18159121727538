import React, { useContext } from 'react';
import { FaEye, FaLocationDot, FaSchool } from 'react-icons/fa6';
import './styles.css';

// Contexts
import { ModalContext } from '~/contexts/modal';

// Components
import Map from '~/components/Maps';
import Infos from './infos';
import Schools from './schools';
import AddSchools from '../Form/addSchool';

interface Props {
    id             : number;
    name           : string;
    position       : any;
    workInformation: any;
    addresses      : any;
    schools        : any;
}

const EmployeeBox: React.FC<any> = (props: Props) => {
    // Contexts
    const { setModalOpen, setModalClass, setModalTitle, setModalBody } = useContext(ModalContext);

    function openModal (type: string) {
        if (type === "map") {
            setModalClass('map')
            setModalTitle('Visualizando Endereço');
            setModalBody(<Map infos={{name: props.name, latitude: props.addresses.latitude, longitude: props.addresses.longitude}} />)
        } else if (type === "schools") {
            setModalClass('schools')
            setModalTitle('Escolas do Funcionário');
            setModalBody(<Schools id={props.id} schools={props.schools} setModalOpen={setModalOpen} openModal={openModal} />);
        } else if (type === "schoolsEdit") {
            setModalClass('schoolsEdit')
            setModalTitle('Editar Escolas do Funcionário');
            setModalBody(<AddSchools id={props.id} schools={props.schools} openModal={openModal} />)
        } else {
            setModalClass('employeeInfos')
            setModalTitle('Visualizando Funcionário')
            setModalBody(<Infos data={props} />)
        }

        setModalOpen(true);
    }

    return <div className="classBox">
        <div className="infos">
            <h3>{props.name}</h3>

            <div className="position">
                <b>Posição:</b> { props.position.name }
            </div>

            <div className="location">
                <b>Pontos de Trabalho:</b> { props.workInformation.length }
            </div>
        </div>

        <div className="buttons">
            <button type="button" className="maps" onClick={() => openModal('map')} title="Visualizar Endereço">
                <FaLocationDot size={14} />
            </button>

            <button type="button" className="view" onClick={() => openModal('infos')} title="Visualizar Funcionário">
                <FaEye size={16} /><span> Visualizar</span>
            </button>

            <button type="button" className="schools" onClick={() => openModal('schools')} title="Escolas do Funcionário">
                <FaSchool size={16} /><span> Escolas</span>
            </button>
        </div>
    </div>;
}

export default EmployeeBox;