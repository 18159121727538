import React, { ChangeEvent } from 'react';
import apiAddress from '~/services/cep';
import { states } from './datas/infos';
import './styles.css';

// Components
import InputLabel, { InputLabelMask } from '~/components/Forms/InputLabel';

const NewStudentAddress: React.FC<any> = ({ data, setData, errors }: any) => {
    async function onChangeCep (event: ChangeEvent<HTMLInputElement | HTMLSelectElement>) {
        let { name, value } = event.target;
        let formattedValue  = (value).replaceAll('_', '').replace('-', '');

        if (formattedValue.length === 8) {
            let addressComplete = await apiAddress.getViaCep(formattedValue) // Acessa a API do viaCep
            if (addressComplete.erro) alert("A busca do cep falhou, preencha manualmente!") //Em caso de cep inválido

            setData({
                ...data,
                cep: addressComplete.cep,
                address: addressComplete.logradouro,
                city: addressComplete.localidade,
                neighborhood: addressComplete.bairro,
                uf: addressComplete.uf,
            })
        } else {
            setData({ ...data, [name]: value });
        }
    }

    async function onChange(event: ChangeEvent<HTMLInputElement | HTMLSelectElement>) {
        let { name, value } = event.target;
        setData({ ...data, [name]: value });
    }

    return <>
        <div className="formGroup nomg">
            <div className="formItem w25">
                <InputLabelMask
                    name='cep'
                    value={data?.cep}
                    type='text'
                    mask="99999-999"
                    placeholder='00000-000'
                    labelName='CEP'
                    onChange={onChangeCep}
                    error={errors && errors['cep']}
                />
            </div>

            <div className="formItem wauto">
                <InputLabel
                    name="address"
                    type='text'
                    labelName='Rua'
                    value={data?.address}
                    onChange={onChange}
                    error={errors && errors['address']}
                />
            </div>

            <div className="formItem w10">
                <InputLabel
                    name="number"
                    type='text'
                    labelName='Número'
                    onChange={onChange}
                    value={data?.number}
                    error={errors && errors['number']}
                />
            </div>

            <div className="formItem w30">
                <InputLabel
                    name="complement"
                    type='text'
                    labelName='Complemento'
                    value={data?.complement}
                    onChange={onChange}
                    error={errors && errors['complement']}
                />
            </div>
        </div>

        <div className="formGroup">
            <div className="formItem w30">
                <InputLabel
                    name="neighborhood"
                    type='text'
                    labelName='Bairro'
                    value={data?.neighborhood}
                    onChange={onChange}
                    error={errors && errors['neighborhood']}
                />
            </div>

            <div className="formItem wauto">
                <InputLabel
                    name="city"
                    type='text'
                    labelName='Cidade'
                    value={data?.city}
                    onChange={onChange}
                    error={errors && errors['city']}
                />
            </div>

            <div className="formItem w30">
                <label htmlFor="uf">Estado</label>
                <select onChange={onChange} id="uf" name="uf" value={data?.uf} defaultValue={data?.uf} required>
                    <option value="">UF</option>
                    {
                        states.map((el: any) => (
                            <option key={`state_${el.acron}`} value={el.acron}>
                                {el.acron}
                            </option>
                        ))
                    }
                </select>
            </div>

            <div className="formItem w30">
                <label htmlFor="uf">Área</label>
                <select onChange={onChange} id="public_area" name="public_area" value={data?.public_area} defaultValue={data?.public_area} required>
                    <option value="">Selecione a Área</option>
                    <option value="URBANA">Urbana</option>
                    <option value="RURAL">Rural</option>
                </select>
            </div>
        </div>
    </>
};

export default NewStudentAddress;