import React, { useContext, useEffect } from 'react';
import { FaFilePdf } from 'react-icons/fa6';
import './styles.css';

// Contexts
import { GeneralContext } from '~/contexts';
import { ModalContext } from '~/contexts/modal';

// Components
import Template from '~/components/Default/Template';
import DecOfAttendance from './Modal/declaration_of_attendance'

const SchoolSecretaryDocuments: React.FC = () => {
    // Contexts
    const { breadcrumbs, setBreadcrumbs } = useContext(GeneralContext);
    const { setModalClass, setModalOpen, setModalTitle, setModalBody } = useContext(ModalContext);

    function openModal(doc: string, data?: any) {
        if (doc === 'declaration_of_attendance' && !data) {
            setModalClass('createDocs')
            setModalTitle('Declaração de Comparecimento')
            setModalBody(<DecOfAttendance />)
            setModalOpen(true)
        }
    }

    // Breadcrumbs
    useEffect(() => {
        (!breadcrumbs || breadcrumbs.curr!=="Documentos") && setBreadcrumbs({
            curr: 'Documentos',
            links: [
                { name: 'Home', url: '/'},
                { name: 'Secretaria Escolar', url: '/secretaria-escolar'},
                { name: 'Documentos' }
            ]
        });
    }, [breadcrumbs]);

    return <Template page="Documentos" pageTitle="Secretaria Escolar - Documentos" className="secEscDocs">
        <div className="documents">
            <button className='btns decAttendance' onClick={() => openModal('declaration_of_attendance')}>
                <FaFilePdf size={18} />
                <span>Declaração de Comparecimento</span>
            </button>
        </div>
    </Template>;
}

export default SchoolSecretaryDocuments;